import { toast } from "react-toastify"
import { STORAGE } from "./storage"
import { addDays } from "date-fns"
import moment from "moment"
import axios from "axios"
import { saveAs } from "file-saver"
import { DRIVER_TYPE } from "./config"

export const convertToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export const downloadFileFromResponse = (data, filename = "download.csv") => {
  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } else {
    toast.error("No data for download")
  }
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]
export const formatDate = date => {
  if (!date) {
    return null
  }
  const dateObj = new Date(date)
  return `${
    monthNames[dateObj.getMonth()]
  } ${dateObj.getDate()},${dateObj.getFullYear()}`
}
export const formatAMPM = input => {
  if (!input) {
    return "-"
  }
  let date = new Date(input)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  let strTime = hours + ":" + minutes + " " + ampm
  return strTime
}
export const isUserLoggedIn = () => {
  const token = STORAGE.getItem("noahCares")?.token
  return token ? true : false
}
export const loggedInUser = () => {
  return isUserLoggedIn() ? STORAGE.getItem("noahCares")?.user : {}
}
export const getAge = dateString => {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

const isNumeric = str => {
  if (typeof str != "string") return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}
const getKey = (parentKey, key, data) => {
  if (parentKey) {
    if (data[key] && isNumeric(key)) {
      if (typeof data[key] === "object") {
        return `${parentKey}[${key}]`
      } else {
        return parentKey
      }
    } else {
      if (parentKey.charAt(parentKey.length - 1) === "]") {
        return `${parentKey}${key}`
      } else {
        return `${parentKey}.${key}`
      }
    }
    // if (
    //   data[key] &&
    //   typeof data[key] === "object" &&
    //   !(data[key] instanceof Date) &&
    //   !(data[key] instanceof File)
    // ) {
    //   return `${parentKey}[${key}]`
    // } else {
    //   return parentKey.includes("[")
    //     ? `${parentKey}${key}`
    //     : `${parentKey}.${key}`
    // }
  } else {
    return key
  }
}

const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], getKey(parentKey, key, data))
    })
  } else {
    const value = data == null ? "" : data

    formData.append(parentKey, value)
  }
}

export const jsonToFormData = data => {
  const formData = new FormData()
  buildFormData(formData, data)
  return formData
}

const toCapitalize = data => {
  let dataArray = data.split(" ")
  return dataArray
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const getAddressValue = data => {
  try {
    let parsedAddress = JSON.parse(data)
    return toCapitalize(parsedAddress.label.toLowerCase())
  } catch (e) {
    return data
  }
}
export const arrangeWeekDays = (days, data) => {
  return days.filter(day => data.includes(day))
}

function getDates(startDate, endDate, stopDate, work_week) {
  let dateArray = []
  let currentDate = new Date(startDate)
  let endTime = new Date(endDate)
  while (currentDate.toISOString() <= stopDate) {
    let day = currentDate.getDay()
    if (
      (work_week === 1 && day !== 6 && day !== 0) ||
      (work_week === 2 && day !== 0) ||
      work_week === 3 ||
      work_week === 4
    ) {
      dateArray.push({ start: currentDate, end: endTime, title: "Some title" })
    }
    currentDate = addDays(currentDate, 1)
    endTime = addDays(endTime, 1)
  }
  return dateArray
}
export const getEvents = events => {
  let result
  events.map(event => {
    let dateArray = getDates(
      event.start_time,
      event.end_time,
      event.until,
      event.work_week
    )
    result = dateArray
  })
  return result
}

export const getDocNameFromPath = path => {
  if (path) {
    let file_parts = path.split("/")
    let file_name = file_parts.length ? file_parts[file_parts.length - 1] : null
    return file_name
  }
  return path
}

const generateQR = qrRef => {
  const canvas = qrRef.current.querySelector("#qrcode-canvas")
  const image = canvas.toDataURL("image/png")
  const link = document.createElement("a")
  link.href = image
  return link
}

export const saveQR = qrRef => {
  const link = generateQR(qrRef)
  link.download = "qrCode.png"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFileFromURL = async (url, filename) => {
  const blob = await createBlobFromURL(url)
  saveAs(blob, filename)
}

export const createBlobFromURL = async url => {
  const resp = await axios.get(url, { responseType: "blob" })
  const blob = resp.data
  return blob
}

export const formatIncidentDate = (date, format = "MMM D, YYYY (hh:mma)") => {
  return moment(date).format(format)
}

export const formatDateString = (date, format = "MMM D, YYYY (hh:mma)") => {
  return moment(date).format(format)
}

export const formatTime = (time, format = "hh:mm a") => {
  return moment(time).format(format)
}

export const getDriverTypeById = driverType => {
  if (!driverType) return ""
  return Object.keys(DRIVER_TYPE).find(key => DRIVER_TYPE[key] === driverType)
}

export const getFacilityFromArray = (facilityName, facilityArr) => {
  const facility = Object.keys(facilityArr).find(f => f.type === facilityName)
  return facility
}

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(secs).padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}
export const getAddressString = addressObj => {
  let address = addressObj?.address
  if (addressObj?.city) {
    address += `, ${addressObj.city}`
  }
  if (addressObj?.state) {
    address += `, ${addressObj.state}`
  }
  if (addressObj?.country) {
    address += `, ${addressObj.country}`
  }
  return address
}

export const compareDates = (d1, d2) =>{
  if(!d1 && !d2){
    return false
  }
  const arrival = new Date(d1);
  const est_arrival =  new Date(d2);
  return moment(arrival).isBefore(est_arrival);
}

export const isDateBefore = (d1) =>{
  if(!d1){
    return false
  }
  const today = moment().startOf("day");
  return moment(d1).isBefore(today);
}

export const getStartTimeOfTheDay = (d1) => {
  return moment(d1).startOf("day").toISOString();
} 

export const getEndTimeOfTheDay = (d1) => {
  return moment(d1).endOf("day").toISOString();
} 