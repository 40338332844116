import { useEffect, useRef } from "react"
import { Grid } from "@material-ui/core"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import { useQueryClient } from "react-query"
import { Form, useFormik, FormikProvider, FieldArray } from "formik"
import {
  useGetOtherRequirements,
  useGetServiceDetails,
  useGetUserTips,
  useGetVehicleRequirements
} from "../../services/queries"
import NCText from "../../shared/NCText"
import NCButton from "../../shared/NCButton"
import { makeStyles, Box } from "@material-ui/core"
import TopNavBar from "../../components/TopNavBar"
import { useState } from "react"
import NCCheckbox from "../../shared/NCCheckbox"
import { useGetClients } from "../../services/queries"
import { useClientRequest } from "../../services/mutations"
import { toast } from "react-toastify"
import * as Yup from "yup"
import NCLoader from "../../shared/NCLoader"
import NCModal from "../../shared/NCModal"
import { useNavigate } from "react-router"
import NCAddress from "../../shared/NCAddress"
import { arrangeWeekDays, formatAMPM } from "../../libs/utils"
import NCDatePicker from "../../shared/NCDatePicker"
import NCTimePicker from "../../shared/NCTimePicker"
import NCAutocomplete from "../../shared/NCAutocomplete"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { DeleteIcon, UploadIcon } from "../../libs/icons"
import { format, isValid } from "date-fns"
import { useTranslation } from "react-i18next"
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px"
  },
  text: {
    fontSize: "14px",
    fontWeight: "400"
  },
  checkedItems: {
    fontSize: "14px",
    fontWeight: "400"
  },
  checklistItems: {
    fontSize: "14px",
    fontWeight: "400",  
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  heading: {
    fontSize: "14px",
    fontWeight: "550"
  },
  form: {
    "& .MuiCheckbox-root": {
      paddingRight: "3px"
    }
  },
  container: {
    paddingBottom: "10px"
  }
}))

const AddRequest = () => {
  const cls = useStyles()
  const {t} = useTranslation();
  const nav = useNavigate()
  const attachmentRef = useRef()
  const queryClient = useQueryClient()
  const [search, setSearch] = useState(null)
  const { isLoading: otherRequirementLoading, data: otherRequirementsData } =
    useGetOtherRequirements()
  const { isLoading: serviceDetailLoading, data: serviceDetailsData } =
    useGetServiceDetails()
  const { isLoading: userTipsLoading, data: userTipsData } = useGetUserTips()
  const { isLoaing: vehicleRequirementLoading, data: vehicleRequirementsData } =
    useGetVehicleRequirements()
  const { data: clientData, isLoading: clientLoading } = useGetClients(
    null,
    search
  )
  const modalTitle = t("requests.discard_charges")
  const modalText = t("requests.discard_confirmation")
  const clientRequest = useClientRequest()
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ]
  const [userTips, setUserTips] = useState([])
  const [vehicleRequirements, setVehicleRequirements] = useState([])
  const [serviceDetails, setServiceDetails] = useState([])
  const [others, setOthers] = useState([])
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleDiscard = () => {
    formik.resetForm()
    setOpen(false)
  }
  const handleUserTips = (e, i) => {
    if (e.target.checked) {
      let tips = [...userTips]
      tips.push(i + 1)
      setUserTips(tips)
    } else {
      setUserTips(
        [...userTips].filter(function (item) {
          return item !== i + 1
        })
      )
    }
  }
  const handleVehicleRequirements = (e, i) => {
    if (e.target.checked) {
      let requirements = [...vehicleRequirements]
      requirements.push(i + 1)
      setVehicleRequirements(requirements)
    } else {
      setVehicleRequirements(
        [...vehicleRequirements].filter(function (item) {
          return item !== i + 1
        })
      )
    }
  }
  const handleServiceDetails = (e, i) => {
    if (e.target.checked) {
      let services = [...serviceDetails]
      services.push(i + 1)
      setServiceDetails(services)
    } else {
      setServiceDetails(
        [...serviceDetails].filter(function (item) {
          return item !== i + 1
        })
      )
    }
  }
  const handleOther = (e, i) => {
    if (e.target.checked) {
      let other = [...others]
      other.push(i + 1)
      setOthers(other)
    } else {
      setOthers(
        [...others].filter(function (item) {
          return item !== i + 1
        })
      )
    }
  }

  const updateGeoCordinates = (cordinates, dayIndex, key) => {
    const newCoordinates = [...cordinates[key]];
    newCoordinates.splice(dayIndex, 1);
    formik.setFieldValue(key, newCoordinates);
  }

  const updateGeoCordinatesOnDayAdd = (cordinates, dayIndex, key, value) => {
    const newCoordinates = [...cordinates[key]];
    newCoordinates.splice(dayIndex, 0, value)
    formik.setFieldValue(key, newCoordinates);
  }

  
  const handleCheckBoxClick = day => {
    if (formik?.values?.selectedDays) {
      if (formik.values.selectedDays.includes(day)) {
        let dayIndex = formik.values.selectedDays.indexOf(day)
        let prevPickUpAddress = [...formik.values.aleg_pickup_addresses]
        prevPickUpAddress.splice(dayIndex, 1)
        formik.setFieldValue("aleg_pickup_addresses", prevPickUpAddress)
        let prevDropoffAddress = [...formik.values.aleg_drop_off_addresses]
        prevDropoffAddress.splice(dayIndex, 1)
        formik.setFieldValue("aleg_drop_off_addresses", prevDropoffAddress)

        let prevbLegPickUpAddress = [...formik.values.bleg_pickup_addresses]
        prevbLegPickUpAddress.splice(dayIndex, 1)
        formik.setFieldValue("bleg_pickup_addresses", prevbLegPickUpAddress)
        let prevbLegDropoffAddress = [...formik.values.bleg_drop_off_addresses]
        prevbLegDropoffAddress.splice(dayIndex, 1)
        formik.setFieldValue("bleg_drop_off_addresses", prevbLegDropoffAddress)

        updateGeoCordinates(formik.values, dayIndex,"aleg_pickup_addresses_geo");
        updateGeoCordinates(formik.values,dayIndex,"aleg_drop_off_addresses_geo");
        updateGeoCordinates(formik.values,dayIndex,"bleg_pickup_addresses_geo");
        updateGeoCordinates(formik.values,dayIndex,"bleg_drop_off_addresses_geo");

        let remainingValues = formik.values.selectedDays.filter(
          item => item !== day
        )
        formik.setFieldValue("selectedDays", remainingValues)
      } else {
        let newSelectedDays = arrangeWeekDays(days, [
          ...formik.values.selectedDays,
          day
        ])
        formik.setFieldValue("selectedDays", newSelectedDays)

        let dayIndex = newSelectedDays.indexOf(day)
        let prevPickUpAddress = [...formik.values.aleg_pickup_addresses]
        prevPickUpAddress.splice(dayIndex, 0, formik?.values?.defaultAddress)
        formik.setFieldValue("aleg_pickup_addresses", prevPickUpAddress)
        let prevDropoffAddress = [...formik.values.aleg_drop_off_addresses]
        prevDropoffAddress.splice(dayIndex, 0, formik?.values?.schooladdress)
        formik.setFieldValue("aleg_drop_off_addresses", prevDropoffAddress)

        let prevBlegPickUpAddress = [...formik.values.bleg_pickup_addresses]
        prevBlegPickUpAddress.splice(dayIndex, 0, formik?.values?.schooladdress)
        formik.setFieldValue("bleg_pickup_addresses", prevBlegPickUpAddress)
        let prevBlegDropoffAddress = [...formik.values.bleg_drop_off_addresses]
        prevBlegDropoffAddress.splice(
          dayIndex,
          0,
          formik?.values?.defaultAddress
        )
        formik.setFieldValue("bleg_drop_off_addresses", prevBlegDropoffAddress)

        updateGeoCordinatesOnDayAdd(formik.values, dayIndex,"aleg_pickup_addresses_geo",{
          latitude: formik?.values?.defaultAddressLatitude,
          longitude: formik?.values?.defaultAddressLongitude
        });
        updateGeoCordinatesOnDayAdd(formik.values,dayIndex,"aleg_drop_off_addresses_geo",{
          latitude: formik?.values?.schooladdressLatitude,
          longitude: formik?.values?.schooladdressLongitude
        });
        updateGeoCordinatesOnDayAdd(formik.values,dayIndex,"bleg_pickup_addresses_geo",{
          latitude: formik?.values?.schooladdressLatitude,
          longitude: formik?.values?.schooladdressLongitude
        });
        updateGeoCordinatesOnDayAdd(formik.values,dayIndex,"bleg_drop_off_addresses_geo",{
          latitude: formik?.values?.defaultAddressLatitude,
          longitude: formik?.values?.defaultAddressLongitude
        });

      }
    }
  }
  const formik = useFormik({
    initialValues: {
      starting_date: "",
      start_bell_time: "",
      end_bell_time: "",
      client: "",
      schooladdress: "",
      schooladdressLatitude: "",
      schooladdressLongitude: "",
      defaultAddress: "",
      defaultAddressLatitude: "",
      defaultAddressLongitude: "",
      aleg_pickup_addresses: [],
      aleg_pickup_addresses_geo: [],
      aleg_drop_off_addresses: [],
      aleg_drop_off_addresses_geo: [],
      bleg_pickup_addresses: [],
      bleg_pickup_addresses_geo: [],
      bleg_drop_off_addresses: [],
      bleg_drop_off_addresses_geo: [],
      selectedDays: [],
      additional_comment: "",
      attachment: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(
      {
        starting_date: Yup.date()
          .typeError("Use format(MM/dd/YYYY)")
          .min(
            format(new Date().setUTCHours(0, 0, 0, 0), "MM/dd/yyyy"),
            t("requests.error.start_date_min")
          )
          .required(t("requests.error.start_date_required")),
        client: Yup.string().required(t("requests.error.client_required")),
        start_bell_time: Yup.date().typeError("Use format(hh:mm a)").when("end_bell_time", (endDate, schema) => {
          if (endDate && endDate.length && endDate[0] && isValid(endDate[0])) {
            const timeBefore = new Date(new Date(endDate).getTime() - 60000)
            return schema.max(
              new Date(timeBefore),
              t("requests.error.less_than_b_leg_time")
            )
          }
          return schema.required(t("requests.error.bell_time_required"))
        }),
        end_bell_time: Yup.date().typeError("Use format(hh:mm a)").when(
          "start_bell_time",
          (startDate, schema) => {
            if (startDate && startDate.length && startDate[0] && isValid(startDate[0])) {
              const timeAfter = new Date(new Date(startDate).getTime() + 60000)
              return schema.min(
                new Date(timeAfter),
                t("requests.error.greater_than_a_leg_time")
              )
            }

            return schema.required("Bell time is required")
          }
        ),
        selectedDays: Yup.array()
          .of(Yup.string())
          .min(1, t("requests.error.min_one_day_selected")),
        aleg_pickup_addresses: Yup.array().when(
          "start_bell_time",
          (startDate, schema) => {
            if (startDate && startDate.length && startDate[0]) {
              return schema.of(
                Yup.string().required(t("requests.error.pickup_adress_required"))
              )
            }
            return schema.of(Yup.string().nullable())
          }
        ),
        aleg_drop_off_addresses: Yup.array().when(
          "start_bell_time",
          (startDate, schema) => {
            if (startDate && startDate.length && startDate[0]) {
              return schema.of(
                Yup.string().required(t("requests.error.dropoff_adress_required"))
              )
            }
            return schema.of(Yup.string().nullable())
          }
        ),
        bleg_pickup_addresses: Yup.array().when(
          "end_bell_time",
          (endDate, schema) => {
            if (endDate && endDate.length && endDate[0]) {
              return schema.of(
                Yup.string().required(t("requests.error.pickup_adress_required"))
              )
            }
            return schema.of(Yup.string().nullable())
          }
        ),
        bleg_drop_off_addresses: Yup.array().when(
          "end_bell_time",
          (endDate, schema) => {
            if (endDate && endDate.length && endDate[0]) {
              return schema.of(
                Yup.string().required(t("requests.error.dropoff_adress_required"))
              )
            }
            return schema.of(Yup.string().nullable())
          }
        )
      },
      ["end_bell_time", "start_bell_time"]
    ),
    onSubmit: values => {
      let alegPickupAddress = [...values.aleg_pickup_addresses]
      let alegDropOffAddress = [...values.aleg_drop_off_addresses]
      let blegPickupAddress = [...values.bleg_pickup_addresses]
      let blegDropOffAddress = [...values.bleg_drop_off_addresses]

      let alegPickupAddressGeo = [...values.aleg_pickup_addresses_geo]
      let alegDropOffAddressGeo = [...values.aleg_drop_off_addresses_geo]
      let blegPickupAddressGeo = [...values.bleg_pickup_addresses_geo]
      let blegDropOffAddressGeo = [...values.bleg_drop_off_addresses_geo]

      let newAddresses = values.selectedDays.reduce(
        (acc, item) => {
          if (item) {
            let weekDay = days.indexOf(item) + 1
            let aleg_pick_address = alegPickupAddress.splice(0, 1)
            let aleg_drop_address = alegDropOffAddress.splice(0, 1)
            let bleg_pick_address = blegPickupAddress.splice(0, 1)
            let bleg_drop_address = blegDropOffAddress.splice(0, 1)

            let aleg_pickup_addresses_geo = alegPickupAddressGeo.splice(0,1);
            let aleg_drop_off_addresses_geo = alegDropOffAddressGeo.splice(0,1);
            let bleg_pickup_addresses_geo = blegPickupAddressGeo.splice(0,1);
            let bleg_drop_off_addresses_geo = blegDropOffAddressGeo.splice(0,1);

            let pick_addressObj = {
              week_day: weekDay,
              location: { address: aleg_pick_address[0], ...aleg_pickup_addresses_geo[0] },
              drop_location: { address: aleg_drop_address[0], ...aleg_drop_off_addresses_geo[0] }
            }
            let drop_addressObj = {
              week_day: weekDay,
              location: { address: bleg_drop_address[0], ...bleg_drop_off_addresses_geo[0] },
              pickup_location: { address: bleg_pick_address[0], ...bleg_pickup_addresses_geo[0] }
            }
            acc.pickAddress_arr.push(pick_addressObj)
            acc.dropAddress_arr.push(drop_addressObj)
          }
          return acc
        },
        { pickAddress_arr: [], dropAddress_arr: [] }
      )
      const obj = {
        starting_date: values.starting_date,
        start_bell_time: values.start_bell_time
          ? new Date(
            `${format(new Date(values.starting_date), "yyyy-MM-dd")} ${formatAMPM(values.start_bell_time)}`
          ).toISOString()
          : null,
        end_bell_time: values.end_bell_time
          ? new Date(
            `${format(new Date(values.starting_date), "yyyy-MM-dd")} ${formatAMPM(values.end_bell_time)}`
          ).toISOString()
          : null,
        client: values.client,
        schooladdress: values.schooladdress,
        pickup_addresses: values.start_bell_time
          ? newAddresses.pickAddress_arr
          : null,
        drop_off_addresses: values.end_bell_time
          ? newAddresses.dropAddress_arr
          : null,
        tips: userTips,
        vehicle_req: vehicleRequirements,
        service_detail: serviceDetails,
        other: others,
        additional_comment: values.additional_comment,
        attachment: values.attachment
      }
      clientRequest.mutate(obj, {
        onSuccess: async () => {
          toast.success("Request has been added successfully")
          queryClient.invalidateQueries(["getNotificationData"])
          queryClient.refetchQueries(["getNotificationSummary"])
          nav("/overview")
        }
      })
    }
  })
  useEffect(() => {
    const obj = clientData?.data?.data?.results?.filter(
      item => item.id == formik.values.client
    )
    formik.setFieldValue("schooladdressLongitude", obj?.[0]?.client_subadmin_info?.longitude);
    formik.setFieldValue("schooladdressLatitude", obj?.[0]?.client_subadmin_info?.latitude);
    formik.setFieldValue("defaultAddressLongitude", obj?.[0]?.longitude);
    formik.setFieldValue("defaultAddressLatitude", obj?.[0]?.latitude);
    formik.setFieldValue(
      "schooladdress",
      obj?.[0]?.client_subadmin_info?.address
    )
    formik.setFieldValue("defaultAddress", obj?.[0]?.address)
  }, [formik?.values?.client])

  useEffect(() => {
    formik.values.selectedDays.map((item, index) => {
      formik.setFieldValue(
        `aleg_pickup_addresses_geo[${index}]`,
        {
          latitude: formik.values.defaultAddressLatitude || null,
          longitude: formik.values.defaultAddressLongitude || null
        }
      )
      formik.setFieldValue(
        `bleg_drop_off_addresses_geo[${index}]`,
        {
          latitude: formik.values.defaultAddressLatitude || null,
          longitude: formik.values.defaultAddressLongitude || null
        }
      )
      formik.setFieldValue(
        `aleg_pickup_addresses[${index}]`,
        formik.values.defaultAddress || null
      )
      formik.setFieldValue(
        `bleg_drop_off_addresses[${index}]`,
        formik.values.defaultAddress || null
      )
    })
  }, [formik?.values?.defaultAddress])
  useEffect(() => {
    formik.values.selectedDays.map((item, index) => {
      formik.setFieldValue(
        `aleg_drop_off_addresses_geo[${index}]`,
        {
          latitude: formik.values.schooladdressLatitude || null,
          longitude: formik.values.schooladdressLongitude || null
        }
      )
      formik.setFieldValue(
        `bleg_pickup_addresses_geo[${index}]`,
        {
          latitude: formik.values.schooladdressLatitude || null,
          longitude: formik.values.schooladdressLongitude || null
        }
      )
      formik.setFieldValue(
        `aleg_drop_off_addresses[${index}]`,
        formik.values.schooladdress || null
      )
      formik.setFieldValue(
        `bleg_pickup_addresses[${index}]`,
        formik.values.schooladdress || null
      )
    })
  }, [formik?.values?.schooladdress])
  return (
    <Grid container>
      <Grid item xs={6}>
        <Box mb={2}>
          <NCText variant={"h4"}>{t("requests.add_request")}</NCText>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <NotificationAlert />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <FormikProvider value={formik}>
        <Form className={cls.form} onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <FormSection
                title={<span style={{ fontWeight: "500" }}>{t("requests.ride_details")}</span>}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormField
                      as={NCDatePicker}
                      label={t("requests.starting_date")}
                      name={"starting_date"}
                      onChange={v => formik.setFieldValue("starting_date", v)}
                      min={new Date().toISOString().split("T")[0]}
                      required={true}
                      dateTime={true}
                      error={formik?.errors?.starting_date}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormField
                      as={NCAutocomplete}
                      options={clientData?.data?.data?.results || []}
                      label={t("requests.select_client")}
                      name={"client"}
                      onChange={v => {
                        formik.setFieldValue("client", v)
                      }}
                      loading={clientLoading}
                      onSearch={v => setSearch(v)}
                      required={true}
                      error={formik?.errors?.client}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormField
                      as={NCAddress}
                      label={t("requests.school_address")}
                      name={"schooladdress"}
                      onChange={e => {
                        formik.setFieldValue("schooladdressLongitude", e?.geometry?.longitude);
                        formik.setFieldValue("schooladdressLatitude", e?.geometry?.latitude);
                        formik.setFieldValue("schooladdress", e?.name);
                      }}
                      error={formik?.errors?.schooladdress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormField
                      as={NCAddress}
                      label={t("requests.default_address")}
                      name={"defaultAddress"}
                      onChange={e => {
                        formik.setFieldValue("defaultAddressLongitude", e?.geometry?.longitude);
                        formik.setFieldValue("defaultAddressLatitude", e?.geometry?.latitude);
                        formik.setFieldValue("defaultAddress", e?.name);
                      }}
                      error={formik?.errors?.defaultAddress}
                    />
                  </Grid>
                </Grid>
                <FieldArray
                  name="aleg_pickup_addresses"
                  render={arrayHelpers => (
                    <>
                      <FormSection
                        title={
                          <span style={{ fontSize: "14px", fontWeight: "500" }}>
                            {t("requests.frequency")}
                          </span>
                        }
                        customBgColor="#E5E7EB"
                        subSection={true}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={12} lg={1}>
                            <NCText className={cls.text}>{t("requests.select_days")}</NCText>
                          </Grid>
                          <Grid item container xs={11} className={cls.checklistItems} style={{ gap: "20px" }}>
                            {days.map((day, i) => (
                              <NCText className={cls.checkedItems}>
                                <NCCheckbox
                                  // checked={formik?.values?.selectedDays?.includes(
                                  //   day
                                  // )}
                                  name={`selectedDays[${i}]`}
                                  key={i}
                                  color="primary"
                                  onChange={() => {
                                    handleCheckBoxClick(day, arrayHelpers)
                                  }}
                                  size="small"
                                />
                                {day}
                              </NCText>
                            ))}
                          </Grid>
                          {formik?.errors?.selectedDays ? (
                            <Box color={"#f44336"} mx={"14px"}>
                              <NCText variant="caption">
                                {formik.errors.selectedDays}
                              </NCText>
                            </Box>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </FormSection>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormSection
                            title={
                              <span
                                style={{ fontSize: "14px", fontWeight: "500" }}
                              >
                               {t("requests.aleg")}
                              </span>
                            }
                            customBgColor="#E5E7EB"
                            subSection={true}
                          >
                            <Grid container alignItems="center" spacing={2} className={cls.checklistItems}>
                              <Grid item xs={6} sm={6} md={2}>
                                <NCText className={cls.text}>{t("requests.bell_time")}</NCText>
                              </Grid>
                              <Grid item xs={10} sm={6} md={10}>
                                <FormField
                                  as={NCTimePicker}
                                  placeholder={t("requests.enter_start_bell_time")}
                                  name={"start_bell_time"}
                                  onChange={v =>
                                    formik.setFieldValue("start_bell_time", v)
                                  }
                                  error={formik?.errors?.start_bell_time}
                                />
                              </Grid>
                              {formik?.values?.selectedDays.map((day, i) => (
                                <>
                                  <Grid item xs={6} sm={6} md={2}>
                                    <NCText className={cls.text}>{day}</NCText>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={5}>
                                    <FormField
                                      key={`${day}${i}`}
                                      as={NCAddress}
                                      label={t("requests.pickup_address")}
                                      name={`aleg_pickup_addresses.${i}`}
                                      onChange={e => {
                                        formik.setFieldValue(
                                          `aleg_pickup_addresses_geo[${i}]`,
                                          {
                                            latitude: e?.geometry?.latitude,
                                            longitude: e?.geometry?.longitude
                                          }
                                        );
                                        formik.setFieldValue(
                                          `aleg_pickup_addresses[${i}]`,
                                          e?.name
                                        )
                                      }
                                      }
                                      required={true}
                                      error={
                                        formik?.errors?.aleg_pickup_addresses?.[
                                        i
                                        ]
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={5}>
                                    <FormField
                                      as={NCAddress}
                                      label={t("requests.drop_address")}
                                      name={`aleg_drop_off_addresses[${i}]`}
                                      onChange={e => {
                                        formik.setFieldValue(
                                          `aleg_drop_off_addresses_geo[${i}]`,
                                          {
                                            latitude: e?.geometry?.latitude,
                                            longitude: e?.geometry?.longitude
                                          }
                                        );
                                        formik.setFieldValue(
                                          `aleg_drop_off_addresses[${i}]`,
                                          e?.name
                                        )
                                      }
                                      }
                                      required={true}
                                      error={
                                        formik?.errors
                                          ?.aleg_drop_off_addresses?.[i]
                                      }
                                    />
                                  </Grid>
                                </>
                              ))}
                              {/* }} */}
                              {/* /> */}
                            </Grid>
                          </FormSection>
                        </Grid>
                        <Grid item xs={12}>
                          <FormSection
                            title={
                              <span
                                style={{ fontSize: "14px", fontWeight: "500" }}
                              >
                                {t("requests.bleg")}
                              </span>
                            }
                            customBgColor="#E5E7EB"
                            subSection={true}
                          >
                            <Grid container alignItems="center" spacing={2} className={cls.checklistItems}>
                              <Grid item xs={6} sm={6} md={2}>
                                <NCText className={cls.text}>{t("requests.bell_time")}</NCText>
                              </Grid>
                              <Grid item xs={10} sm={6} md={10}>
                                <FormField
                                  as={NCTimePicker}
                                  placeholder={t("requests.enter_end_bell_time")}
                                  disablePast={true}
                                  name={"end_bell_time"}
                                  onChange={v =>
                                    formik.setFieldValue("end_bell_time", v)
                                  }
                                  error={formik?.errors?.end_bell_time}
                                />
                              </Grid>
                              {formik?.values?.selectedDays.map((day, i) => (
                                <>
                                  <Grid item xs={6} sm={6} md={2}>
                                    <NCText className={cls.text}>{day}</NCText>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={5}>
                                    <FormField
                                      key={`${day}${i}`}
                                      as={NCAddress}
                                      label={t("requests.pickup_address")}
                                      name={`bleg_pickup_addresses.${i}`}
                                      onChange={e => {
                                        formik.setFieldValue(
                                          `bleg_pickup_addresses_geo[${i}]`,
                                          {
                                            latitude: e?.geometry?.latitude,
                                            longitude: e?.geometry?.longitude
                                          }
                                        );
                                        formik.setFieldValue(
                                          `bleg_pickup_addresses[${i}]`,
                                          e?.name
                                        )
                                      }
                                      }
                                      required={true}
                                      error={
                                        formik?.errors?.bleg_pickup_addresses?.[
                                        i
                                        ]
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={5}>
                                    <FormField
                                      as={NCAddress}
                                      label={t("requests.drop_address")}
                                      name={`bleg_drop_off_addresses[${i}]`}
                                      onChange={e => {
                                        formik.setFieldValue(
                                          `bleg_drop_off_addresses_geo[${i}]`,
                                          {
                                            latitude: e?.geometry?.latitude,
                                            longitude: e?.geometry?.longitude
                                          }
                                        );
                                        formik.setFieldValue(
                                          `bleg_drop_off_addresses[${i}]`,
                                          e?.name
                                        )
                                      }
                                      }
                                      required={true}
                                      error={
                                        formik?.errors
                                          ?.bleg_drop_off_addresses?.[i]
                                      }
                                    />
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </FormSection>
                        </Grid>
                      </Grid>
                    </>
                  )}
                />
              </FormSection>
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection
                title={
                  <span style={{ fontWeight: "500" }}>{t("requests.specific_details")}</span>
                }
              >
                <Grid container>
                  <NCText className={cls.heading}>{t("requests.pop_type")}</NCText>
                </Grid>
                <Grid container className={cls.container}>
                  {userTipsData &&
                    userTipsData?.data?.data.map((item, i) => (
                      <Grid item xs={12} md={2}>
                        <NCText className={cls.checkedItems}>
                          <NCCheckbox
                            color="primary"
                            size="small"
                            onChange={e => handleUserTips(e, i)}
                          />
                          {item.name}
                        </NCText>
                      </Grid>
                    ))}
                </Grid>
                <Grid container>
                  <NCText className={cls.heading}>{t("requests.vehicle_req")}</NCText>
                </Grid>
                <Grid container className={cls.container}>
                  {vehicleRequirementsData &&
                    vehicleRequirementsData?.data?.data.map((item, i) => (
                      <Grid item xs={12} md={2}>
                        <NCText className={cls.checkedItems}>
                          <NCCheckbox
                            color="primary"
                            size="small"
                            onChange={e => handleVehicleRequirements(e, i)}
                          />
                          {item.name}
                        </NCText>
                      </Grid>
                    ))}
                </Grid>
                <Grid container>
                  <NCText className={cls.heading}>{t("requests.service_details")}</NCText>
                </Grid>
                <Grid container className={cls.container}>
                  {serviceDetailsData &&
                    serviceDetailsData?.data?.data.map((item, i) => (
                      <Grid item xs={12} md={2}>
                        <NCText className={cls.checkedItems}>
                          <NCCheckbox
                            color="primary"
                            size="small"
                            onChange={e => handleServiceDetails(e, i)}
                          />
                          {item.name}
                        </NCText>
                      </Grid>
                    ))}
                </Grid>
                <Grid container>
                  <NCText className={cls.heading}>{t("requests.other")}</NCText>
                </Grid>
                <Grid container className={cls.container}>
                  {otherRequirementsData &&
                    otherRequirementsData?.data?.data.map((item, i) => (
                      <Grid item xs={12} md={2}>
                        <NCText className={cls.checkedItems}>
                          <NCCheckbox
                            color="primary"
                            size="small"
                            onChange={e => handleOther(e, i)}
                          />
                          {item.name}
                        </NCText>
                      </Grid>
                    ))}
                </Grid>
              </FormSection>
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection
                title={
                  <span style={{ fontWeight: "500" }}>{t("requests.additional_comments")}</span>
                }
              >
                <FormField
                  label={t("requests.add_comment")}
                  multiline
                  rows={3}
                  name={"additional_comment"}
                  required={true}
                  error={formik?.errors?.additional_comment}
                />
                <label htmlFor="upload-doc">
                  <input
                    style={{ display: "none" }}
                    id="upload-doc"
                    name="upload-doc"
                    ref={attachmentRef}
                    type="file"
                    value={formik?.values?.attachment?.files}
                    onChange={e => {
                      formik.setFieldValue("attachment", e.target.files[0])
                    }}
                  />
                  {formik?.values?.attachment ? null : (
                    <Box className="cursor-pointer">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <UploadIcon />
                        </Grid>
                        <Grid item>
                          <NCText color={"primary"}>
                            {t("requests.upload_bip")}
                          </NCText>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </label>
                {formik?.values?.attachment ? (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <NCText color={"primary"}>
                        {formik?.values?.attachment?.name}
                      </NCText>
                    </Grid>
                    <Grid item>
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => {
                          attachmentRef.current.value = null
                          formik.setFieldValue("attachment", null)
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </FormSection>
            </Grid>
          </Grid>
          <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <NCButton
                    onClick={() => nav("/overview")}
                    variant="outlined"
                    color="primary"
                  >
                    {t("common.cancel")}
                  </NCButton>
                </Grid>
                <Grid item>
                  <NCButton variant="contained" color="primary" type="submit">
                    {t("common.save")}
                  </NCButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <NCModal
            open={open}
            title={modalTitle}
            text={modalText}
            onClose={handleClose}
            handleDiscard={handleDiscard}
          />
          {(otherRequirementLoading ||
            serviceDetailLoading ||
            userTipsLoading ||
            vehicleRequirementLoading ||
            clientRequest.isLoading) && <NCLoader open={true} />}
        </Form>
      </FormikProvider>
    </Grid>
  )
}

export default AddRequest
