import React, { useState } from "react"
import NCButton from "./NCButton"
import { makeStyles } from "@material-ui/core"
import NCSvgIcon from "./NCSvgIcon"
import { ActivityIcon } from "../libs/icons"
import ActivityLog from "../components/ActivityLog"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  audit_content: {
    position: "relative",
    zIndex: 2
  }
})
const NCAuditButton = ({ data, isLoading }) => {
  const { t } = useTranslation()
  const [chatWindow, showChatWindow] = useState(false)
  const cls = useStyles()
  return (
    <div>
      <NCButton
        variant="contained"
        color="primary"
        endIcon={<NCSvgIcon component={ActivityIcon} inheritViewBox />}
        onClick={() => showChatWindow(!chatWindow)}
      >
        {t("shared.audit_log")}
      </NCButton>
      <div className={cls.audit_content}>
        {chatWindow ? (
          <ActivityLog
            showChatWindow={showChatWindow}
            data={data}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  )
}

export default NCAuditButton
