import { Button, Grid, useMediaQuery, useTheme } from "@material-ui/core"
import FormSection from "../../components/FormSection"
import {
  useGetOtherRequirements,
  useGetServiceDetails,
  useGetUserTips,
  useGetVehicleRequirements,
  useGetRequestAuditLog
} from "../../services/queries"
import NCText from "../../shared/NCText"
import { makeStyles, Box } from "@material-ui/core"
import TopNavBar from "../../components/TopNavBar"
import NCCheckbox from "../../shared/NCCheckbox"
import { useGetClientRequestDetail } from "../../services/queries"
import { useParams } from "react-router-dom"
import {
  formatDate,
  formatAMPM,
  getAddressValue,
  loggedInUser,
  getDocNameFromPath
} from "../../libs/utils"
import NCLoader from "../../shared/NCLoader"
import NCAuditButton from "../../shared/NCAuditButton"
import NCButton from "../../shared/NCButton"
import {
  useApproveClientRequest,
  useClientRequestDecline
} from "../../services/mutations"
import { toast } from "react-toastify"
import { useState } from "react"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { ActionIcon, DocumentIcon } from "../../libs/icons"
import ApproveRequestModal from "../../components/request/ApproveRequestModal"
import NCModal from "../../shared/NCModal"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useQueryClient } from "react-query"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
// import RideDetails from "./RideDetails"

const useStyles = makeStyles((theme) => ({
  label: { fontSize: "14px", fontWeight: "500", whiteSpace: "nowrap" },
  title: {
    fontSize: "20px"
  },
  button: {
    borderRadius: "20px",
    fontSize: "12px",
    width: "180px"
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between"
  },
  text: {
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center"
  },
  checkedItems: {
    fontSize: "14px",
    fontWeight: "400"
  },
  checklistItems: {
    fontSize: "14px",
    fontWeight: "400",  
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  subHeading: {
    fontSize: "12px",
    color: "#9CA3AF",
    fontWeight: "400"
  },
  heading: {
    fontSize: "14px",
    fontWeight: "550"
  },
  formsection: {
    marginTop: "15px"
  },
  rideContainer: {
    marginBottom: "10px"
  },
  container: {
    "& .MuiCheckbox-root": {
      paddingRight: "3px"
    }
  },
  subContainer: {
    marginBottom: "10px"
  },
  subSection: {
    paddingBottom: "10px"
  },
  approve_button: {
    backgroundColor: "#1DB954",
    color: "#FFFFFF",
    padding: "5px 20px",
    margin: "8px",
    "&:hover": {
      backgroundColor: "#64ed88"
    }
  },
  decline_button: {
    border: "1px solid #E22134",
    color: "#E22134",
    padding: "5px 20px",
    margin: "8px",
    marginRight: "1px",
    "&:hover": {
      backgroundColor: "#c2c4c0"
    }
  }
}))

const RequestDetails = () => {
  let { id } = useParams()
  const { t } = useTranslation();
  const cls = useStyles()
  const queryClient = useQueryClient()
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [daysChecked, setDaysChecked] = useState([])
  // const [selectedTab, setSelectedTab] = useState(1)
  const { data: result, isLoading: clientRequestLoading, refetch } =
    useGetClientRequestDetail(id)
  const { data: otherRequirementsData } = useGetOtherRequirements()
  const { data: serviceDetailsData } = useGetServiceDetails()
  const { data: userTipsData } = useGetUserTips()
  const approveRequest = useApproveClientRequest("")
  const { data: vehicleRequirementsData } = useGetVehicleRequirements()
  const user = loggedInUser()
  const declineRequest = useClientRequestDecline()
  const requestStatus = result?.data?.data?.status

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const handleApproveClick = () => {
    setShowApproveModal(true)
  }

  const handleDeclineClick = () => {
    setShowDeleteModel(true)
  }
  const declineRequestMethod = () => {
    declineRequest.mutate(clientRequestsData.id, {
      onSuccess: () => {
        toast.success(t("requests.request_declined"))
        refetch();
        queryClient.invalidateQueries(["getClientRequestDetail"])
        queryClient.invalidateQueries(["getNotificationData"])
        queryClient.refetchQueries(["getNotificationSummary"])
      }
    })
  }

  let clientRequestsData = result?.data?.data || null
  const { data, isLoading } = useGetRequestAuditLog(
    { request_id: id },
    { enabled: user.user_type === 1 ? true : false }
  )
  const auditData = data?.data?.data?.results || []
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ]
  useEffect(() => {
    if (clientRequestsData) {
      let add =
        clientRequestsData.drop_off_addresses &&
          clientRequestsData.drop_off_addresses.length
          ? clientRequestsData.drop_off_addresses
          : clientRequestsData.pickup_addresses
      setDaysChecked(add?.map(address => address.week_info.name))
    }
  }, [clientRequestsData])
  // const [check, setChecked] = useState(false)
  // useEffect(() => {
  //   setChecked(clientRequestsData?.is_default_driver || false)
  // }, [clientRequestsData?.is_default_driver])
  // const handleCheck = () => {
  //   setChecked(!check)
  // }
  // const approveDefaultDriver = () => {
  //   const data = {
  //     id: clientRequestsData?.id,
  //     is_default_driver: check
  //   }
  //   approveRequest.mutate(data, {
  //     onSuccess: () => {
  //       toast.success("Driver Assigned successfully!")
  //       queryClient.invalidateQueries(["getClientRequestDetail"])
  //     }
  //   })
  // }

  const approvedTitle = (
    <Grid item className={cls.titleSection}>
      {t("requests.approved_details")}
      {user.user_type === 4 || user.user_type === 1 ? <Button
        onClick={() => setShowApproveModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        {t("common.edit")}
      </Button> : null}
    </Grid>
  )
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2} display={"flex"}>
          {/* {selectedTab === 2 ? (
            <NCText variant={"h4"} style={{ marginRight: "10px" }}>
              Ride Details
            </NCText>
          ) : ( */}
          <NCText variant={"h4"} style={{ marginRight: "10px" }}>
          {t("requests.request_details")}
          </NCText>
          {/* )} */}
        </Box>

        <Box display={"flex"}>
          {!isSmallScreen && user.user_type === 1 ? (
            <NCAuditButton data={auditData} isLoading={isLoading} />
          ) : null}
          <Box marginLeft={"5px"}>
            <NotificationAlert />
          </Box>
        </Box>
      </Grid>
      {isSmallScreen && <Grid item xs={6} sm={4}>
        <Box>
          {user.user_type === 1 ? (
            <NCAuditButton data={auditData} isLoading={isLoading} />
          ) : null}
        </Box>
      </Grid>}
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      {(user.user_type === 1 && requestStatus === 1) ||
        (user.user_type === 4 && requestStatus === 1) ? (
        <Grid
          item
          xs={12}
          style={{
            display: isSmallScreen ? "block" : "flex",
            justifyContent: "space-between",
            backgroundColor: "rgba(29, 185, 84, 0.1)",
            padding: "5px 10px",
            borderRadius: "8px",
            marginBottom: "15px",
            alignItems: "center"
          }}
        >
          <Box color={"green"}>
            <NCText>
              <NCSvgIcon component={ActionIcon} inheritViewBox /> {
                t("requests.approval_message")
              }
            </NCText>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <NCButton
              className={cls.approve_button}
              onClick={() => {
                handleApproveClick(id)
              }}
            >
              {t("common.approve")}
            </NCButton>
            <NCButton
              className={cls.decline_button}
              onClick={() => {
                handleDeclineClick(id)
              }}
            >
              {t("common.decline")}
            </NCButton>
          </Box>
        </Grid>
      ) : null}
      {/* {user.user_type === 4 && requestStatus === 2 ? (
        <Grid item xs={12}>
          <Grid mb={2}>
            <Box mb={2}>
              <ButtonGroup disableElevation>
                <Button
                  style={{
                    borderStartStartRadius: "20px",
                    borderBottomLeftRadius: "20px"
                  }}
                  variant={selectedTab === 1 ? "contained" : "outlined"}
                  onClick={() => setSelectedTab(1)}
                  color={selectedTab === 1 ? "primary" : ""}
                >
                  Ride Details
                </Button>

                <Button
                  style={{
                    borderStartEndRadius: "20px",
                    borderBottomRightRadius: "20px"
                  }}
                  variant={selectedTab === 2 ? "contained" : "outlined"}
                  onClick={() => setSelectedTab(2)}
                  color={selectedTab === 2 ? "primary" : ""}
                >
                  Upcoming/Current Trip
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
      ) : null} */}
      {/* {selectedTab === 2 ? (
        <RideDetails id={id} />
      ) : ( */}
      <Grid item xs={12} container className={cls.container}>
        <Grid item xs={12} className={cls.rideContainer}>
          {clientRequestsData?.status === 2 ? <FormSection
            title={approvedTitle}
          >
            <Grid container className={cls.subContainer} spacing={2}>
              <Grid item xs={12} md={4}>
                <NCText className={cls.subHeading}>{t("requests.approve_modal.actual_start_date")}</NCText>
                <NCText className={cls.text}>
                  {formatDate(clientRequestsData?.actual_starting_date || "")}
                </NCText>
              </Grid>
              {/* {clientRequestsData?.is_a_leg_exists ? <Grid item xs={12} md={4}>
                <NCText className={cls.subHeading}>{t("requests.pickup_time")}</NCText>
                <NCText className={cls.text}>
                  {formatAMPM(clientRequestsData?.pickup_time || "")}
                </NCText>
              </Grid> : null}
              {clientRequestsData?.is_b_leg_exists ? <Grid item xs={12} md={4}>
                <NCText className={cls.subHeading}>{t("requests.bleg_eta")}</NCText>
                <NCText className={cls.text}>
                  {formatAMPM(clientRequestsData?.bleg_pickup_time || "")}
                </NCText>
              </Grid> : null} */}
            </Grid>
          </FormSection> : null}
        </Grid>
        <Grid item xs={12} className={cls.rideContainer}>
          <FormSection
            title={<span style={{ fontWeight: "500" }}>{t("requests.ride_details")}</span>}
          >
            <Grid container className={cls.subContainer} spacing={2}>
              <Grid item xs={12} md={4}>
                <NCText className={cls.subHeading}>{t("requests.starting_date")}</NCText>
                <NCText className={cls.text}>
                  {formatDate(clientRequestsData?.starting_date || "")}
                </NCText>
              </Grid>
              <Grid item xs={12} md={4}>
                <NCText className={cls.subHeading}>{t("requests.select_client")}</NCText>
                <NCText className={cls.text}>
                  {clientRequestsData?.client_name || ""}
                </NCText>
              </Grid>
              <Grid item xs={12} md={4}>
                <NCText className={cls.subHeading}>{t("requests.school_address")}</NCText>
                <NCText className={cls.text}>
                  {getAddressValue(
                    clientRequestsData?.client_subadmin_info?.address || ""
                  ) || ""}
                </NCText>
              </Grid>
              {/* {user.user_type === 4 ? (
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    <NCText style={{ paddingTop: "6px" }}>
                      <NCCheckbox
                        color="primary"
                        size="small"
                        checked={check}
                        onClick={() => {
                          handleCheck()
                        }}
                      />
                      Keep same driver for all rides
                    </NCText>
                    {clientRequestsData?.is_default_driver ? (
                      <Box top={8}>
                        <DriverChangeIcon
                          style={{
                            width: "30px",
                            marginLeft: "5px",
                            top: "16px",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            approveDefaultDriver()
                          }}
                        />
                      </Box>
                    ) : (
                      <Box top={8}>
                        <DriverSaveIcon
                          style={{
                            width: "30px",
                            marginLeft: "5px",
                            top: "16px",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            approveDefaultDriver()
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                ) : null} */}
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <FormSection
                  title={
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      {t("requests.frequency")}
                    </span>
                  }
                  customBgColor="#E5E7EB"
                  subSection={true}
                >
                  <Grid container spacing={5}>
                    <Grid item container xs={12} className={cls.checklistItems} style={{ gap: "20px" }}>
                      {days.map((day, i) => (
                        <NCText className={cls.checkedItems}>
                          <NCCheckbox
                            checked={daysChecked.includes(day) ? true : false}
                            key={i}
                            color="primary"
                            size="small"
                          />
                          {day}
                        </NCText>
                      ))}
                    </Grid>
                  </Grid>
                </FormSection>
              </Grid>

              {clientRequestsData?.is_a_leg_exists ? (
                <Grid item xs={12} style={{ marginBottom: "8px" }}>
                  <FormSection
                    title={
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        {t("requests.aleg")}
                      </span>
                    }
                    customBgColor="#E5E7EB"
                    subSection={true}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={2}>
                        <NCText className={cls.label}>{t("requests.bell_time")}:</NCText>
                      </Grid>
                      <Grid item xs={6} sm={6} md={10}>
                        <NCText>
                          <span className={cls.text}>
                            {formatAMPM(clientRequestsData?.start_bell_time) ||
                              ""}
                          </span>
                        </NCText>
                      </Grid>
                      {clientRequestsData?.pickup_addresses.map(address => (
                        <>
                          <Grid item xs={12} sm={12} md={2}>
                            <NCText className={cls.label}>
                              {address.week_info.name}:
                            </NCText>
                          </Grid>
                          <Grid item xs={6} sm={6} md={5}>
                            <NCText>
                              <span className={cls.subHeading}>
                              {t("requests.pickup_address")}
                              </span>
                            </NCText>
                            <NCText className={cls.text}>
                              {getAddressValue(address?.location?.address)}
                            </NCText>
                          </Grid>
                          <Grid item xs={6} sm={6} md={5}>
                            <NCText>
                              <span className={cls.subHeading}>
                                {t("requests.drop_address")}
                              </span>
                            </NCText>
                            <NCText className={cls.text}>
                              {getAddressValue(address?.drop_location?.address)}
                            </NCText>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </FormSection>
                </Grid>
              ) : null}
              {clientRequestsData?.is_b_leg_exists ? (
                <Grid item xs={12}>
                  <FormSection
                    title={
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        {t("requests.bleg")}
                      </span>
                    }
                    customBgColor="#E5E7EB"
                    subSection={true}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={2}>
                        <NCText className={cls.label}>{t("requests.bell_time")}:</NCText>
                      </Grid>
                      <Grid item xs={6} sm={6} md={10}>
                        <NCText>
                          <span className={cls.text}>
                            {formatAMPM(clientRequestsData?.end_bell_time) ||
                              ""}
                          </span>
                        </NCText>
                      </Grid>
                      {clientRequestsData?.drop_off_addresses.map(address => (
                        <>
                          <Grid item xs={12} sm={12} md={2}>
                            <NCText className={cls.label}>
                              {address.week_info.name}:
                            </NCText>
                          </Grid>
                          <Grid item xs={6} sm={6} md={5}>
                            <NCText>
                              <span className={cls.subHeading}>
                               {t("requests.pickup_address")}
                              </span>
                            </NCText>
                            <NCText className={cls.text}>
                              {getAddressValue(
                                address?.pickup_location?.address
                              )}
                            </NCText>
                          </Grid>
                          <Grid item xs={6} sm={6} md={5}>
                            <NCText>
                              <span className={cls.subHeading}>
                                {t("requests.drop_address")}
                              </span>
                            </NCText>
                            <NCText className={cls.text}>
                              {getAddressValue(address?.location?.address)}
                            </NCText>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </FormSection>
                </Grid>
              ) : null}
            </Grid>
          </FormSection>
        </Grid>
        <Grid item xs={12} sm={8} md={12}>
          <FormSection
            title={<span style={{ fontWeight: "500" }}>{t("requests.specific_details")}</span>}
          >
            <Grid container>
              <NCText className={cls.heading}>{t("requests.pop_type")}</NCText>
            </Grid>
            <Grid container className={cls.subSection}>
              {userTipsData &&
                userTipsData?.data?.data.map((item, i) => (
                  <Grid item xs={12} md={2}>
                    <NCText className={cls.checkedItems}>
                      <NCCheckbox
                        color="primary"
                        size="small"
                        checked={
                          clientRequestsData?.tips.includes(i + 1)
                            ? true
                            : false
                        }
                      />
                      {item.name}
                    </NCText>
                  </Grid>
                ))}
            </Grid>
            <Grid container>
              <NCText className={cls.heading}>{t("requests.vehicle_req")}</NCText>
            </Grid>
            <Grid container className={cls.subSection}>
              {vehicleRequirementsData &&
                vehicleRequirementsData?.data?.data.map((item, i) => (
                  <Grid item xs={12} md={2}>
                    <NCText className={cls.checkedItems}>
                      <NCCheckbox
                        color="primary"
                        size="small"
                        checked={
                          clientRequestsData?.vehicle_req.includes(i + 1)
                            ? true
                            : false
                        }
                      />
                      {item.name}
                    </NCText>
                  </Grid>
                ))}
            </Grid>
            <Grid container>
              <NCText className={cls.heading}>{t("requests.service_details")}</NCText>
            </Grid>
            <Grid container className={cls.subSection}>
              {serviceDetailsData &&
                serviceDetailsData?.data?.data.map((item, i) => (
                  <Grid item xs={12} md={2}>
                    <NCText className={cls.checkedItems}>
                      <NCCheckbox
                        color="primary"
                        size="small"
                        checked={
                          clientRequestsData?.service_detail.includes(i + 1)
                            ? true
                            : false
                        }
                      />
                      {item.name}
                    </NCText>
                  </Grid>
                ))}
            </Grid>
            <Grid container>
              <NCText className={cls.heading}>{t("requests.other")}</NCText>
            </Grid>
            <Grid container className={cls.subSection}>
              {otherRequirementsData &&
                otherRequirementsData?.data?.data.map((item, i) => (
                  <Grid item xs={12} md={2}>
                    <NCText className={cls.checkedItems}>
                      <NCCheckbox
                        color="primary"
                        size="small"
                        checked={
                          clientRequestsData?.other.includes(i + 1)
                            ? true
                            : false
                        }
                      />
                      {item.name}
                    </NCText>
                  </Grid>
                ))}
            </Grid>
          </FormSection>
        </Grid>
        <Grid item xs={12} sm={8} md={12}>
          <FormSection
            title={
              <span style={{ fontWeight: "500" }}>{t("requests.additional_comments")}</span>
            }
          >
            <Grid container>
              <Grid item xs={6}>
                <NCText>
                  <span className={cls.subHeading}>{t("requests.comment")}</span>
                </NCText>
                <NCText className={cls.text}>
                  {clientRequestsData?.additional_comment}
                </NCText>
              </Grid>
              <Grid item xs={6}>
                <NCText>
                  <span className={cls.subHeading}>{t("requests.attachment")}</span>
                </NCText>
                <NCText
                  className={`${cls.text} cursor-pointer`}
                  onClick={() => window.open(clientRequestsData?.attachment)}
                >
                  {clientRequestsData?.attachment ? <DocumentIcon /> : null}
                  {clientRequestsData?.attachment
                    ? getDocNameFromPath(clientRequestsData?.attachment)
                    : null}
                </NCText>
              </Grid>
            </Grid>
          </FormSection>
        </Grid>
      </Grid>
      {/* )} */}
      <NCLoader
        open={
          clientRequestLoading ||
          approveRequest.isLoading ||
          declineRequest.isLoading
        }
      />
      <ApproveRequestModal
        data={clientRequestsData || {}}
        modalState={showApproveModal}
        isLoading={clientRequestLoading}
        handleClose={(status) => {
          setShowApproveModal(false)
          queryClient.invalidateQueries(["getClientRequestDetail"])
          status && refetch()
        }}
      />
      <NCModal
        open={showDeleteModel}
        title={t("requests.decline_request")}
        text={t("requests.decline_request_confirmation_text")}
        onClose={() => setShowDeleteModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => {
              declineRequestMethod()
              setShowDeleteModel(false)
            }}
            style={{ width: "auto" }}
          >
            {t("common.decline")}
          </NCButton>
        }
      />
    </Grid>
  )
}

export default RequestDetails
