import { Form, FormikProvider, useFormik } from "formik"
import NCModal from "../../shared/NCModal"
import { Grid } from "@material-ui/core"
import * as Yup from "yup"
import { useGetDispatchersList } from "../../services/queries"
import FormField from "../FormField"
import NCAutocomplete from "../../shared/NCAutocomplete"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import { useAssignDispatcherToDriver } from "../../services/mutations"
import NCLoader from "../../shared/NCLoader"
import { toast } from "react-toastify"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const AssignDispatcherModal = ({
  driverId,
  refetch,
  modalState = false,
  handleClose
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState(null)
  const { data } = useGetDispatchersList(null, search)
  const assignDispatcherApi = useAssignDispatcherToDriver()
  const queryClient = useQueryClient()
  const { results: dispatcherList, isLoading } = data?.data?.data || []
  const formik = useFormik({
    initialValues: {
      dispatcher: ""
    },
    validationSchema: Yup.object().shape({
      dispatcher: Yup.string().required("Dispatcher is required")
    }),
    enableReinitialize: true,
    onSubmit: values => {
      assignDispatcherApi.mutate(
        { ...values, id: driverId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getDriverDetail"])
            toast.success(t("drivers.dispatcher_assigned"))
            refetch?.();
            handleClose()
          }
        }
      )
    }
  })
  const assignModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              as={NCAutocomplete}
              options={dispatcherList || []}
              label={t("drivers.dispatcher")}
              name={"dispatcher"}
              onChange={v => {
                formik.setFieldValue("dispatcher", v)
              }}
              onSearch={v => setSearch(v)}
              loading={isLoading}
              required={true}
              error={formik?.errors?.dispatcher}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
  return (
    <>
      <NCModal
        open={modalState}
        title={t("drivers.assign_dispatcher")}
        text={assignModal}
        onClose={() => handleClose()}
        size={"lg"}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            {t("common.approve")}
          </NCButton>
        }
      />
      {assignDispatcherApi.isLoading && <NCLoader open={true} />}
    </>
  )
}

export default AssignDispatcherModal
