import { useState } from "react"
import { Grid, makeStyles, Paper, Box } from "@material-ui/core"
import NCTitle from "../shared/NCTitle"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import { Form, useFormik, FormikProvider } from "formik"
import FormField from "../components/FormField"
import * as Yup from "yup"
import { useAuthentication } from "../services/mutations"
//import { toast } from "react-toastify"
import NCLoader from "../shared/NCLoader"
import PhoneNumber from "../components/PhoneNumber"
import VerifyAuth from "./VerifyAuth"
import withContext from "../components/DrawerConsumer"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px",
    minWidth: "350px"
  },
  title: {
    marginTop: "12px",
    marginBottom: "5px"
  },
  text: {
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "20px"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px"
  },
  formField: {
    marginTop: "20px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& .MuiFormLabel-root": {
      marginBottom: "5px"
    },
    "& .MuiButtonBase-root": {
      marginTop: "25px"
    }
  }
}))

const PhoneAuthentication = props => {
  const cls = useStyles()
  const phoneAuth = useAuthentication()
  const [isPhoneContent, setIsPhoneContent] = useState(true)
  const [isLinkSent, setIsLinkSent] = useState(false)
  const [verificationId, setVerificationId] = useState()
  const formik = useFormik({
    initialValues: {
      phone: ""
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string().required("Phone number is required")
    }),
    onSubmit(values) {
      phoneAuth.mutate(
        { two_factor_config: { method: "SMS", phone_number: values.phone } },
        {
          onSuccess: response => {
            setVerificationId(response?.data?.data?.id)
            // toast.success("Verification code is sent to your phone")
            props.setEmailMessage(
              "Please check your inbox verification code sent to your mobile number."
            )
            props.showEmailModal(true)
            props.showModalHeader("Verification Code sent!")
            setIsPhoneContent(false)
            setIsLinkSent(true)
          },
          onError: () => {
            //console.log("error block")
          }
        }
      )
    }
  })
  const enterPhoneContent = (
    <Grid item xs={8}>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>Enter Phone Number</NCTitle>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <Box mt={"15px"}>
              <FormField
                as={PhoneNumber}
                name={"phone"}
                onChange={e => formik.setFieldValue("phone", `+${e}`)}
                required={true}
                error={formik?.errors?.phone}
              />
            </Box>
            <NCButton variant="contained" color="primary" type="submit">
              Send Code
            </NCButton>
          </Form>
        </FormikProvider>
      </Paper>
    </Grid>
  )
  return (
    <>
      {phoneAuth.isLoading && <NCLoader open={true} />}
      {isPhoneContent && <AuthComponent>{enterPhoneContent}</AuthComponent>}
      {isLinkSent && (
        <VerifyAuth
          verificationId={verificationId}
          phone={formik?.values?.phone}
        />
      )}
    </>
  )
}

export default withContext(PhoneAuthentication)
