import { useRef } from "react"
import { CallIcon } from "../../libs/icons"
import { useGetAllUsers } from "../../services/queries"
import { makeStyles } from "@material-ui/core"
import { PhoneNumberUtil } from "google-libphonenumber"

const useStyles = makeStyles(() => ({
  phone: {
    fontSize: "24px",
    color: "#1EAAC4",
    marginLeft: "10px",
    display: "inline-block"
  },
  phoneContainer: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none"
  }
}))

const WebCall = ({ uuid }) => {
  const cls = useStyles()

  const callRef = useRef()

  const phoneUtil = PhoneNumberUtil.getInstance()

  const { data: userList } = useGetAllUsers(null, uuid)

  const userData = userList?.data?.data?.results?.[0] || {}

  const formatPhoneNumber = number => {
    try {
      // Parse the phone number
      const phoneNumber = phoneUtil.parseAndKeepRawInput(number, "ZZ") // 'ZZ' stands for unknown region

      const nationalNumber = "" + phoneNumber.getNationalNumber()

      // Format the parsed phone number
      const formattedNumber = `+${phoneNumber.getCountryCode()} ${nationalNumber.slice(
        0,
        3
      )}-${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6)}`
      return formattedNumber
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error parsing or formatting phone number:", error)
      return number // Return original number if formatting fails
    }
  }

  return userData?.phone ? (
    <a
      href={"tel:" + userData?.phone}
      ref={callRef}
      className={cls.phoneContainer}
    >
      <CallIcon style={{ cursor: "pointer" }} width={"24px"} height={"24px"} />
      <span className={cls.phone}>{formatPhoneNumber(userData?.phone)}</span>
    </a>
  ) : null
}

export default WebCall
