import { createContext } from "react";

export const ChatContext = createContext();

export const ChatProvider = ({children, selectedUser,fetchUserList}) => {
    return <ChatContext.Provider value={{
        selectedUser:selectedUser,
        fetchUserList: fetchUserList
    }}>
        {children}
    </ChatContext.Provider>
}

export default ChatProvider;