import { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useUpdateClient } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import FormField from "../FormField"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import { useGetSubAdmins } from "../../services/queries"
import NCAutocomplete from "../../shared/NCAutocomplete"
import { useTranslation } from "react-i18next"

const EditSubAdminModal = ({ modalState = false, handleClose, data = {} }) => {
  const [search, setSearch] = useState(null)
  const { t } = useTranslation();
  const { data: subAdminData, isLoading } = useGetSubAdmins(null, search || data.name)
  const subAdmins = subAdminData?.data?.data?.results || []
  const queryClient = useQueryClient()
  const updateClient = useUpdateClient()
  const [adminName, setAdminName] = useState(data.adminName)

  const formik = useFormik({
    initialValues: {
      subadmin: data.subAdmin || ""
    },
    validationSchema: Yup.object().shape({
      subadmin: Yup.string().required(t("client.error.subadmin_required"))
    }),
    // enableReinitialize: true,
    onSubmit(values) {
      updateClient.mutate(
        { ...values, id: data.clientId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getClientDetail"])
            queryClient.invalidateQueries(["getClientAuditLog"])
            toast.success(t("client.subadmin_edited"))
            handleClose()
          }
        }
      )
    }
  })

  useEffect(() => {
    if (formik.values.subadmin) {
      const obj = subAdmins?.filter(item => item.id == formik.values.subadmin)
      obj && obj.length ? setAdminName(obj?.[0]?.admin?.company_name) : setAdminName(null)
    } else {
      setAdminName(null)
    }
  }, [formik.values.subadmin])

  const emergencyContactModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              as={NCAutocomplete}
              options={subAdmins || []}
              label={t("client.select_subadmin")}
              name={"subadmin"}
              onChange={v => {
                formik.setFieldValue("subadmin", v)
              }}
              loading={isLoading}
              onSearch={v => setSearch(v)}
              required={true}
              error={formik?.errors?.subadmin}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={t("client.admin")}
              name={"admin"}
              value={adminName || data?.adminName}
              readOnly={true}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
  return (
    <>
      <NCModal
        open={modalState}
        title={t("client.edit_subadmin_school")}
        text={emergencyContactModal}
        onClose={() => handleClose()}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            {t("common.update")}
          </NCButton>
        }
      />
      {updateClient.isLoading && <NCLoader open={true} />}
    </>
  )
}

export default EditSubAdminModal
