import { Box, Grid } from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCButton from "../../shared/NCButton"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { AddIcon } from "../../libs/icons"
import NCCalendar from "../../shared/NCCalendar"
import { useQueryClient } from "react-query"
import {
  useGetDriverAppointments,
  useGetDriverAvailability,
  useGetDriverList
} from "../../services/queries"
import { useState } from "react"
import AppointmentModal from "./AddAppointmentModal"
import { format } from "date-fns"
import NCAutocomplete from "../../shared/NCAutocomplete"
import NotificationAlert from "../notification/NotificationAlert"
import { useTranslation } from "react-i18next"
const Appointments = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient()
  const [showAppointModal, setShowAppointmentModal] = useState(false)
  const [selectedDriverId, setSelectedDriverId] = useState()
  const [search, setSearch] = useState(null)
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"))
  const [startView, setStartView] = useState("DAY")
  const { data, isLoading } = useGetDriverList(search)
  const allData = data?.data?.data?.results || []

  const handleAppointClick = () => {
    setShowAppointmentModal(true)
  }

  const { data: driverAvailability } = useGetDriverAvailability(
    {
      driver_id: selectedDriverId,
      date: startDate,
      filter: startView
    },
    {
      enabled: !!selectedDriverId && !!allData.filter(item => item?.driver?.id === selectedDriverId).length
    }
  )

  const { data: driverAppointments, refetch: refetchDriverAppointments } = useGetDriverAppointments(
    {
      driver_id: selectedDriverId,
      date: startDate,
      filter: startView
    },
    { enabled: !!selectedDriverId && !!allData.filter(item => item?.driver?.id === selectedDriverId).length }
  )

  const driverEvent = driverAvailability?.data?.data || []
  const tripEvent = driverAppointments?.data?.data || []

  const getAvailabilityData = (currentDate, view) => {
    setStartDate(currentDate)
    setStartView(view.toUpperCase())
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box mb={2}>
              <NCText variant={"h4"} style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("appointments.heading")}
              </NCText>
            </Box>
            <Box mb={2} display={"flex"} gridGap={1} alignItems={"center"}>
              <NCButton
                variant="contained"
                color="primary"
                endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
                onClick={() => handleAppointClick()}
                style={{ width: "auto" }}
              >
                {t("appointments.add_availability")}
              </NCButton>
              <NotificationAlert />
            </Box>

          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#F3F4F6",
            padding: "10px",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "10px"
          }}
        >
          <Grid item md="8" style={{ marginTop: "5px" }} xs={6}>
            <NCText>
            {t("appointments.message")}
            </NCText>
          </Grid>

          <Grid item md="5" xs={6} style={{ marginLeft: "10px" }}>
            <NCAutocomplete
              placeholder= {t("appointments.select_driver")}
              value={selectedDriverId}
              options={
                allData.map(item => {
                  return {
                    id: item?.driver?.id,
                    name: item?.driver?.name
                  }
                }) || []
              }
              name={"driver"}
              onChange={driver => setSelectedDriverId(driver)}
              loading={isLoading}
              onSearch={v => setSearch(v)}
            />
          </Grid>
          <AppointmentModal
            modalState={showAppointModal}
            refetchDriverAppointments={refetchDriverAppointments}
            allData={allData}
            isLoading={isLoading}
            setSearch={setSearch}
            handleClose={() => {
              queryClient.refetchQueries("getDriverList")
              setShowAppointmentModal(false)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <NCCalendar
            activities={[...driverEvent, ...tripEvent]}
            getInterval={(currentDate, view) =>
              getAvailabilityData(currentDate, view)
            }
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Appointments
