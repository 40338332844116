import { Box, Grid } from "@material-ui/core"
import React, { useState } from "react"
import NCText from "../../shared/NCText"
import { useTranslation } from "react-i18next"
import NCInput from "../../shared/NCInput"
import { DetailIcon, SearchIcon } from "../../libs/icons"
import { formatDateString } from "../../libs/utils"
import { useGetTransactionDetails } from "../../services/queries"
import { TABLE_CONFIG } from "../../libs/config"
import NCTable from "../../shared/table/NCTable"
import NCLoader from "../../shared/NCLoader"
import { useNavigate, useParams } from "react-router-dom"
// import Filter from "../../shared/table/Filter"
import { IconButton } from "@mui/material"
import TopNavBar from "../../components/TopNavBar"

const TransactionDetails = () => {
  const { t } = useTranslation()
  const nav = useNavigate()
  const { id } = useParams()
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(1)
  const [ordering, setOrdering] = useState("id")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [statusFilter, setStatusFilter] = useState([])

  const { isLoading, data } = useGetTransactionDetails(id)

  const {
    next,
    previous,
    total_pages,
    trips: tripsData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const getIdCell = cell => {
    return (
      <Grid container alignItems="center" spacing={20}>
        <NCText>{cell?.id}</NCText>
      </Grid>
    )
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton onClick={() => nav(`/transactions/${id}/${cell.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }

  const getTransactionAmount = cell => {
    return (
      <Box alignSelf="center">
        <NCText>$ {cell?.payment_detail?.fare}</NCText>
      </Box>
    )
  }

  const columns = [
    {
      Header: t("serial"),
      id: "sr_no",
      accessor: cell => cell?.id,
      disableSortBy: true
    },
    {
      Header: t("trip_id"),
      id: "id",
      accessor: cell => getIdCell(cell),
      disableSortBy: true
    },
    {
      Header: t("amount"),
      id: "payment_detail.fare",
      accessor: cell => getTransactionAmount(cell),
      disableSortBy: true
    },
    {
      Header: t("pickup_datetime"),
      accessor: cell => formatDateString(cell.pickup_time),
      disableSortBy: true
    },
    {
      Header: t("status"),
      id: "status",
      accessor: cell => <NCText>{cell?.report_status}</NCText>,
      defaultCanFilter: true,
      // Filter: SelectColumnFilter,
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  return (
    <Box>
      <Box mb={2}>
        <Grid container justifyContent="space-between" mb={20}>
          <Grid item xs={12} md={4}>
            <NCText variant={"h4"}>{t("transaction_details")}</NCText>
          </Grid>
          <Grid item xs={12} md={2}>
            <NCInput
              value={query}
              onChange={e => {
                setQuery(e.target.value)
              }}
              placeholder={t("search_here")}
              startIcon={SearchIcon}
              className="rounded"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <NCTable
            columns={columns}
            data={tripsData || []}
            page={page}
            onNext={onNext}
            onPrev={onPrev}
            pageCount={total_pages}
            gotoPage={setPage}
            pageSize={pageSize || TABLE_CONFIG.PAGE_SIZE}
            setPageSize={setPageSize}
            sorting={ordering}
            setSorting={setOrdering}
            filter={statusFilter}
            setFilter={setStatusFilter}
          />
          {isLoading && <NCLoader open={true} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default TransactionDetails
