import React, { useCallback, useEffect, useRef } from "react"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsRenderer,
  InfoWindow
} from "@react-google-maps/api"
import { Grid } from "@material-ui/core"
import { useState } from "react"
import { googleAPiKey } from "../../libs/const"
import NCText from "../../shared/NCText"

const Map = ({
  startAddress,
  dropAddress,
  center,
  zoomControl,
  showStartToEnd,
  stops,
  zoom,
  showClientToDriver
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleAPiKey
  })
  const mapRef = useRef()

  const [selectedMarker, setSelectedMarker] = useState(null)

  const [direction, setDirection] = useState(null)
  async function distanceCalculation() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: startAddress,
      destination: dropAddress,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING
    })
    setDirection(results)
  }

  // eslint-disable-next-line no-unused-vars
  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])

  useEffect(() => {
    if (zoom && mapRef.current && center) {
      mapRef.current.setZoom(zoom)
    }
  }, [center, zoom])

  useEffect(() => {
    if (startAddress && dropAddress && isLoaded) {
      distanceCalculation()
    }
  }, [startAddress, dropAddress, isLoaded])

  return (
    <>
      {!isLoaded ? (
        <Grid>Loading.........</Grid>
      ) : (
        <GoogleMap
          zoom={zoom || 15}
          center={center}
          onLoad={onMapLoad}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: zoomControl || false,
            streetViewControl: false,
            mapTypeControl: false,
            // eslint-disable-next-line no-undef
            // mapTypeId: google.maps.MapTypeId.SATELLITE,
            fullscreenControl: false
          }}
        >
          {!showStartToEnd && !showClientToDriver && !stops && (
            <Marker
              position={center}
              icon={{
                // path: google.maps.SymbolPath.CIRCLE,
                url: require("../../assets/icons/driverLocation.svg").default,

                scale: 7
              }}
            />
          )}

          {stops?.length > 0 &&
            stops?.map(stop => {
              const position = {
                lat: stop.latitude,
                lng: stop.longitude
              }
              return (
                <Marker
                  key={stop.seq}
                  position={position}
                  onClick={() => stop.showPopup &&  setSelectedMarker(stop)}
                  icon={{
                    url: require(stop.blueMark
                      ? "../../assets/icons/blue-marker.svg"
                      : stop.geenMark ? "../../assets/icons/start_marker.svg"
                      : "../../assets/icons/end_marker.svg").default,
                    scale: 7
                  }}
                />
              )
            })}

          {showStartToEnd && (
            <>
              <Marker
                position={startAddress}
                icon={{
                  url: require("../../assets/icons/start_marker.svg").default,
                  scale: 7
                }}
              />
              <Marker
                position={dropAddress}
                icon={{
                  url: require("../../assets/icons/end_marker.svg").default,
                  scale: 7
                }}
              />
            </>
          )}

          {showClientToDriver && (
            <>
              <Marker
                position={startAddress}
                icon={{
                  url: require("../../assets/icons/dropLocation.svg").default,
                  scale: 7
                }}
              />
              <Marker
                position={dropAddress}
                icon={{
                  url: require("../../assets/icons/pickupLocation.svg").default,
                  scale: 7
                }}
              />
            </>
          )}

          {direction && (
            <DirectionsRenderer
              directions={direction}
              options={{
                suppressMarkers: true, // Hide default markers,
                preserveViewport: true
              }}
            />
          )}

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker?.latitude,
                lng: selectedMarker?.longitude
              }}
              options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <NCText>{selectedMarker?.info}</NCText>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </>
  )
}

export default Map
