import { useNavigate, useParams } from "react-router-dom"
import {
  useGetDriverAuditLog,
  useGetDriverDetail
} from "../../services/queries"
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import AddProfileIcon from "../../assets/icons/add_profile_image.svg"
import { GENDER, VEHICLE_FACILITIES } from "../../libs/config"
import FormSection from "../../components/FormSection"
import DriverDocuments from "../../components/driver/Document"
import NCButton from "../../shared/NCButton"
import NCLoader from "../../shared/NCLoader"
import AssignDispatcherModal from "../../components/driver/AssignDispatcherModal"
import { useRef, useState } from "react"
import { loggedInUser, saveQR } from "../../libs/utils"
import NCAuditButton from "../../shared/NCAuditButton"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { QRCodeCanvas } from "qrcode.react"
import { useReactToPrint } from "react-to-print"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { useAssignDispatcherToDriver, usePerformBackgroundCheck } from "../../services/mutations"
import { useTranslation } from "react-i18next"
import DriverInspectionList from "../../components/driver/DriverInspectionList"
import NCModal from "../../shared/NCModal"

const DriverDetail = () => {
  const queryClient = useQueryClient()
  const assignDispatcherApi = useAssignDispatcherToDriver()
  const [showBGCModel,setshowBGCModel] = useState(false);
  const { driverId } = useParams()
  const backgroundCheckAPI = usePerformBackgroundCheck();
  const { t } = useTranslation()
  const [assignDispatcherModal, setAssignDispatcherModal] = useState(false)
  const nav = useNavigate()
  const user = loggedInUser()
  const qrRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => qrRef.current
  })
  const mobileTheme = useTheme()
  const isXSScreen = useMediaQuery(mobileTheme.breakpoints.down("xs"))
  const {
    isLoading,
    data: driverResult,
    refetch
  } = useGetDriverDetail(driverId)
  const driverData = driverResult?.data?.data || {}
  const { data } = useGetDriverAuditLog(
    {
      driver_id: driverId
    },
    { enabled: driverId ? true : false }
  )
  const auditData = data?.data?.data?.results || []

  const [hideBackgroundCheckButton, setHideBackgroundCheckButton] = useState(false) ;

  const isBGCDone = driverData?.background_check_status ? true : hideBackgroundCheckButton;

  const renderRowItem = (key, value) => {
    return (
      <Grid item md={4}>
        <NCText>
          <Box fontSize={"12px"} color={"#9CA3AF"}>
            {key}
          </Box>
        </NCText>
        <NCText className="overflow-ellipsis">{value}</NCText>
      </Grid>
    )
  }

  const renderPrintButton = (
    <NCButton
      variant="contained"
      color="primary"
      disableElevation
      onClick={handlePrint}
      style={{ width: "auto", marginRight: "12px" }}
    >
      {t("common.print_qr")}
    </NCButton>
  )

  const isFacilityAvailable = facilityName => {
    if (!driverData?.vehicle?.vehicle_facilities) return false
    const facility = driverData?.vehicle?.vehicle_facilities.find(
      f => f.type === facilityName
    )
    return facility ? true : false
  }

  const getFacilityQuantityFromArray = facilityName => {
    if (!driverData?.vehicle?.vehicle_facilities) return "--"
    const facility = driverData?.vehicle?.vehicle_facilities.find(
      f => f.type === facilityName
    )
    return facility ? facility.quantity : "--"
  }

  const handleApproveOnDemandDriver = () => {
    assignDispatcherApi.mutate(
      { id: driverId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getDriverDetail"])
          toast.success(t("drivers.approved"))
          refetch?.()
        }
      }
    )
  }

  const handleBackgroundCheck = () => {
    setHideBackgroundCheckButton(true)
    setshowBGCModel(false);
    backgroundCheckAPI.mutate(driverId,{
      onSuccess: () => {
        toast.success(t("drivers.background_check_complete_message"));
        setTimeout(()=>{
          refetch();
        },1000)
      },
      onError: () => {
        setHideBackgroundCheckButton(false);
      }
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item md={6}>
            <Box mb={2} display={"flex"}>
              <NCText variant={"h4"} style={{ marginRight: "10px" }}>
                {t("drivers.driver_detail")}
              </NCText>
            </Box>
          </Grid>
          <Grid item container md={6} spacing={2} justifyContent="flex-end">
          {!isBGCDone && <Grid item>
            <Box>
              <NCButton
                  variant="contained"
                  color="primary"
                  onClick={()=> setshowBGCModel(true)}
                >
                  {t("drivers.run_background_check")}
                </NCButton>
              </Box>
            </Grid>}
            <Grid item>
              <Box>
                <NCButton
                  variant="contained"
                  color="primary"
                  onClick={() => nav(`/drivers/${driverId}/edit`)}
                >
                  {t("drivers.edit_driver")}
                </NCButton>
              </Box>
            </Grid>
            <Grid item>
              <Box display={"flex"}>
                {user.user_type === 1 && driverId ? (
                  <Box>
                    <NCAuditButton data={auditData} isLoading={isLoading} />
                  </Box>
                ) : null}
                <NotificationAlert />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={2}>
            <Box display={"flex"} justifyContent={"center"}>
              <img
                src={driverData?.avatar || AddProfileIcon}
                width={"180px"}
                height="180px"
                style={{ borderRadius: driverData?.avatar ? "100%" : "0" }}
                htmlFor="contained-button-file"
              />
            </Box>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  marginTop={"20px"}
                  marginBottom={"20px"}
                  ref={qrRef}
                >
                  <QRCodeCanvas
                    id="qrcode-canvas"
                    style={{ height: "auto", width: "120px" }}
                    value={`${window.location.origin}/verify-driver/?uuid=${driverData?.uuid}`}
                    level="H"
                  />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  marginTop={"20px"}
                  marginBottom={"20px"}
                >
                  <NCButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={saveQR.bind(this, qrRef)}
                    style={{ width: "auto", marginRight: "12px" }}
                  >
                    {t("common.save_qr")}
                  </NCButton>
                  {isXSScreen && renderPrintButton}
                </Box>
                {!isXSScreen && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    marginTop={"10px"}
                    marginBottom={"10px"}
                  >
                    {renderPrintButton}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={10}>
            {[1, 2].includes(driverData?.driver_profile_status) ? (
              <Grid container className="success-container">
                <Grid item xs={12} md={10}>
                  <NCText>
                    {t("drivers.approved_message")}
                  </NCText>
                </Grid>
                <Grid item xs={6} md={2}>
                  <NCButton
                    variant={"outlined"}
                    color={"primary"}
                    onClick={() =>
                      driverData?.driver_type === "Driver"
                        ? setAssignDispatcherModal(true)
                        : handleApproveOnDemandDriver()
                    }
                  >
                    {t("common.approve")}
                  </NCButton>
                </Grid>
              </Grid>
            ) : null}
            <FormSection title={t("drivers.driver_detail")}>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("common.first_name"), driverData?.first_name)}
                  {renderRowItem(t("common.middle_name"), driverData?.middle_name)}
                  {renderRowItem(t("common.last_name"), driverData?.last_name)}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("common.email_address"), driverData?.email)}
                  {renderRowItem(t("common.phone_number"), driverData?.phone)}
                  {renderRowItem(t("common.gender"), GENDER[driverData?.gender])}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("common.address"), driverData?.address)}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("job_country"),
                    driverData?.job_location_country
                  )}
                  {renderRowItem(
                    t("job_state"),
                    driverData?.job_location_state
                  )}
                  {renderRowItem(t("job_city"), driverData?.job_location_city)}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("list.driver_type"), driverData?.driver_type)}
                  {
                    isBGCDone ?
                      <>
                        {renderRowItem(t("drivers.background_check_status"), driverData?.background_check_status?.status)}
                        {renderRowItem(t("drivers.background_check_result"), driverData?.background_check_status?.result)}
                      </> : null
                  }
                </Grid>
              </Box>
            </FormSection>
            <FormSection title={t("vehicles.vehicle_details")}>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("list.mark"), driverData?.vehicle?.mark)}
                  {renderRowItem(t("list.model"), driverData?.vehicle?.model)}
                  {renderRowItem(t("list.vin"), driverData?.vehicle?.vin_no)}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("list.license_plate"),
                    driverData?.vehicle?.licence_plate
                  )}
                </Grid>
              </Box>
            </FormSection>
            <FormSection title={t("vehicles.vehicle_offering")}>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.buckle_guard"),
                    isFacilityAvailable(VEHICLE_FACILITIES.BUCKLE_GUARD)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(
                      VEHICLE_FACILITIES.BUCKLE_GUARD
                    )
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.booster"),
                    isFacilityAvailable(VEHICLE_FACILITIES.BOOSTER)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(VEHICLE_FACILITIES.BOOSTER)
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.safety_vest"),
                    isFacilityAvailable(VEHICLE_FACILITIES.SAFETY_VEST)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(VEHICLE_FACILITIES.SAFETY_VEST)
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.car_seat"),
                    isFacilityAvailable(VEHICLE_FACILITIES.CAR_SEAT)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(VEHICLE_FACILITIES.CAR_SEAT)
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.wheel_chair"),
                    isFacilityAvailable(VEHICLE_FACILITIES.WHEELCHAIR)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.service_dog"),
                    isFacilityAvailable(VEHICLE_FACILITIES.SERVICE_DOG)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.travel_alone"),
                    isFacilityAvailable(VEHICLE_FACILITIES.TRAVEL_ALONE)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.vehicle_capacity"),
                    driverData?.vehicle?.capacity
                  )}
                </Grid>
              </Box>
            </FormSection>
            <FormSection title={t("drivers.all_ratings")}>
              <NCText>{t("drivers.in_development")}</NCText>
            </FormSection>
            <FormSection title={t("drivers.uploaded_documents")}>
              {driverData?.document ? (
                <DriverDocuments data={driverData?.document || []} />
              ) : null}
            </FormSection>
            <FormSection title={"Inspection"}>
                <DriverInspectionList driverId={driverId} />
            </FormSection>
            <FormSection title={t("drivers.scores")}>
              <NCText>{t("drivers.in_development")}</NCText>
            </FormSection>
          </Grid>
        </Grid>
      </Grid>
      <AssignDispatcherModal
        driverId={driverId}
        refetch={refetch}
        modalState={assignDispatcherModal}
        handleClose={() => setAssignDispatcherModal(false)}
      />
      <NCModal
        open={showBGCModel}
        title={t("drivers.background_check")}
        text={t("drivers.bgc_message")}
        onClose={() => setshowBGCModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => handleBackgroundCheck()}
            style={{ width: "auto" }}
          >
            {t("common.yes")}
          </NCButton>
        }
      />
      <NCLoader open={isLoading} />
    </Grid>
  )
}

export default DriverDetail
