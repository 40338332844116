/* eslint-disable no-console */
import React from "react";
import ReactPlayer from "react-player";

const AudioPlayer = ({ audioUrl }) => {
  return (
    <div>
      <ReactPlayer
        url={audioUrl}
        controls={true}
        width="100%"
        height="50px"
        playing={true}
        volume={0.8}
        onProgress={(state) => console.log("progress", state)}
        onDuration={(duration) => console.log("duration", duration)}
        onError={(error) => console.log("error", error)}
      />
    </div>
  );
};

export default AudioPlayer;
