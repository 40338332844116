import PubNub from "pubnub";
import React  from "react";
import { PubNubProvider } from "pubnub-react";
import { pubnubPublishKey, pubnubSubscribeKey } from "./libs/const";

const Pubnub = ({ children, uuid }) => {

    const pubnub = new PubNub({
        publishKey: pubnubPublishKey,
        subscribeKey: pubnubSubscribeKey,
        uuid: uuid,
    });

    return <PubNubProvider client={pubnub}>
        {children}
    </PubNubProvider>
}

export default Pubnub;