import {
  Box,
  FormControlLabel,
  Grid,
  RadioGroup,
  makeStyles
} from "@material-ui/core"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { FieldArray, Form, FormikProvider, useFormik } from "formik"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import * as Yup from "yup"
import NCButton from "../../shared/NCButton"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { AddIcon } from "../../libs/icons"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useRef } from "react"
import { useCreateTraining, useUpdateTraining } from "../../services/mutations"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useGetTrainingById } from "../../services/queries"
import NCRadio from "../../shared/NCRadio"
import { LESSON_TYPE, PDF, TEXTDATA, VIDEOAUDIO } from "./training-const"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  form: {
    "& .MuiCheckbox-root": {
      paddingRight: "3px"
    }
  },
  addLessonHeading: {
    backgroundColor: "rgba(11, 155, 182, 0.21)",
    padding: "10px 20px",
    marginBottom: "10px",
    borderRadius: "8px"
  },
  pointer: {
    cursor: "pointer"
  },
  markCorrect: {
    fontSize: "18px",
    color: "#1EAAC4",
    display: "flex",
    alignItems: "center",
    height: "100%",
    cursor: "pointer",
    textDecoration: "underline"
  },
  correct: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    height: "100%",
    color: "#1DB954"
  },
  error: {
    color: "#f44336"
  },
  lessonType: {
    margin: "20px 0px"
  }
}))

const CreateTraining = () => {
  const cls = useStyles()

  const nav = useNavigate()

  const { id } = useParams()

  const {t} = useTranslation();

  const queryClient = useQueryClient()

  const uploadPDFRef = useRef(null)

  const createTrainingMutation = useCreateTraining()
  const updateTrainingMutation = useUpdateTraining(id)

  const { data: trainingData } = id ? useGetTrainingById(id) : {}


  const getPDFName = link => {
    return link?.split("/")?.pop() || ""
  }

  useEffect(() => {
    if (trainingData?.data?.data?.id) {
      const tData = trainingData?.data?.data
      const lessons = tData?.lessons?.map(lesson => {
        return {
          questions: lesson?.questions,
          type: lesson?.type,
          identifier: lesson?.id,
          video_url:
            lesson?.type === VIDEOAUDIO
              ? lesson?.vimeo_url
              : lesson?.type === TEXTDATA
              ? lesson?.text
              : lesson?.file
        }
      })
      formik.setFieldValue("name", tData?.name)
      formik.setFieldValue("description", tData?.description)
      formik.setFieldValue("topic", tData?.topic)
      formik.setFieldValue("lessons", lessons)
    }
  }, [trainingData?.data?.data?.id])

  const formik = useFormik({
    initialValues: {
      name: "",
      topic: "",
      description: "",
      lessons: [
        {
          video_url: "",
          type: VIDEOAUDIO,
          questions: [
            {
              question: "",
              answers: [
                {
                  answer: "",
                  is_correct: false
                },
                {
                  answer: "",
                  is_correct: false
                },
                {
                  answer: "",
                  is_correct: false
                }
              ]
            }
          ]
        }
      ],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("training.error.name")),
      topic: Yup.string().required(t("training.error.topic")),
      description: Yup.string().required(t("training.error.description")),
      lessons: Yup.array().of(
        Yup.object().shape({
          video_url: Yup.string().required(t("training.error.video_url")),
          type: Yup.string().required(t("training.error.type")),
          questions: Yup.array().of(
            Yup.object().shape({
              question: Yup.string().required(t("training.error.question")),
              answers: Yup.array()
                .test(
                  "at-least-one",
                  t("training.error.answer_mark_correct"),
                  values => {
                    return values.some(item => item.is_correct)
                  }
                )
                .of(
                  Yup.object().shape({
                    answer: Yup.string().required(t("training.error.answer")),
                    is_correct: Yup.bool()
                  })
                )
            })
          )
        })
      )
    }),
    onSubmit: (values, { resetForm }) => {
      const updatedValues = {
        ...values,
        lessons: values.lessons?.map(lesson => {
          return {
            type: lesson.type,
            identifier: lesson?.identifier,
            questions: lesson.questions,
            vimeo_url: lesson.type === VIDEOAUDIO  ? lesson.video_url : null,
            file: lesson.type === PDF && typeof(lesson.video_url) !== "string" ? lesson.video_url : null,
            text: lesson.type === TEXTDATA ? lesson.video_url : null
          }
        })
      }

      if (!id) {
        createTrainingMutation.mutate(updatedValues, {
          onSuccess: async () => {
            toast.success(t("training.success"))
            resetForm({ values: "" })
            queryClient.invalidateQueries(["getAllTrainings"])
            nav("/training")
          }
        })
      } else {
        updateTrainingMutation.mutate(updatedValues, {
          onSuccess: async () => {
            toast.success(t("training.update"))
            resetForm({ values: "" })
            queryClient.invalidateQueries(["getAllTrainings"])
            nav("/training")
          }
        })
      }
    }
  })

  const addLesson = lessonsHelper => {
    const lessonObj = {
      video_url: "",
      type: VIDEOAUDIO,
      questions: [
        {
          text: "",
          answers: [
            {
              text: "",
              checked: false
            },
            {
              text: "",
              checked: false
            },
            {
              text: "",
              checked: false
            }
          ]
        }
      ]
    }

    lessonsHelper.push(lessonObj)
  }

  const addQuestion = questionsHelper => {
    const questionObj = {
      text: "",
      answers: [
        {
          text: "",
          checked: false
        },
        {
          text: "",
          checked: false
        },
        {
          text: "",
          checked: false
        }
      ]
    }

    questionsHelper.push(questionObj)
  }

  const addAnswer = answersHelper => {
    const answerObj = {
      text: "",
      checked: false
    }

    answersHelper.push(answerObj)
  }

  const onCheckAnswer = (index, qIndex, aIndex) => {
    const answers = formik.values.lessons[index].questions[qIndex].answers
    const updatedAnswer = answers?.map((data, index) => {
      if (index === aIndex) {
        return {
          ...data,
          is_correct: true
        }
      }
      return {
        ...data,
        is_correct: false
      }
    })
    formik.setFieldValue(
      `lessons.${index}.questions.${qIndex}.answers`,
      updatedAnswer
    )
  }

  const getLessonTypeLabel = index => {
    const lessonType = formik.values?.lessons?.[index]?.type
    switch (lessonType) {
      case VIDEOAUDIO:
        return "Video/Audio Url"
      case TEXTDATA:
        return "Text"
    }
    return ""
  }

  const handleRadioChange = (event, index) => {
    const value = parseInt(event.target.value)
    formik.setFieldValue(`lessons.${index}.video_url`, "")
    formik.setFieldValue(`lessons.${index}.type`, value)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>{id ? t("training.edit") : t("training.add")} {t("training.heading")}</NCText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <FormSection
                  title={
                    <span style={{ fontWeight: "500" }}>{t("training.training_details")}</span>
                  }
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormField
                        label={t("training.name_of_training")}
                        name={"name"}
                        required={true}
                        error={formik?.errors?.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormField
                        label={t("training.topic")}
                        name={"topic"}
                        required={true}
                        error={formik?.errors?.topic}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        label={t("training.full_description")}
                        name={"description"}
                        required={true}
                        error={formik?.errors?.description}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name="lessons"
                  render={lessonsHelper => {
                    return (
                      <>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          className={cls.addLessonHeading}
                        >
                          <NCText>{t("training.lessons")}</NCText>
                          <div>
                            <NCButton
                              variant="contained"
                              color="primary"
                              endIcon={
                                <NCSvgIcon component={AddIcon} inheritViewBox />
                              }
                              onClick={addLesson.bind(this, lessonsHelper)}
                            >
                              {t("training.add_lesson")}
                            </NCButton>
                          </div>
                        </Box>

                        {formik?.values?.lessons?.map((lesson, index) => {
                          return (
                            <FieldArray
                              name={`lessons.${index}.questions`}
                              render={questionsHelper => {
                                return (
                                  <Grid container>
                                    {/* <Grid item xs={12} sm={4} md={2}>
                                      <Box marginRight={"10px"}>
                                        <img
                                          src={AddProfileIcon}
                                          width={"100%"}
                                          htmlFor="contained-button-file"
                                        />
                                      </Box>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                      <Grid
                                        container
                                        className={cls.lessonType}
                                      >
                                        <Grid item xs={12}>
                                          <NCText>{t("training.lesson_type")}</NCText>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: "15px" }}
                                        >
                                          <RadioGroup
                                            defaultValue="phone"
                                            className={cls.radioButton}
                                            name="radio-buttons-group"
                                            value={
                                              formik.values.lessons[index].type
                                            }
                                            onChange={event => {
                                              handleRadioChange(event, index)
                                            }}
                                          >
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              gridGap={"50px"}
                                            >
                                              {LESSON_TYPE?.map(type => {
                                                return (
                                                  <span key={type.name}>
                                                    <FormControlLabel
                                                      value={type.value}
                                                      control={
                                                        <NCRadio
                                                          color={"primary"}
                                                        />
                                                      }
                                                      label={t(type.name)}
                                                    />
                                                  </span>
                                                )
                                              })}
                                            </Box>
                                          </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Box mt={2}>
                                            {formik.values.lessons?.[index]
                                              ?.type !== PDF && (
                                              <FormField
                                                label={getLessonTypeLabel(
                                                  index
                                                )}
                                                name={`lessons.${index}.video_url`}
                                                required={true}
                                                error={
                                                  formik?.errors?.lessons?.[
                                                    index
                                                  ]?.video_url
                                                }
                                              />
                                            )}
                                            {formik.values.lessons?.[index]
                                              ?.type === PDF && (
                                              <FormSection
                                                title={
                                                  <Box
                                                    display={"flex"}
                                                    justifyContent={
                                                      "space-between"
                                                    }
                                                    alignItems={"center"}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500"
                                                      }}
                                                    >
                                                      {t("training.upload_pdf")}
                                                    </span>
                                                    <div>
                                                      <NCButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                          uploadPDFRef.current.click()
                                                        }}
                                                      >
                                                        {t("training.upload_file")}
                                                      </NCButton>
                                                      <input
                                                        style={{
                                                          display: "none"
                                                        }}
                                                        ref={uploadPDFRef}
                                                        id="upload-pdf"
                                                        name="upload-pdf"
                                                        type="file"
                                                        accept="application/pdf"
                                                        onChange={e => {
                                                          // eslint-disable-next-line no-console
                                                          console.log(e)
                                                          if (
                                                            e?.target
                                                              ?.files?.[0]
                                                          ) {
                                                            formik.setFieldValue(
                                                              `lessons.${index}.video_url`,
                                                              e.target.files[0]
                                                            )
                                                          }
                                                        }}
                                                      />
                                                    </div>
                                                  </Box>
                                                }
                                              >
                                                <FormSection
                                                  title={"File Name"}
                                                  subSection={true}
                                                >
                                                  <Box
                                                    display={"flex"}
                                                    justifyContent={
                                                      "space-between"
                                                    }
                                                    p={1}
                                                  >
                                                    <NCText>
                                                      <Box fontWeight={"700"}>
                                                        {formik.values
                                                          .lessons?.[index]
                                                          .video_url?.name || (
                                                          <span>
                                                            {getPDFName(
                                                              formik.values
                                                                .lessons?.[
                                                                index
                                                              ].video_url
                                                            )}
                                                          </span>
                                                        )}
                                                      </Box>
                                                    </NCText>

                                                    {formik.values.lessons?.[
                                                      index
                                                    ]?.video_url && (
                                                      <NCText>
                                                        <Box
                                                          fontWeight={"700"}
                                                          color={"#E22134"}
                                                          className={
                                                            cls.pointer
                                                          }
                                                          onClick={() => {
                                                            formik.setFieldValue(
                                                              `lessons.${index}.video_url`,
                                                              null
                                                            )
                                                          }}
                                                        >
                                                          {t("training.delete_upload")}
                                                        </Box>
                                                      </NCText>
                                                    )}
                                                  </Box>
                                                  <Box className={cls.error}>
                                                    {formik?.errors?.lessons?.[
                                                      index
                                                    ]?.video_url && (
                                                      <span>
                                                        {t("training.pdf_required")}
                                                      </span>
                                                    )}
                                                  </Box>
                                                </FormSection>
                                              </FormSection>
                                            )}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormSection
                                        title={
                                          <Box
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                          >
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500"
                                              }}
                                            >
                                              {t("training.lesson_no")}{index + 1}
                                            </span>
                                            <div>
                                              <NCButton
                                                variant="contained"
                                                color="primary"
                                                endIcon={
                                                  <NCSvgIcon
                                                    component={AddIcon}
                                                    inheritViewBox
                                                  />
                                                }
                                                onClick={addQuestion.bind(
                                                  this,
                                                  questionsHelper
                                                )}
                                              >
                                                {t("training.add_question")}
                                              </NCButton>
                                            </div>
                                          </Box>
                                        }
                                      >
                                        <Grid container spacing={2}>
                                          {lesson.questions?.map(
                                            (question, qIndex) => {
                                              return (
                                                <FieldArray
                                                  name={`lessons.${index}.questions.${qIndex}.answers`}
                                                  render={answersHelper => {
                                                    return (
                                                      <>
                                                        <Grid item xs={12}>
                                                          <FormField
                                                            label={`${t("training.question_no")} ${
                                                              qIndex + 1
                                                            }`}
                                                            name={`lessons.${index}.questions.${qIndex}.question`}
                                                            required={true}
                                                            error={
                                                              formik?.errors
                                                                ?.lessons?.[
                                                                index
                                                              ]?.questions?.[
                                                                qIndex
                                                              ]?.question
                                                            }
                                                          />
                                                        </Grid>
                                                        {question?.answers?.map(
                                                          (answer, aIndex) => {
                                                            return (
                                                              <>
                                                                <Grid
                                                                  item
                                                                  md={9}
                                                                  xs={12}
                                                                >
                                                                  <FormField
                                                                    label={`${t("training.answer_no")} ${
                                                                      aIndex + 1
                                                                    }`}
                                                                    name={`lessons.${index}.questions.${qIndex}.answers.${aIndex}.answer`}
                                                                    required={
                                                                      true
                                                                    }
                                                                    error={
                                                                      formik
                                                                        ?.errors
                                                                        ?.lessons?.[
                                                                        index
                                                                      ]
                                                                        ?.questions?.[
                                                                        qIndex
                                                                      ]
                                                                        ?.answers?.[
                                                                        aIndex
                                                                      ]?.answer
                                                                    }
                                                                  />
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  md={3}
                                                                  xs={12}
                                                                >
                                                                  {!answer.is_correct ? (
                                                                    <div
                                                                      className={
                                                                        cls.markCorrect
                                                                      }
                                                                      onClick={onCheckAnswer.bind(
                                                                        this,
                                                                        index,
                                                                        qIndex,
                                                                        aIndex
                                                                      )}
                                                                    >
                                                                      <NCText>
                                                                        {t("training.mark_correct")}
                                                                      </NCText>
                                                                    </div>
                                                                  ) : (
                                                                    <div
                                                                      className={
                                                                        cls.correct
                                                                      }
                                                                    >
                                                                      <NCText>
                                                                        {t("training.correct_answer")}
                                                                      </NCText>
                                                                    </div>
                                                                  )}
                                                                </Grid>
                                                              </>
                                                            )
                                                          }
                                                        )}
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          className={cls.error}
                                                        >
                                                          <NCText>
                                                            {typeof formik
                                                              ?.errors
                                                              ?.lessons?.[index]
                                                              ?.questions?.[
                                                              qIndex
                                                            ]?.answers ===
                                                            "string"
                                                              ? formik?.errors
                                                                  ?.lessons?.[
                                                                  index
                                                                ]?.questions?.[
                                                                  qIndex
                                                                ]?.answers
                                                              : null}
                                                          </NCText>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                          <Box
                                                            display={"flex"}
                                                            justifyContent={
                                                              "flex-end"
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                display:
                                                                  "inline-block"
                                                              }}
                                                            >
                                                              <NCButton
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={addAnswer.bind(
                                                                  this,
                                                                  answersHelper
                                                                )}
                                                                endIcon={
                                                                  <NCSvgIcon
                                                                    component={
                                                                      AddIcon
                                                                    }
                                                                    inheritViewBox
                                                                  />
                                                                }
                                                              >
                                                               {t("training.add_answer")}
                                                              </NCButton>
                                                            </div>
                                                          </Box>
                                                        </Grid>
                                                      </>
                                                    )
                                                  }}
                                                />
                                              )
                                            }
                                          )}
                                        </Grid>
                                      </FormSection>
                                    </Grid>
                                  </Grid>
                                )
                              }}
                            />
                          )
                        })}
                      </>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Grid item>
                      <NCButton
                        onClick={() => nav("/training")}
                        variant="outlined"
                        color="primary"
                      >
                        {t("common.cancel")}
                      </NCButton>
                    </Grid>
                    <Grid item>
                      <NCButton
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {id ? t("common.update") : t("common.create")}
                      </NCButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>
    </Grid>
  )
}

export default CreateTraining
