import {
  Modal,
  makeStyles,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  Grid
} from "@material-ui/core"
import { CloseIcon } from "../libs/icons"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    [theme.breakpoints.down("sm")]:{
      maxWidth:"80%",
      width:"100%",
      minWidth: "auto",
    },
    // width: 500,
    backgroundColor: "white",
    boxShadow: 24,
    padding: "10px 20px 20px 20px",
    borderRadius: "5px",
    outline: "none",
    maxHeight:"80%",
    overflow:"auto",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#2E3B36",
    fontSize: "20px",
    paddingBottom: "5px"
  },
  text: {
    fontSize: "14px",
    paddingTop: "15px"
    // paddingBottom: "35px"
  },
  closeIcon: {
    padding: "3px"
  },
  btn: {
    justifyContent: "right",
    paddingTop: "10px"
  },
  cancelButton: {
    borderRadius: "30px",
    fontSize: "14px",
    marginRight: "10px",
    lineHeight: "16px",
    color: "#005B90",
    borderColor: "#005B90"
  },
  discardButton: {
    borderRadius: "30px",
    fontSize: "14px",
    lineHeight: "16px",
    color: "white",
    backgroundColor: "#E22134"
  }
}))
const NCModal = props => {
  const cls = useStyles()

  const {t} = useTranslation();

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box className={`${cls.root} ${props.className}`}>
        <Typography className={cls.title}>
          {props.title}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Typography>
        <Divider />
        <Typography className={cls.text}>{props.text}</Typography>
        <Grid container className={cls.btn}>
          {props.cancelButton ? (
            props.cancelButton
          ) : (
            <Button
              className={cls.cancelButton}
              variant="outlined"
              onClick={props.onClose}
            >
              {props.cancelButtonLabel ? props.cancelButtonLabel : t("common.cancel")}
            </Button>
          )}
          {props.submitButton ? (
            props.submitButton
          ) : (
            <Button
              className={cls.discardButton}
              variant="contained"
              disableElevation
              onClick={props.handleDiscard}
            >
              {t("common.discard")}
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  )
}

export default NCModal
