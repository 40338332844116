import { useState, useEffect } from "react"
import AuthComponent from "../components/AuthComponent"
import { Grid, makeStyles, Paper, Box } from "@material-ui/core"
import NCTitle from "../shared/NCTitle"
import NCInput from "../shared/NCInput"
import NCButton from "../shared/NCButton"
import NCText from "../shared/NCText"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useCodeVerify } from "../services/mutations"
import successLogo from "../assets/images/successLogo.svg"
import { useNavigate } from "react-router"
import { useAuthentication } from "../services/mutations"
import FormField from "../components/FormField"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px",
    minWidth: "350px"
  },
  title: {
    marginTop: "12px",
    marginBottom: "5px"
  },
  text: {
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "20px"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px"
  },
  waitText: {
    fontSize: "12px",
    paddingTop: "10px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& .MuiFormLabel-root": {
      marginBottom: "5px"
    },
    "& .MuiButtonBase-root": {
      marginTop: "25px"
    }
  }
}))
const VerifyAuth = props => {
  const cls = useStyles()
  const [isVerifyAuth, setISVerifyAuth] = useState(true)
  const verifyAuthcode = useCodeVerify()
  const [counter, setCounter] = useState(60)
  const emailAuth = useAuthentication()
  const nav = useNavigate()
  const formik = useFormik({
    initialValues: {
      verificationCode: ""
    },
    validationSchema: Yup.object().shape({
      verificationCode: Yup.string().required("Verification code is required")
    }),
    onSubmit(values) {
      verifyAuthcode.mutate(
        { otp: values.verificationCode, id: props.verificationId },
        {
          onSuccess: () => {
            toast.success("Verification done")
            setISVerifyAuth(false)
          },
          onError: () => {
            //console.log("error block")
          }
        }
      )
    }
  })
  const handleClick = () => {
    emailAuth.mutate(
      { two_factor_config: { method: "EMAIL", email: props.email } },
      {
        onSuccess: () => {
          toast.success("Verification code is sent to the email")
          setCounter(60)
        },
        onError: () => {
          //console.log("error block")
        }
      }
    )
  }
  useEffect(() => {
    let interval = setInterval(
      () =>
        setCounter(prevCounter =>
          prevCounter > 0 ? --prevCounter : prevCounter
        ),
      1000
    )
    return () => clearInterval(interval)
  }, [])
  const verifyAuthContent = (
    <Grid item xs={8}>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>Verify that it's you</NCTitle>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <Box mt={"15px"}>
              <FormField
                as={NCInput}
                className={cls.input}
                required={true}
                name="verificationCode"
                placeholder="Enter verification code"
                error={formik?.errors?.verificationCode}
              />
            </Box>
            <NCButton variant="contained" color="primary" type="submit">
              Verify
            </NCButton>
            <NCText className={cls.waitText}>
              Wait for {counter} seconds before requesting a &nbsp;
              <span
                style={
                  counter > 0
                    ? { color: "#9CA3AF", fontWeight: "bold" }
                    : { color: "#1EAAC4", fontWeight: "bold" }
                }
                className={counter === 0 && "cursor-pointer"}
                onClick={handleClick}
              >
                New Code
              </span>
            </NCText>
          </Form>
        </FormikProvider>
      </Paper>
    </Grid>
  )

  const verificationSuccessContent = (
    <Grid item xs={8}>
      <Paper className={cls.paper}>
        <img src={successLogo} style={{ width: "25px" }} />
        <NCTitle className={cls.title}>
          2 Factor Authentication is Setup
        </NCTitle>
        <NCText className={cls.text}>
          You can disable it from the settings.
        </NCText>
        <NCButton
          variant="contained"
          color="primary"
          onClick={() => nav("/logout")}
        >
          Continue{" "}
        </NCButton>
      </Paper>
    </Grid>
  )
  return (
    <AuthComponent>
      {isVerifyAuth ? verifyAuthContent : verificationSuccessContent}
    </AuthComponent>
  )
}

export default VerifyAuth
