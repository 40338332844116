export const NOAH_CARES = "noahCares";

export const STORAGE = {
  getItem: key => JSON.parse(localStorage.getItem(key)) || {},
  setItem: (key, data) => localStorage.setItem(key, JSON.stringify(data)),
  getLocale: () => localStorage.getItem("locale"),
  getToken:()=> JSON.parse(localStorage.getItem(NOAH_CARES))?.token,
  setLocale: (lang) => localStorage.setItem("locale", lang),
  clear: () => localStorage.removeItem(NOAH_CARES),
  getPubnubUUID: () => JSON.parse(localStorage.getItem(NOAH_CARES))?.user?.pubnub_profile?.uuid || null,
  getUserDetails: () => JSON.parse(localStorage.getItem(NOAH_CARES))?.user,
  getAccessList: () => STORAGE.getUserDetails()?.features?.map(data => data?.feature) || [],
  isDispatcher: () => STORAGE.getUserDetails().user_type === 4
}