export const fetchChannels = (pubnub, userId) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const channels = {}
    const metadata = await pubnub.objects.getAllChannelMetadata({
      filter: `id LIKE "*${userId}*"`,
      include: { customFields: true }
    })
    metadata.data.forEach(({ id, name, updated, custom }) => {
      channels[id] = {
        id,
        name,
        updated,
        custom: { ...channels[id]?.custom, ...custom }
      }
    })
    resolve(channels)
  })
}

export const createDirectChannel = (pubnub, userId, chatWithId, customData) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const channel = createChannelId(userId, chatWithId)
    await pubnub.objects.setChannelMetadata({
      channel,
      data: customData
    })
    await pubnub.objects.setChannelMembers({
      channel,
      uuids: [{ id: userId }, { id: `${chatWithId}` }]
    })
    await pubnub.channelGroups.addChannels({
      channels: [channel],
      channelGroup: userId
    })
    resolve({ channel: channel })
  })
}

export const createChannelId = (userId, chatWithId) => {
  return [userId, chatWithId].sort().join("-")
}

export const publishMessage = (pubnub, message, channel, metaInfo) => {
  return new Promise(resolve => {
    pubnub.publish(
      {
        message: message,
        channel: channel,
        meta: metaInfo
      },
      (status, response) => {
        return resolve(response)
      }
    )
  })
}

export const publishFile = (pubnub, file, channel, message, metaInfo) => {
  return new Promise(() => {
    pubnub.sendFile({
      file: file,
      channel: channel,
      message: message,
      meta: metaInfo
    })
  })
}

export const downloadUploadedFile = (pubnub, fileParams) => {
  return pubnub.downloadFile(fileParams)
}

export const timeSince = date => {
  if (!date) {
    return null
  }
  const seconds = Math.floor((new Date() - date) / 1000)
  let interval = seconds / 31536000
  if (interval > 1) {
    return Math.floor(interval) + "y"
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + "m"
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + "d"
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + "h"
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + "m"
  }
  return Math.floor(seconds) + "s"
}

export const showTime = date => {
  if (!date) {
    return null
  }
  const d = new Date(date)
  const currentDate = new Date().setHours(0, 0, 0, 0)
  const providedDate = new Date(d).setHours(0, 0, 0, 0)
  const seconds = Math.floor((currentDate - providedDate) / 1000)
  const month = d.getMonth() + 1
  const mdate = d.getDate()

  const monthDate =
    (month?.toString()?.length > 1 ? month : "0" + month) +
    "-" +
    (mdate?.toString()?.length > 1 ? mdate : "0" + mdate)

  const time =
    (d.getHours()?.toString()?.length > 1 ? d.getHours() : "0" + d.getHours()) +
    "-" +
    (d.getMinutes()?.toString()?.length > 1
      ? d.getMinutes()
      : "0" + d.getMinutes())

  let interval = seconds / (60 * 60 * 24)
  if (interval < 1) {
    return "Today " + time
  } else if (interval >= 1 && interval <= 2) {
    return "Yesterday " + time
  }
  return monthDate + " " + time
}

export const getMembership = pubnub => {
  return pubnub.objects.getMemberships({
    include: {
      customFields: true
    }
  })
}

export const setMembership = (pubnub, channel) => {
  return pubnub.objects.setMemberships({
    channels: [
      {
        id: channel,
        custom: {
          lastReadTimetoken: Date.now() * 10000
        }
      }
    ]
  })
}

export const getUnreadMessageCounts = (pubnub, channels, timeTokens) => {
  if (channels && channels?.length === timeTokens?.length) {
    return new Promise(resolve => {
      pubnub.messageCounts(
        {
          channels: channels,
          channelTimetokens: timeTokens || []
        },
        (status, results) => {
          resolve(results?.channels || {})
        }
      )
    })
  }
  return {}
}
