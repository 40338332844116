import { useState } from "react"
import { Box, Grid, Icon } from "@material-ui/core"
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
// import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import NCText from "../../shared/NCText"
import NCButton from "../../shared/NCButton"
import NCModal from "../../shared/NCModal"
import { DocumentIcon, FeedbackUserIcon } from "../../libs/icons"
import { FEEDBACK_TYPE, USER_TYPE } from "../../libs/config"
import { formatDate, getDocNameFromPath } from "../../libs/utils"
import FeedbackPopup from "./FeedbackPopup"
import { useFeedbackAdminReply, usePayrollReply } from "../../services/mutations"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { ReplyIcon } from "../../libs/icons"
import withContext from "../DrawerConsumer"
import FormField from "../FormField"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

const FeedbackCard = props => {
  const { t } = useTranslation();
  const { detail, index, selectedTab } = props
  const queryClient = useQueryClient()
  const [showFeedbackModel, setShowFeedbackModel] = useState(false)
  const feedbackAdminReplyApi = useFeedbackAdminReply()
  const payrollReplyApi = usePayrollReply()
  const formik = useFormik({
    initialValues: {
      reply: detail.reply || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      reply: detail.reply ? "" : Yup.string().required(t("feedback.reply_required"))
    }),
    onSubmit: values => {
      if(selectedTab !== 7){
        feedbackAdminReplyApi.mutate(
          { id: detail.id, ...values },
          {
            onSuccess: () => {
              toast.success(t("feedback.reply_sent"))
              // props.setEmailMessage(
              //   "Please check your inbox and spam/junk folder for the reply sent."
              // )
              // props.showEmailModal(true)
              queryClient.invalidateQueries(["getFeedbackList"])
              queryClient.invalidateQueries(["getNotificationData"])
              queryClient.refetchQueries(["getNotificationSummary"])
              setShowFeedbackModel(false)
            }
          }
        )  
      }else{
        payrollReplyApi.mutate(
          { id: detail.id, ...values },
          {
            onSuccess: () => {
              toast.success(t("feedback.reply_sent"))
              // props.setEmailMessage(
              //   "Please check your inbox and spam/junk folder for the reply sent."
              // )
              // props.showEmailModal(true)
              queryClient.invalidateQueries(["getPayrollList"])
              queryClient.invalidateQueries(["getNotificationData"])
              queryClient.refetchQueries(["getNotificationSummary"])
              setShowFeedbackModel(false)
            }
          }
        )  
      }
      // console.log("submited values", values)
    }
  })
  return (
    <Box
      border={"1px solid #E3E3E3"}
      borderRadius={"8px"}
      mb={1}
      bgcolor={"#fff"}
      key={index}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            bgcolor={"#F2F2F2"}
            p={"2px 16px"}
            // borderTopRightRadius="8px"
            style={{ borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} gridGap={"10px"} alignItems={"center"}>
                <Box width={"40px"} paddingTop={"8px"}>
                  {detail?.user_info?.avatar ? (
                    <img
                      src={detail?.user_info?.avatar}
                      alt="usr-img"
                      width={"100%"}
                    />
                  ) : (
                    <Icon>
                      <FeedbackUserIcon width={"100%"} />
                    </Icon>
                  )}
                </Box>
                <Box>
                  <NCText variant="subtitle2">
                    <Box
                      fontWeight={"bold"}
                      style={{ "text-transform": "capitalize" }}
                    >
                      {detail?.user_info?.name}
                    </Box>
                  </NCText>

                  <Box>
                    <NCText variant="body">
                      {USER_TYPE[detail?.user_info?.user_type]}
                    </NCText>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  bgcolor={"#E5E7EB"}
                  color={"#2E3B36"}
                  p={"5px 10px"}
                  borderRadius={"10px"}
                >
                  <NCText variant="body">
                    {`${t("feedback.client_name")}: ${
                      detail?.on_behalf_of_client_info?.name || "-"
                    }`}
                  </NCText>
                </Box>
                <Box
                  mt={0.5}
                  bgcolor={"#EFFDFF"}
                  p={0.5}
                  paddingLeft={1.5}
                  borderRadius={"8px"}
                >
                  <NCText variant="body" color={"textSecondary"}>
                    {formatDate(detail.created)}
                    {" / "}
                    {new Date(detail.created).toLocaleTimeString("en-US")}
                  </NCText>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box p={"8px 16px 8px 16px"}>
            <Grid container>
              <Grid item xs>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box display={"flex"} gridGap={"2px"} alignItems={"center"}>
                    <NCText variant={"h6"}>
                      <Box fontWeight={"bold"}>{detail.title}</Box>
                    </NCText>
                    <NCText variant="subtitle1" color={"textSecondary"}>
                      ({FEEDBACK_TYPE[detail.type]})
                    </NCText>
                  </Box>
                  <Box>
                    {detail.document ? (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <NCText
                            className={"cursor-pointer"}
                            onClick={() => window.open(detail?.document)}
                          >
                            <Box display={"flex"} alignItems={"center"}>
                              {detail?.document ? <DocumentIcon /> : null}
                              {detail?.document
                                ? getDocNameFromPath(detail?.document)
                                : null}
                            </Box>
                          </NCText>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {detail?.last_reply?.length ? (
            detail?.last_reply?.map(m => (
              <Box p={"0 16px 16px"}>
                <NCText variant="body" color={"textSecondary"}>
                  {m.reply}
                </NCText>
              </Box>
            ))
          ) : (
            <Box p={"0 16px 16px"}>
              <NCText variant="body" color={"textSecondary"}>
                {detail.message}
              </NCText>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} >
          {detail?.payroll_answers?.map((qa, index) => {
            return (
              <Box
                p={"8px 16px 8px 16px"}
                display={"flex"}
                style={{ flexDirection: "column", marginTop: "5px" }}
              >
                <div>
                  <NCText>
                    <Box
                      maxWidth={"100%"}
                      fontSize={"14px"}
                      fontWeight={"bold"}
                    >
                      {`Q${index + 1}. `}
                      {qa.question}
                    </Box>
                  </NCText>
                </div>
                <NCText variant="subtitle2" color={"textSecondary"}>
                  Ans {qa.answer}
                </NCText>
              </Box>
            )
          })}
        </Grid>

        <Grid
          container
          item
          xs={12}
          direction="column"
          alignItems="flex-end"
          style={{
            paddingRight: "16px",
            paddingBottom: "16px"
          }}
        >
          <NCButton
            variant="contained"
            color="primary"
            endIcon={<NCSvgIcon component={ReplyIcon} inheritViewBox />}
            onClick={() => {
              //   setIsView(false)
              //   setSelectedFeedback(detail)
              setShowFeedbackModel(true)
            }}
            style={{ width: "90px", fontSize: "16px" }}
          >
            {t("feedback.reply")}
          </NCButton>
        </Grid>
      </Grid>
      <FormikProvider value={formik}>
        <NCModal
          open={showFeedbackModel}
          title={selectedTab != 7 ? t("feedback.feedback") : t("feedback.payroll")}
          text={
            <FeedbackPopup
              detail={detail}
              enableFeedback={selectedTab != 7}
              edit={
                <FormField
                  label={t("feedback.reply")}
                  name={"reply"}
                  multiline
                  rows={3}
                  required={detail.reply ? false : true}
                  value={formik.values.reply}
                  readOnly={detail.reply}
                  error={formik?.errors?.reply}
                />
              }
            />
          }
          cancelButtonLabel={detail?.reply ? t("common.close") : ""}
          onClose={() => setShowFeedbackModel(false)}
          submitButton={
            detail?.reply ? (
              " "
            ) : (
              <NCButton
                variant="contained"
                color="primary"
                disableElevation
                style={{ width: "auto" }}
                onClick={formik.handleSubmit}
              >
                {t("common.send")}
              </NCButton>
            )
          }
        />
      </FormikProvider>
    </Box>
  )
}

export default withContext(FeedbackCard)
