import { Grid, makeStyles } from "@material-ui/core"
import background from "../assets/images/background.svg"
import logo from "../assets/images/logo.svg"
// import NCText from "../shared/NCText"

const useStyles = makeStyles(theme => ({
  left: {
    background: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: "20px",
    padding: "45px",
    [theme.breakpoints.down("md")]:{
      display:"none"
    }
  },
  right: {
    background: theme.palette.primary.BG,
    height: "100vh",
    [theme.breakpoints.down("md")]:{
      background: `url(${background})`,
    }
  },
  heading: {
    color: "white",
    fontSize: "35px",
    fontWeight: "normal",
    lineHeight: "40px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "60px",
      lineHeight: "70px"
    }
  },
  text: {
    color: "white",
    fontSize: "12px", //change factor 0.079
    fontWeight: "normal",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px"
    }
  },
  img:{
    position: "absolute",
    left:"30px",
    bottom:"30px",
    [theme.breakpoints.up("lg")]: {
      display:"none"
    }
  }
}))

const AuthComponent = props => {
  const cls = useStyles()

  return (
    <Grid container spacing={0}>
      <Grid item container className={cls.left} xs={12} lg={6}>
        <Grid item>
          <img src={logo} style={{ width: "80px" }} />
        </Grid>
        {/* <Grid item>
          <NCText className={cls.heading}>
            Effortlessly manage all your services in one place.
          </NCText>
        </Grid>
        <Grid item>
          <NCText className={cls.text}>
            Welcome to your admin panel, where you can easily manage all of your
            services in one convenient location. From updating your products to
            managing user accounts, our streamlined platform puts everything you
            need at your fingertips. Log in now to take control of your
            business!
          </NCText>
        </Grid> */}
      </Grid>
      <Grid
        alignItems="center"
        className={cls.right}
        container
        // direction="column"
        justifyContent="center"
        xs={12}
        lg={6}
      >
        {props.children}
        <img src={logo} style={{ width: "80px" }} className={cls.img} />
      </Grid>
    </Grid>
  )
}

export default AuthComponent
