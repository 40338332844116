import { useState, useEffect } from "react"
import { makeStyles, Paper } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import NCInput from "../shared/NCInput"
import NCTitle from "../shared/NCTitle"
import NCText from "../shared/NCText"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import { Form, useFormik, FormikProvider } from "formik"
import FormField from "../components/FormField"
import * as Yup from "yup"
import { useVerifyOtp } from "../services/mutations"
import { useResendOtp } from "../services/mutations"
import { toast } from "react-toastify"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px"
  },
  title: {
    marginTop: "12px",
    marginBottom: "15px"
  },
  waitText: {
    fontSize: "12px",
    paddingTop: "10px"
  }
}))

const AuthenticationPage = props => {
  const cls = useStyles()
  const verifyOtpRequest = useVerifyOtp()
  const navigate = useNavigate()
  const [counter, setCounter] = useState(60)
  const sendOtpRequest = useResendOtp()
  const formik = useFormik({
    initialValues: {
      otp: ""
    },
    validationSchema: Yup.object().shape({
      otp: Yup.string().required("Verification code is required")
    }),
    onSubmit(values) {
      verifyOtpRequest.mutate(
        { verify_type: "LOGIN", ...values, ...props.userCredentials },
        {
          onSuccess: () => {
            props.updateLanguage();
            navigate("/clients")
          },
          onError: () => {
            // console.log("error block")
          }
        }
      )
    }
  })
  useEffect(() => {
    let interval = setInterval(
      () =>
        setCounter(prevCounter =>
          prevCounter > 0 ? --prevCounter : prevCounter
        ),
      1000
    )
    return () => clearInterval(interval)
  }, [])

  const handleClick = () => {
    counter === 0 &&
      sendOtpRequest.mutate(
        {},
        {
          onSuccess: () => {
            toast.success("Verification code has been sent successfully.")
            setCounter(60)
          }
        }
      )
  }

  return (
    <AuthComponent>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>Verify that it's you</NCTitle>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <FormField
              as={NCInput}
              required={true}
              className={cls.input}
              placeholder="Enter verification code"
              name="otp"
              error={formik?.errors?.otp}
            />
            <NCButton type="submit" variant="contained" color="primary">
              Sign In
            </NCButton>
            <NCText className={cls.waitText}>
              Wait for {counter} seconds before requesting a &nbsp;
              <span
                style={
                  counter > 0
                    ? { color: "#9CA3AF", fontWeight: "bold" }
                    : { color: "#1EAAC4", fontWeight: "bold" }
                }
                onClick={handleClick}
                className={counter === 0 && "cursor-pointer"}
              >
                New Code
              </span>
            </NCText>
          </Form>
        </FormikProvider>
      </Paper>
    </AuthComponent>
  )
}

export default AuthenticationPage
