import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import NCText from "../../shared/NCText"
import { useTranslation } from "react-i18next"
import NCInput from "../../shared/NCInput"
import { DetailIcon, SearchIcon } from "../../libs/icons"
import NCCalendarButton from "../../shared/NCCalendarButton"
import { formatDateString, formatIncidentDate } from "../../libs/utils"
import { useGetTripReportList } from "../../services/queries"
import useDebounce from "../../libs/hooks/Debounce"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import NCTable from "../../shared/table/NCTable"
import NCLoader from "../../shared/NCLoader"
import { useNavigate } from "react-router-dom"
import NCButton from "../../shared/NCButton"
import Filter from "../../shared/table/Filter"
import { useMarkAsPaid, usePostReportToAdmin } from "../../services/mutations"
import { toast } from "react-toastify"

const ManageReports = () => {
  const { t } = useTranslation()
  const nav = useNavigate()
  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const [query, setQuery] = useState("")
  const [selectedDate, setSelectedDate] = useState(null)
  const [page, setPage] = useState(1)
  const [ordering, setOrdering] = useState("id")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [selectedReports, setSelectedReports] = useState([])
  const [statusFilter, setStatusFilter] = useState([])
  const [shouldShowPayBtn, setShouldShowPayBtn] = useState(false)
  const [shouldShowSendBtn, setShouldShowSendBtn] = useState(false)
  const sendReportRequest = usePostReportToAdmin()
  const markPaidRequest = useMarkAsPaid()

  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const { isLoading, data, refetch } = useGetTripReportList(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      report_status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null,
      date: selectedDate || ""
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: reportsData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  useEffect(() => {
    if (selectedReports.length > 0) {
      const isPaid =
        selectedReports.findIndex(report => report.report_status === "PAID") >
        -1
      const isSent =
        selectedReports.findIndex(report => report.report_status === "SENT") >
        -1
      setShouldShowPayBtn(isPaid ? false : true)
      setShouldShowSendBtn(isSent ? false : true)
    } else {
      setShouldShowPayBtn(false)
      setShouldShowSendBtn(false)
    }
  }, [selectedReports])

  function SelectColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <Filter
        options={[
          { key: "PENDING", name: t("reports.pending") },
          { key: "SENT", name: t("reports.sent") },
          { key: "PAID", name: t("reports.paid") },
          { key: "PROCESSING", name: t("reports.processing") }
        ]}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }

  const onCheckChange = report => {
    const isSelected = isReportSelected(report.id)
    if (!isSelected) {
      setSelectedReports(reports => [...reports, report])
    } else {
      const arrayToUpdate = [...selectedReports]
      const updatedArr = arrayToUpdate.filter(item => item.id !== report.id)
      setSelectedReports(updatedArr)
    }
  }

  const isReportSelected = id => {
    return selectedReports?.findIndex(item => item.id === id) > -1
  }

  const sendReportToAdmin = () => {
    const dataToSend = {
      trips: selectedReports.map(report => report.id)
    }
    sendReportRequest.mutate(dataToSend, {
      onSuccess: () => {
        setSelectedReports([])
        toast.success(t("reports.sent_success"))
        refetch();
      }
    })
  }
  const markReportAsPaid = () => {
    const dataToSend = {
      trips: selectedReports.map(report => report.id)
    }
    markPaidRequest.mutate(dataToSend, {
      onSuccess: response => {
        setSelectedReports([])
        toast.success(response?.data?.message)
      }
    })
  }

  const getIdCell = cell => {
    return (
      <Grid container alignItems="center">
        <Checkbox
          color="primary"
          onChange={() => onCheckChange(cell)}
          checked={isReportSelected(cell?.id)}
          disabled={cell?.report_status === "PAID"}
        />
        <NCText>{cell?.id}</NCText>
      </Grid>
    )
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <NCText>{cell?.drop_off_address?.address}</NCText>
        <IconButton onClick={() => nav(`/manage-reports/${cell.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }

  const getDriverName = cell => {
    return (
      <div>
        <NCText>{cell?.driver?.name}</NCText>
      </div>
    )
  }

  const getClientName = cell => {
    return (
      <div>
        <NCText>{cell?.client_request?.client_name}</NCText>
      </div>
    )
  }

  const getReportStatus = cell => {
    return (
      <div>
        <NCText>{cell?.report_status}</NCText>
      </div>
    )
  }

  const columns = [
    {
      Header: t("request"),
      id: "id",
      accessor: cell => getIdCell(cell),
      disableSortBy: true
    },
    {
      Header: t("driver"),
      id: "driver.name",
      accessor: cell => getDriverName(cell),
      disableSortBy: true
    },
    {
      Header: t("admin.admin"),
      id: "admin",
      accessor: cell =>
        cell?.client_request?.client_subadmin_info?.admin?.company_name,
      disableSortBy: true
    },
    {
      Header: t("client_name"),
      id: "client",
      accessor: cell => getClientName(cell),
      disableSortBy: true
    },
    {
      Header: t("status"),
      id: "status",
      accessor: cell => getReportStatus(cell),
      defaultCanFilter: true,
      Filter: SelectColumnFilter,
      disableSortBy: true
    },
    {
      Header: t("pickup_address"),
      accessor: cell => cell.pickup_address.address,
      disableSortBy: true
    },
    {
      Header: t("pickup_datetime"),
      accessor: cell => formatDateString(cell.pickup_time),
      disableSortBy: true
    },

    {
      Header: t("drop_address"),
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  return (
    <Box>
      <Box mb={2}>
        <Grid container justifyContent="space-between" mb={20}>
          <Grid item xs={12} md={4}>
            <NCText variant={"h4"}>{t("manage_reports")}</NCText>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid
              container
              spacing={2}
              justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
            >
              {shouldShowPayBtn && (
                <Grid item style={{ paddingRight: "10" }}>
                  <NCButton
                    variant="contained"
                    color="primary"
                    disalbed={true}
                    onClick={markReportAsPaid}
                  >
                    {t("mark_paid")}
                  </NCButton>
                </Grid>
              )}
              {shouldShowSendBtn && (
                <Grid item style={{ paddingRight: "10" }}>
                  <NCButton
                    variant="contained"
                    color="primary"
                    disalbed={true}
                    onClick={sendReportToAdmin}
                  >
                    {t("send_to_admin")}
                  </NCButton>
                </Grid>
              )}
              <Grid item>
                <NCInput
                  value={query}
                  onChange={e => {
                    setQuery(e.target.value)
                  }}
                  placeholder={t("search_here")}
                  startIcon={SearchIcon}
                  className="rounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="space-between" mb={20}>
        <Box />
        <Box width={"150px"}>
          <NCCalendarButton
            selectedDate={selectedDate => {
              selectedDate
                ? setSelectedDate(
                    formatIncidentDate(selectedDate, "YYYY-MM-DD")
                  )
                : setSelectedDate(null)
            }}
          />
        </Box>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <NCTable
            columns={columns}
            data={reportsData || []}
            page={page}
            onNext={onNext}
            onPrev={onPrev}
            pageCount={total_pages}
            gotoPage={setPage}
            pageSize={pageSize || TABLE_CONFIG.PAGE_SIZE}
            setPageSize={setPageSize}
            sorting={ordering}
            setSorting={setOrdering}
            filter={statusFilter}
            setFilter={setStatusFilter}
          />
          {isLoading && <NCLoader open={true} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ManageReports
