import { Grid, makeStyles } from "@material-ui/core"
import Navigation from "./Navigation"
import { FAQs } from "./const"
import { useEffect, useState } from "react"
import NCAccordion from "../../shared/NCAccordion"

const useStyles = makeStyles(theme => ({
  container: {
    background: "linear-gradient(180deg, #1EAAC4 0%, #10658E 100%)",
    display: "grid",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    gridTemplateColumns: "12fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "3fr 9fr"
    }
  },
  header: {
    fontSize: "50px",
    color: "white",
    marginBottom: "2rem"
  },
  description: {
    fontSize: "16px",
    color: "white",
    marginBottom: "2rem"
  },
  accordianDetails: {
    marginLeft: "20px",
    borderLeft: "5px solid #1EAAC4",
    paddingLeft: "10px"
  },
  content: {
    paddingLeft: "10px",
    paddingRight: "10px"
  }
}))

const FAQ = ({ faq }) => {
  const cls = useStyles()

  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    const faqList =
      faq?.data?.results
        ?.filter(faq => faq?.is_active)
        ?.map(faq => ({
          title: faq?.question || "",
          description: faq?.answer || "",
          expanded: true
        })) || []

    setFaqs(faqList)
  }, [faq])

  const updateAccordianState = index => {
    const faqCopy = JSON.parse(JSON.stringify(faqs))
    faqCopy[index].expanded = !faqCopy[index].expanded
    setFaqs(faqCopy)
  }

  return (
    <div className={cls.container} id="faqs">
      <Navigation currentPage={FAQs} />
      <Grid container direction="column" className={cls.content}>
        <Grid item>
          <Grid container justifyContent="center">
            <Grid item className={cls.header}>
              FAQs
            </Grid>
            <Grid item className={cls.description}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              quis eros posuere, fermentum odio quis, elementum odio. Duis
              sagittis viverra purus sed egestas.
            </Grid>
          </Grid>
        </Grid>
        {faqs?.map((faq, index) => {
          return (
            <Grid item>
              <NCAccordion
                onChange={() => updateAccordianState(index)}
                title={faq.title}
                expanded={faq.expanded}
                description={faq.description}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default FAQ
