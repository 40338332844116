import { Box } from "@material-ui/core"
import NCText from "../shared/NCText"

const FormSection = ({
  title,
  subTitle,
  subSection = false,
  children,
  customBorder,
  customBgColor
}) => {
  return (
    <Box
      borderRadius={subSection ? "5px" : "16px"}
      border={customBorder ? customBorder : "1px solid #D1D5DB"}
      marginBottom={2}
    >
      <Box
        bgcolor={customBgColor ? customBgColor : "#F9FAFB"}
        p={1}
        borderRadius={subSection ? "5px 5px 0 0" : "16px 16px 0 0"}
        fontWeight={"bold"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <NCText>{title}</NCText>
        <Box>{subTitle}</Box>
      </Box>
      <Box p={1}>{children}</Box>
    </Box>
  )
}

export default FormSection
