import { Box, Grid, makeStyles } from "@material-ui/core"
import {
  DriverEditIcon,
  EndMarkerIcon,
  StartMarkerIcon
} from "../../libs/icons"
import NCText from "../../shared/NCText"
import NCButton from "../../shared/NCButton"
import NCSvgIcon from "../../shared/NCSvgIcon"
import NCTable from "../../shared/table/NCTable"
import { useEffect, useRef, useState } from "react"
import { TABLE_CONFIG } from "../../libs/config"
import { useGetRideDetails, useGetTripStops } from "../../services/queries"
import { compareDates, formatAMPM, isDateBefore } from "../../libs/utils"
import { STOPS_CALL_INTERVAL, STOP_STATUS, STOP_TYPE, rideStatus } from "../../libs/const"
import Map from "../../components/map/Map"
import AssignDriverModal from "./assign-driver-modal"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  container: {
    border: "1px solid #F3F4F6"
  },
  headingContainer: {
    backgroundColor: "#F9FAFB",
    marginBottom: "10px"
  },
  heading: {
    fontSize: "26px",
    padding: "10px 0px"
  },
  requestId: {
    fontWeight: 700
  },
  status: {
    fontSize: "18px",
    fontWeight: 600
  },
  statusText: {
    color: "#7D7D7D",
    fontSize: "14px"
  },
  boxContainer: {
    border: "1px solid #D1D5DB"
  },
  circle: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 10px"
  },
  yColor: {
    backgroundColor: "#F9B50B"
  },
  gColor: {
    backgroundColor: "#4CAF50"
  },
  rightContainerTitle: {
    color: "#2E3B36",
    fontSize: "24px",
    padding: "15px 20px",
    borderBottom: "1px solid #E3E3E3"
  },
  alertIndicator:{
    color:"#E22134"
  }
}))

const ScheduledTrip = ({ startTime, endTime, driver, search }) => {
  const cls = useStyles()

  const {t} = useTranslation();

  const [stops, setStops] = useState([])
  const [center, setCenter] = useState(null)
  const [driverLocation, setDriverLocation] = useState(null)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])

  const [assignDriverModal, setAssignDriverModal] = useState(false)

  const [count, setCount] = useState(0)

  const stopsInterval = useRef(null)


  const { isLoading, data, refetch: tripsRefetch } = useGetRideDetails(
    {
      page,
      search: search,
      pageSize,
      ordering,
      start_time: startTime,
      end_time: endTime,
      driver: driver
    },
    {
      enabled: !!(driver && startTime && endTime)
    }
  )

  const {
    isLoading: isStopsLoading,
    data: stopsResp,
    refetch
  } = useGetTripStops(
    {
      page_size: 100,
      start_time: startTime,
      end_time: endTime,
      driver: driver
    },
    {
      enabled: !!(driver && startTime && endTime)
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: driverData
  } = data?.data?.data || {}

  const { results: stopsData } = stopsResp?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const onRowClicked = row => {
    const index = row.index
    const stop = stops[index]
    setCenter({
      lat: stop.latitude,
      lng: stop.longitude
    })
  }

  const columns = [
    {
      Header: t("list.trip_id"),
      id: "id",
      accessor: cell => cell.id,
      Cell: ({ value }) => (
        <Box pt={1} pb={1}>
          <NCText>{value}</NCText>
        </Box>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.start_address"),
      id: "start_address",
      disableSortBy: true,
      accessor: cell => cell.pickup_address?.address
    },
    {
      Header: t("list.start_time"),
      id: "arrival",
      accessor: cell => cell.pickup_time,
      disableSortBy: true,
      Cell: ({ value }) =>
        value ? formatAMPM(value).toUpperCase() : "-"
    },
    {
      Header: t("list.end_address"),
      id: "end_address",
      disableSortBy: true,
      accessor: cell => cell.drop_off_address?.address
    },
    // {
    //   Header: "End Date/Time",
    //   id: "dropoff",
    //   disableSortBy: true,
    //   accessor: cell => cell.timing_details.dropped_time,
    //   Cell: ({ value }) =>
    //     value ? formatDate(value) + " - " + formatAMPM(value) : "-"
    // },
    {
      Header: t("list.status"),
      id: "status",
      disableSortBy: true,
      accessor: cell => rideStatus[cell.status]
    }
  ]

  const alertIndicator = (row) => {
    return compareDates(row.arrival,row.est_arrival) ?  cls.alertIndicator :"";
  };

  const stopColumns = [
    {
      Header: t("list.seq"),
      id: "id",
      accessor: cell => cell,
      Cell: ({ value }) => (
        <Box pt={1} pb={1} mr={2} className={alertIndicator(value)}>
          <NCText>{value.seq}</NCText>
        </Box>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.client_name"),
      id: "start_address",
      disableSortBy: true,
      accessor: cell => cell,
      Cell: ({ value }) => <Box className={alertIndicator(value)} mr={2}>{value.client_name}</Box>
    },
    {
      Header: t("list.address"),
      id: "arrival",
      accessor: cell => cell,
      disableSortBy: true,
      Cell: ({ value }) => <Box className={alertIndicator(value)} mr={3}>{value.address}</Box>
    },
    {
      Header: t("list.arrival"),
      id: "dropoff",
      disableSortBy: true,
      accessor: cell => cell,
      Cell: ({ value }) => (
        <Box mr={2} ml={1} className={alertIndicator(value)}>
          {value?.arrival ? formatAMPM(value.arrival).toUpperCase() : "-"}
        </Box>
      )
    },
    {
      Header: t("list.est_arrival"),
      id: "end_address",
      disableSortBy: true,
      accessor: cell => cell,
      Cell: ({ value }) => (
        <Box mr={2} className={alertIndicator(value)}>
          {value?.est_arrival ? formatAMPM(value.est_arrival).toUpperCase() : "-"}
        </Box>
      )
    },
    {
      Header: t("list.type"),
      id: "type",
      disableSortBy: true,
      accessor: cell => cell,
      Cell: ({ value }) => <Box mr={2} className={alertIndicator(value)}>{value.type}</Box>
    },
    {
      Header: t("list.status"),
      id: "status",
      disableSortBy: true,
      accessor: cell => cell,
      Cell: ({ value }) => <Box mr={2} className={alertIndicator(value)}>{value.status}</Box>
    },
    {
      Header: t("list.distance"),
      id: "distance",
      disableSortBy: true,
      accessor: cell => cell,
      Cell: ({ value }) => <Box mr={2} className={alertIndicator(value)}>{value.distance}</Box>
    }
  ]

  useEffect(() => {
    if (stopsData) {
      const stopsList = stopsData
      const stops =
        stopsList.map((stop, index) => {

          let latitude = stop?.address?.latitude;
          let longitude = stop?.address?.longitude;

          const list = JSON.parse(JSON.stringify(stopsList)).splice(0,index)
          const sameFound = list.filter(item => item.address.latitude == latitude && item.address.longitude === longitude);

          latitude += (0.00001 * sameFound.length)

          return {
            seq: stop.id,
            client_name: stop?.client_name,
            address: stop?.address?.address ,
            est_arrival: stop?.eta,
            arrival: stop?.scheduled_time,
            type: STOP_TYPE[stop?.type],
            status: STOP_STATUS[stop?.status],
            distance: stop?.trip.approx_distance_in_miles + " miles",
            info: stop.id + " - " + stop?.trip.client.name,
            showPopup:true,
            latitude,
            longitude,
          }
        }) || []
      if (count == 0) {
        setCenter({
          lat: stops?.[0]?.latitude,
          lng: stops?.[0]?.longitude,
        })
        setCount(count + 1)
      }
      if(stops.length > 0){
        const driverLocation = {
          seq:"123",
          latitude: stopsData[0]?.trip?.location_data?.driver?.latitude,
          longitude: stopsData[0]?.trip?.location_data?.driver?.longitude,
          geenMark:true,
          showPopup: true,
          info: stopsData[0]?.trip?.driver?.name
        }  
        setDriverLocation(driverLocation);
      }
      setStops(stops)
    }
  }, [stopsData])

  useEffect(() => {
    stopsInterval.current = setInterval(() => {
      refetch()
    }, STOPS_CALL_INTERVAL)

    return () => {
      clearInterval(stopsInterval.current)
    }
  }, [])

  useEffect(() => {
    setPage(1)
  }, [search])

  useEffect(() => {
    setCount(0)
  }, [driver])

  const trips = driverData?.reduce((acc,trip)=>{
    acc[trip.id] = true;
    return acc;
  },{})

  return (
    <Grid container>
      <Grid item xs={12} className={cls.container}>
        <Box mb={2} p={1} mt={2}>
          {!driver && (
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"10px"}
              pt={2}
              pb={2}
            >
              <NCText>{t("trip-log.ask_driver_select")}</NCText>
            </Box>
          )}
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            className={cls.headingContainer}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"10px"}
              pt={2}
              pb={2}
            >
              <>
                <StartMarkerIcon />
                <NCText>{t("trip-log.driver_location")}</NCText>
                <EndMarkerIcon />
                <NCText>{t("trip-log.stops")}</NCText>
              </>
            </Box>
          </Box>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={6}>
              <Box height={"300px"} p={1} className={cls.boxContainer}>
                {stops?.length > 0 && (
                  <Map
                    center={center}
                    stops={[...stops, driverLocation]}
                    showStartToEnd={false}
                    showClientToDriver={false}
                    zoomControl={true}
                    zoom={15}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box height={"100%"} className={cls.boxContainer} p={0}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  p={2}
                  style={{ borderBottom: "1px solid #e3e3e3" }}
                >
                  <NCText>{t("trip-log.stops")}</NCText>
                  <NCText style={{ fontWeight: "bold" }}>{stops.length}</NCText>
                </Box>
                <Box p={1} height={"243px"} overflow={"scroll"}>
                  <NCTable
                    columns={stopColumns}
                    data={stops}
                    // rowClassName={rowClassName}
                    isLoading={isStopsLoading}
                    onRowClicked={onRowClicked}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2} pl={1} pr={1} mt={2} className={cls.headingContainer}>
          <Box
            pt={1}
            pb={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <NCText>{t("trip-log.trips")}</NCText>
            <Box>
              <NCButton
                variant="contained"
                color="primary"
                endIcon={
                  <NCSvgIcon component={DriverEditIcon} inheritViewBox />
                }
                disabled={!(driverData && driverData?.length > 0) || isDateBefore(startTime)}
                onClick={() => {
                  setAssignDriverModal(true)
                }}
              >
                {t("change_driver.change_driver")}
              </NCButton>
            </Box>
          </Box>
        </Box>
        <NCTable
          columns={columns}
          data={driverData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
      </Grid>
      {assignDriverModal && <AssignDriverModal
        driverId={driver}
        tripsRefetch={tripsRefetch}
        trips={trips}
        stopsRefetch={refetch}
        startTime={startTime}
        modalState={assignDriverModal}
        handleClose={() => setAssignDriverModal(false)}
      />}
    </Grid>
  )
}

export default ScheduledTrip
