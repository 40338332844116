import { Backdrop, CircularProgress } from "@material-ui/core"

const NCLoader = props => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 1 }}
      open={props.open}
      onClick={props.handleClose}
    >
      <CircularProgress />
    </Backdrop>
  )
}

export default NCLoader
