import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Grid, IconButton, makeStyles } from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCInput from "../../shared/NCInput"
import { DetailIcon, SearchIcon } from "../../libs/icons"
import NCCalendarButton from "../../shared/NCCalendarButton"
import { formatDateString, formatIncidentDate } from "../../libs/utils"
import useDebounce from "../../libs/hooks/Debounce"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import {
  useGetPendingAmount,
  useGetTripReportList
} from "../../services/queries"
// import { NOAH_CARES, STORAGE } from "../../libs/storage"
import NCTable from "../../shared/table/NCTable"
import NCLoader from "../../shared/NCLoader"
import { useNavigate } from "react-router-dom"
import NCButton from "../../shared/NCButton"
import { usePayPendingAmount } from "../../services/mutations"
import { toast } from "react-toastify"
import NCModal from "../../shared/NCModal"
import { useQueryClient } from "react-query"

const useStyles = makeStyles(() => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  pay: {
    marginLeft: "10px"
  }
}))

const MyReports = () => {
  const [query, setQuery] = useState("")
  const cls = useStyles()
  const { t } = useTranslation()
  const nav = useNavigate()
  const [selectedDate, setSelectedDate] = useState(null)
  const [page, setPage] = useState(1)
  const [ordering, setOrdering] = useState("id")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [shouldShowPayBtn, setShouldShowPayBtn] = useState(false)
  const [showPayModal, setShowPayModal] = useState(false)

  const queryClient = useQueryClient()
  const payPendingAmount = usePayPendingAmount()
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const { isLoading, data, refetch } = useGetTripReportList(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      date: selectedDate || ""
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: reportsData
  } = data?.data?.data || {}

  const { data: unpaidAmountData, refetch: refetchUnpaidAmount } =
    useGetPendingAmount()

  useEffect(() => {
    setShouldShowPayBtn(unpaidAmountData?.data?.data?.total_unpaid_amount > 0)
  }, [unpaidAmountData])

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <NCText>{cell?.drop_off_address?.address}</NCText>
        <IconButton onClick={() => nav(`/my-reports/${cell.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }

  const getDriverName = cell => {
    return (
      <div>
        <NCText>{cell?.driver?.name}</NCText>
      </div>
    )
  }

  const getClientName = cell => {
    return (
      <div>
        <NCText>{cell?.client_request?.client_name}</NCText>
      </div>
    )
  }

  const getReportStatus = cell => {
    return (
      <div>
        <NCText>
          {cell?.report_status === "SENT" ? "PENDING" : cell?.report_status}
        </NCText>
      </div>
    )
  }

  const columns = [
    {
      Header: t("request"),
      id: "id",
      accessor: cell => cell.id,
      disableSortBy: true
    },
    {
      Header: t("driver"),
      id: "driver.name",
      accessor: cell => getDriverName(cell),
      disableSortBy: true
    },
    {
      Header: t("client_name"),
      id: "client",
      accessor: cell => getClientName(cell),
      disableSortBy: true
    },
    {
      Header: t("status"),
      id: "status",
      accessor: cell => getReportStatus(cell),
      defaultCanFilter: false,
      disableSortBy: true
    },
    {
      Header: t("pickup_address"),
      accessor: cell => cell.pickup_address.address,
      disableSortBy: true
    },
    {
      Header: t("pickup_datetime"),
      accessor: cell => formatDateString(cell.pickup_time),
      disableSortBy: true
    },

    {
      Header: t("drop_address"),
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  const onPayClick = async () => {
    setShowPayModal(false)
    const payResponse = await payPendingAmount.mutateAsync()
    if ((await payResponse).status === 200 && payResponse?.data?.message) {
      queryClient.invalidateQueries({
        queryKey: ["getTripReportList", "useGetPendingAmount"]
      })
      toast.success(t("payment_success"))
      refetch()
      refetchUnpaidAmount()
    } else {
      toast.error(payResponse?.data?.message)
    }
  }

  return (
    <Box>
      <Box mb={2}>
        <Grid container justifyContent="space-between" mb={20}>
          <Grid>
            <NCText variant={"h4"}>{t("my_reports")}</NCText>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {shouldShowPayBtn && (
                <Grid item>
                  <Grid container className={cls.center}>
                    <Grid item>
                      <NCText>
                        {t("unpaid_amount")}: $
                        {unpaidAmountData?.data?.data?.total_unpaid_amount}
                      </NCText>
                    </Grid>
                    <Grid item className={cls.pay}>
                      <NCButton
                        variant="contained"
                        color="primary"
                        disalbed={false}
                        onClick={() => setShowPayModal(true)}
                      >
                        {t("pay_now")}
                      </NCButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <NCInput
                  value={query}
                  onChange={e => {
                    setQuery(e.target.value)
                  }}
                  placeholder={t("search_here")}
                  startIcon={SearchIcon}
                  className="rounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={"flex-end"}
        alignItems="flex-end"
      >
        <NCCalendarButton
          selectedDate={selectedDate => {
            selectedDate
              ? setSelectedDate(formatIncidentDate(selectedDate, "YYYY-MM-DD"))
              : setSelectedDate(null)
          }}
        />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <NCTable
            columns={columns}
            data={reportsData || []}
            page={page}
            onNext={onNext}
            onPrev={onPrev}
            pageCount={total_pages}
            gotoPage={setPage}
            pageSize={pageSize || TABLE_CONFIG.PAGE_SIZE}
            setPageSize={setPageSize}
            sorting={ordering}
            setSorting={setOrdering}
          />
          {isLoading && <NCLoader open={true} />}
        </Grid>
      </Grid>
      <NCModal
        open={showPayModal}
        title={t("pay_pending_amount")}
        text={t("pay_pending_msg")}
        onClose={() => setShowPayModal(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => onPayClick()}
            style={{ width: "auto" }}
          >
            {t("common.confirm")}
          </NCButton>
        }
      />
    </Box>
  )
}

export default MyReports
