export const API_BASE_PATH = "https://noah-cares-38566-staging.botics.co"
const API_VERSION = "api/v1"
export const API_PATH = {
  LOGIN: "/api/v1/login/",
  LOGOUT: "/rest-auth/logout/",
  RESET_PASSWORD: "/rest-auth/password/reset/",
  NEW_PASSWORD: "/rest-auth/password/reset/confirm/",
  CHANGE_PASSWORD: "/rest-auth/password/change/",
  PRIVACY_POLICY: "/modules/privacy-policy/",
  TERMS_AND_CONDITIONS: "/modules/terms-and-conditions/",
  FAQS: "/modules/faq/",
  CONTACT_US: "/modules/contact-us/contact_us/",
  ABOUT_US: "/about-us/api/v1/",
  CLIENT_LIST: "/clients/api/v1/",
  USERS: "/api/v1/users/",
  AUTHENTICATION:
    "/modules/two-factor-authentication/user-two-factor/register/",
  AUTHCODE_VERIFICATION:
    "/modules/two-factor-authentication/user-two-factor/2/verify/",
  ADD_ADMIN: "/admins/api/v1/",
  CLIENT_REQUEST_LIST: "/client-requests/api/v1/",
  ADMIN_LIST: "/admins/api/v1/",
  ADMIN_EMPLOYEE_LIST: "/admins/api/v1/employees/",
  SUB_ADMIN_LIST: "/subadmins/api/v1/",
  SUB_EMPLOYEE_LIST: "/subadmins/api/v1/sub-employees/",
  ADD_SUB_ADMIN_EMPLOYEE: "/subadmins/api/v1/sub-employees/",
  REPRESENTATIVE_LIST: "/admins/api/v1/company-representatives/",
  ADD_EMPLOYEE: "/admins/api/v1/employees/",
  EDIT_REPRESENTATIVE: "/admins/api/v1/company-representatives/",
  SEND_OTP: "/modules/two-factor-authentication/two-factor/",
  VERIFY_OTP: "/modules/two-factor-authentication/two-factor/",
  USER_TIPS: "/client-requests/api/v1/user-tips/",
  VEHICLE_REQUIREMENTS: "/client-requests/api/v1/vehicle-requirements/",
  SERVICE_DETAILS: "/client-requests/api/v1/service-details/",
  OTHER_REQUIREMENTS: "/client-requests/api/v1/other-requirements/",
  CLIENT_REQUESTS: "/client-requests/api/v1/",
  GET_FEEDBACK_LIST: "/feedbacks/api/v1/",
  GET_PAYROLL_LIST: "/feedbacks/api/v1/payroll/",
  GET_FEEDBACK_REPLIES: "/feedbacks/api/v1/reply/",
  GET_PAYROLL_REPLY: "/feedbacks/api/v1/payroll/reply/",
  FEEDBACK_ADMIN_REPLY: feedbackId =>
    `/feedbacks/api/v1/${feedbackId}/feedback-reply/`,
  PAYROLL_REPLY: payrollId =>
    `/feedbacks/api/v1/payroll/${payrollId}/payroll-reply/`,
  USER_PROFILE: "/api/v1/profile/",
  GET_GUARDIAN_LIST: "/clients/api/v1/guardians/",
  EMERGENCY_CONTACT: "/clients/api/v1/emergency-contacts/",
  DISPATCHER_LIST: "/dispatchers/api/v1/",
  ADD_DISPATCHER: "/dispatchers/api/v1/",
  DRIVER: "/drivers/api/v1/",
  DRIVER_BASIC_DETAIL: "/drivers/api/v1/get-basic-driver-info/",
  AID_MONITOR: "/aid_monitors/api/v1/",
  ADD_FEEDBACK: "/feedbacks/api/v1/",
  VERIFY_AUTH_SETUP: "/modules/two-factor-authentication/user-two-factor/",
  TWO_FACTOR_DEACTIVATE:
    "/modules/two-factor-authentication/user-two-factor/deregister/",
  NOTIFICATION_SETTING: "/api/v1/notification_setting/",
  NOTIFICATION: "/modules/firebase-push-notifications/notification/",
  NOTIFICATION_SUMMARY:
    "/modules/firebase-push-notifications/notification/summary/",
  READ_NOTIFICATION: "/modules/firebase-push-notifications/user-notification/",
  MARK_ALL_NOTIFICATION_AS_READ:
    "/modules/firebase-push-notifications/user-notification/mark-all-as-read/",
  CLIENT_AUDIT_LOG: "/clients/api/v1/history/",
  REQUEST_AUDIT_LOG: "/client-requests/api/v1/history/",
  ADMIN_AUDIT_LOG: "/admins/api/v1/history/",
  SUBADMIN_AUDIT_LOG: "/subadmins/api/v1/history/",
  DRIVER_AUDIT_LOG: "/drivers/api/v1/history/",
  DISPATCHER_AUDIT_LOG: "/dispatchers/api/v1/history/",
  AID_MONITOR_AUDIT_LOG: "/aid_monitors/api/v1/history/",
  CLIENT_REQUEST_ACTION_STATUS: "/client-requests/api/v1/",
  DRIVER_APPOINTMENT: "/drivers/api/v1/web-appointment/",
  DRIVER_AVAILABILITY: "/drivers/api/v1/web-availability/",
  ADD_DRIVER_AVAILABILITY: "/drivers/api/v1/web-availability/",
  INSPECTION_QUESTIONS: "/drivers/api/v1/inspection-questions/",
  INSPECTION: "/drivers/api/v1/inspection/",
  DRIVER_LIST: "/dispatchers/api/v1/driver-list/",
  LIVE_LOCATION: "/dispatchers/api/v1/live-location/",
  TRIPS: "/trips/api/v1/",
  TRIP_STOPS: "/trips/api/v1/stops/",
  TRIP_CHANGE_DRIVER: "/trips/api/v1/change-driver/",
  ALLOCATED_DRIVER_FOR_UNSCHEDULE: "/trips/api/v1/allocate-driver/",
  GET_RECOMMENDED_DRIVER: "/trips/api/v1/recommended-drivers/",
  VEHICLES: "/drivers/api/v1/vehicles/",
  FEATURE_LIST: "/user-roles/api/v1/features/",
  USERS_LIST_FOR_ACCESS_MANAGEMENT: "/user-roles/api/v1/user-list/",
  USER_ROLE: "/user-roles/api/v1/user-role/",
  GET_INCIIDENT_REPORTS: "/incident-report/api/v1/reports/",
  TRAININGS: "/trainings/api/v1/",
  GENERATE_PLAID_LINK_TOKEN: "/plaid/api/v1/link_token/",
  GENERATE_PLAID_ACCESS_TOKEN: "/plaid/api/v1/access_token/",
  GET_TWILLIO_TOKEN: "/phone-calls/api/v1/token/",
  GET_PHONE_CALL_RECORDING: "/phone-calls/api/v1/",
  GET_BANK_DETAILS: "/plaid/api/v1/stripe/bank-details/",
  DELETE_BANK_LINKED: "/plaid/api/v1/delete-stripe-customer/",
  ASSIGN_DRIVER: tripId => `/trips/api/v1/${tripId}/assign-driver/`,
  PERFORM_BACKGROUND_CHECK: driverId => `/drivers/api/v1/${driverId}/run-background-check/`,
  APPROVE_CLIENT_REQUEST: clientRequestId =>
    `/client-requests/api/v1/${clientRequestId}/default-driver/`,
  PUT_ON_HOLD: clientId => `/clients/api/v1/${clientId}/put-on-hold/`,
  RELEASE_HOLD: clientId => `/clients/api/v1/${clientId}/release-hold/`,
  TRIP_REPORTS: `/trips/${API_VERSION}/reports/`,
  SEND_REPORTS: `/trips/${API_VERSION}/reports/send-to-admin/`,
  MARK_PAID: `/trips/${API_VERSION}/reports/mark-as-paid/`,
  EDIT_INVOICE: invoiceId =>
    `/trips/${API_VERSION}/reports/${invoiceId}/update-invoice/`,
  INTRO: `/${API_VERSION}/introduction-to-app/`,
  TRANSACTIONS: `/plaid/${API_VERSION}/admin-transaction-history/`,
  UNPAID_AMOUNT: `/trips/${API_VERSION}/reports/unpaid-amount/`,
  PAY_AMOUNT: `/trips/${API_VERSION}/reports/pay-unpaid-amount/`
}

export const TABLE_CONFIG = {
  PAGE_SIZE: 10
}
export const SEARCH_CONFIG = {
  TIME_IN_MILLI: 1000
}
export const GENDER = {
  M: "Male",
  F: "Female",
  O: "Other"
}

export const WORK_WEEK = {
  1: "Only Business Days",
  2: "Business Days Plus Saturday",
  3: "Whole Week",
  4: "No Frequency"
}

export const REQUEST_STATUS = {
  1: "pending",
  2: "approved",
  3: "declined",
  4: "onhold"
}
export const LIVE_LOCATION_STATUS = {
  "On a Trip/Ride": "on_trip",
  Idle: "idle",
  Offline: "offline"
}

export const ACTIVITY_STATUS = {
  A: "active",
  I: "inactive",
  O: "onhold"
}
export const DOCUMENT_VERIFICATION_STATUS = {
  PENDING: "Pending",
  VERIFIED: "Verified",
  REJECTED: "Rejected",
  EXPIRED: "Expired"
}

export const USER_TYPE = {
  1: "Super Admin",
  2: "Admin",
  3: "Sub Admin",
  4: "Dispatcher",
  5: "Aid Monitor",
  6: "Driver",
  7: "Guardian",
  8: "Client"
}
export const USER_TYPE_ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUB_ADMIN: 3,
  DISPATCHER: 4,
  AID_MONITOR: 5,
  DRIVER: 6,
  GUARDIAN: 7,
  CLIENT: 8
}
export const FEEDBACK_TYPE = {
  1: "Incident Report",
  2: "Late Report",
  3: "Billing Issue",
  4: "Missing Equipment"
}
export const DRIVER_TYPE = {
  10: "On Demand Driver",
  6: "Driver"
}
export const INSPECTION_REPORT_TYPE = {
  1: "PRE",
  2: "POST"
}
export const VEHICLE_FACILITIES = {
  BUCKLE_GUARD: "BG",
  BOOSTER: "BOS",
  SAFETY_VEST: "SV",
  WHEELCHAIR: "WC",
  SERVICE_DOG: "SD",
  TRAVEL_ALONE: "ALONE",
  CAR_SEAT: "CS"
}
// @unique
// class ClientStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'
//     ON_HOLD = 'O'

// @unique
// class DriverStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'

// @unique
// class RecordStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'

// @unique
// class RideRequestStatus(BaseEnum):
//     PENDING = 1
//     APPROVED = 2
//     DECLINED = 3

// @unique
// class DispatcherStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'

// @unique
// class DocumentVerificationStatus(BaseEnum):
//     PENDING = 'PENDING'
//     VERIFIED = 'VERIFIED'

// @unique
// class DriverVerificationStatus(BaseEnum):
//     PENDING = 1
//     APPROVED = 2
//     DECLINED = 3

export const UI_PATH = {
  CLIENTS: "/clients"
}

export const LANGUAGES = [
  { code: "en-us", lang: "American English" },
  { code: "es", lang: "Spanish" },
  { code: "fr", lang: "French" },
  { code: "ar", lang: "Arabic" },
  { code: "am", lang: "Amharic" },
  { code: "ti", lang: "Tigrinya" },
  { code: "om", lang: "Oromo" }
]

export const LANGUAGE_LOCALE = {
  ["en-us"]: "en-us",
  es: "es",
  fr: "fr",
  ar: "ar",
  am: "am",
  ti: "ti",
  om: "om",
  default_lang: "en-us"
}

export const COUNTRIES = [
  {
    label: "United States Of America",
    value: "USA"
  }
]

export const STATES = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
]

export const CITIES = [
  { state: "AL", city: "Birmingham" },
  { state: "AL", city: "Montgomery" },
  { state: "AL", city: "Mobile" },
  { state: "AL", city: "Huntsville" },
  { state: "AK", city: "Anchorage" },
  { state: "AK", city: "Fairbanks" },
  { state: "AK", city: "Juneau" },
  { state: "AK", city: "Sitka" },
  { state: "AZ", city: "Phoenix" },
  { state: "AZ", city: "Tucson" },
  { state: "AZ", city: "Mesa" },
  { state: "AZ", city: "Chandler" },
  { state: "AR", city: "Little Rock" },
  { state: "AR", city: "Fort Smith" },
  { state: "AR", city: "Fayetteville" },
  { state: "AR", city: "Springdale" },
  { state: "CA", city: "Los Angeles" },
  { state: "CA", city: "San Francisco" },
  { state: "CA", city: "San Diego" },
  { state: "CA", city: "San Jose" },
  { state: "CO", city: "Denver" },
  { state: "CO", city: "Colorado Springs" },
  { state: "CO", city: "Aurora" },
  { state: "CO", city: "Fort Collins" },
  { state: "CT", city: "Hartford" },
  { state: "CT", city: "New Haven" },
  { state: "CT", city: "Stamford" },
  { state: "CT", city: "Bridgeport" },
  { state: "DE", city: "Wilmington" },
  { state: "DE", city: "Dover" },
  { state: "DE", city: "Newark" },
  { state: "DE", city: "Middletown" },
  { state: "FL", city: "Miami" },
  { state: "FL", city: "Orlando" },
  { state: "FL", city: "Tampa" },
  { state: "FL", city: "Jacksonville" },
  { state: "GA", city: "Atlanta" },
  { state: "GA", city: "Savannah" },
  { state: "GA", city: "Augusta" },
  { state: "GA", city: "Columbus" },
  { state: "HI", city: "Honolulu" },
  { state: "HI", city: "Hilo" },
  { state: "HI", city: "Kailua" },
  { state: "HI", city: "Kapolei" },
  { state: "ID", city: "Boise" },
  { state: "ID", city: "Meridian" },
  { state: "ID", city: "Nampa" },
  { state: "ID", city: "Idaho Falls" },
  { state: "IL", city: "Chicago" },
  { state: "IL", city: "Aurora" },
  { state: "IL", city: "Naperville" },
  { state: "IL", city: "Joliet" },
  { state: "IN", city: "Indianapolis" },
  { state: "IN", city: "Fort Wayne" },
  { state: "IN", city: "Evansville" },
  { state: "IN", city: "South Bend" },
  { state: "IA", city: "Des Moines" },
  { state: "IA", city: "Cedar Rapids" },
  { state: "IA", city: "Davenport" },
  { state: "IA", city: "Sioux City" },
  { state: "KS", city: "Wichita" },
  { state: "KS", city: "Overland Park" },
  { state: "KS", city: "Kansas City" },
  { state: "KS", city: "Topeka" },
  { state: "KY", city: "Louisville" },
  { state: "KY", city: "Lexington" },
  { state: "KY", city: "Bowling Green" },
  { state: "KY", city: "Owensboro" },
  { state: "LA", city: "New Orleans" },
  { state: "LA", city: "Baton Rouge" },
  { state: "LA", city: "Shreveport" },
  { state: "LA", city: "Lafayette" },
  { state: "ME", city: "Portland" },
  { state: "ME", city: "Lewiston" },
  { state: "ME", city: "Bangor" },
  { state: "ME", city: "South Portland" },
  { state: "MD", city: "Baltimore" },
  { state: "MD", city: "Frederick" },
  { state: "MD", city: "Rockville" },
  { state: "MD", city: "Gaithersburg" },
  { state: "MA", city: "Boston" },
  { state: "MA", city: "Worcester" },
  { state: "MA", city: "Springfield" },
  { state: "MA", city: "Cambridge" },
  { state: "MI", city: "Detroit" },
  { state: "MI", city: "Grand Rapids" },
  { state: "MI", city: "Warren" },
  { state: "MI", city: "Sterling Heights" },
  { state: "MN", city: "Minneapolis" },
  { state: "MN", city: "Saint Paul" },
  { state: "MN", city: "Rochester" },
  { state: "MN", city: "Duluth" },
  { state: "MS", city: "Jackson" },
  { state: "MS", city: "Gulfport" },
  { state: "MS", city: "Southaven" },
  { state: "MS", city: "Hattiesburg" },
  { state: "MO", city: "Kansas City" },
  { state: "MO", city: "Saint Louis" },
  { state: "MO", city: "Springfield" },
  { state: "MO", city: "Columbia" },
  { state: "MT", city: "Billings" },
  { state: "MT", city: "Missoula" },
  { state: "MT", city: "Great Falls" },
  { state: "MT", city: "Bozeman" },
  { state: "NE", city: "Omaha" },
  { state: "NE", city: "Lincoln" },
  { state: "NE", city: "Bellevue" },
  { state: "NE", city: "Grand Island" },
  { state: "NV", city: "Las Vegas" },
  { state: "NV", city: "Henderson" },
  { state: "NV", city: "Reno" },
  { state: "NV", city: "North Las Vegas" },
  { state: "NH", city: "Manchester" },
  { state: "NH", city: "Nashua" },
  { state: "NH", city: "Concord" },
  { state: "NH", city: "Dover" },
  { state: "NJ", city: "Newark" },
  { state: "NJ", city: "Jersey City" },
  { state: "NJ", city: "Paterson" },
  { state: "NJ", city: "Elizabeth" },
  { state: "NM", city: "Albuquerque" },
  { state: "NM", city: "Las Cruces" },
  { state: "NM", city: "Rio Rancho" },
  { state: "NM", city: "Santa Fe" },
  { state: "NY", city: "New York City" },
  { state: "NY", city: "Buffalo" },
  { state: "NY", city: "Rochester" },
  { state: "NY", city: "Yonkers" },
  { state: "NC", city: "Charlotte" },
  { state: "NC", city: "Raleigh" },
  { state: "NC", city: "Greensboro" },
  { state: "NC", city: "Durham" },
  { state: "ND", city: "Fargo" },
  { state: "ND", city: "Bismarck" },
  { state: "ND", city: "Grand Forks" },
  { state: "ND", city: "Minot" },
  { state: "OH", city: "Columbus" },
  { state: "OH", city: "Cleveland" },
  { state: "OH", city: "Cincinnati" },
  { state: "OH", city: "Toledo" },
  { state: "OK", city: "Oklahoma City" },
  { state: "OK", city: "Tulsa" },
  { state: "OK", city: "Norman" },
  { state: "OK", city: "Broken Arrow" },
  { state: "OR", city: "Portland" },
  { state: "OR", city: "Salem" },
  { state: "OR", city: "Eugene" },
  { state: "OR", city: "Gresham" },
  { state: "PA", city: "Philadelphia" },
  { state: "PA", city: "Pittsburgh" },
  { state: "PA", city: "Allentown" },
  { state: "PA", city: "Erie" },
  { state: "RI", city: "Providence" },
  { state: "RI", city: "Warwick" },
  { state: "RI", city: "Cranston" },
  { state: "RI", city: "Pawtucket" },
  { state: "SC", city: "Charleston" },
  { state: "SC", city: "Columbia" },
  { state: "SC", city: "North Charleston" },
  { state: "SC", city: "Mount Pleasant" },
  { state: "SD", city: "Sioux Falls" },
  { state: "SD", city: "Rapid City" },
  { state: "SD", city: "Aberdeen" },
  { state: "SD", city: "Brookings" },
  { state: "TN", city: "Nashville" },
  { state: "TN", city: "Memphis" },
  { state: "TN", city: "Knoxville" },
  { state: "TN", city: "Chattanooga" },
  { state: "TX", city: "Houston" },
  { state: "TX", city: "San Antonio" },
  { state: "TX", city: "Dallas" },
  { state: "TX", city: "Austin" },
  { state: "UT", city: "Salt Lake City" },
  { state: "UT", city: "West Valley City" },
  { state: "UT", city: "Provo" },
  { state: "UT", city: "West Jordan" },
  { state: "VT", city: "Burlington" },
  { state: "VT", city: "South Burlington" },
  { state: "VT", city: "Rutland" },
  { state: "VT", city: "Barre" },
  { state: "VA", city: "Virginia Beach" },
  { state: "VA", city: "Norfolk" },
  { state: "VA", city: "Chesapeake" },
  { state: "VA", city: "Richmond" },
  { state: "WA", city: "Seattle" },
  { state: "WA", city: "Spokane" },
  { state: "WA", city: "Tacoma" },
  { state: "WA", city: "Vancouver" },
  { state: "WV", city: "Charleston" },
  { state: "WV", city: "Huntington" },
  { state: "WV", city: "Morgantown" },
  { state: "WV", city: "Parkersburg" },
  { state: "WI", city: "Milwaukee" },
  { state: "WI", city: "Madison" },
  { state: "WI", city: "Green Bay" },
  { state: "WI", city: "Kenosha" },
  { state: "WY", city: "Cheyenne" },
  { state: "WY", city: "Casper" },
  { state: "WY", city: "Laramie" },
  { state: "WY", city: "Gillette" }
]
