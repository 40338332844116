import { Box, Button, TextField } from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import NCSvgIcon from "./NCSvgIcon"
import { CalendarIcon, CloseIcon } from "../libs/icons"
import { useState } from "react"
import { formatIncidentDate } from "../libs/utils"

const NCCalendarButton = props => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [open, setOpen] = useState(false)

  const handleButtonClick = () => {
    setOpen(true)
  }

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{
          borderRadius: "50px"
        }}
        className={"rounded"}
        endIcon={
          <Box display="flex" alignItems={"flex-start"}>
            <NCSvgIcon
              width="16px"
              height="16px"
              component={CalendarIcon}
              className="svg-primary"
              inheritViewBox
            />
            {selectedDate && (
              <NCSvgIcon
                width="16px"
                height="16px"
                style={{
                  marginTop:"2px",
                  marginLeft:"2px"
                }}
                component={CloseIcon}
                inheritViewBox
                onClick={(e) => {
                  // eslint-disable-next-line no-console
                  console.log(e);
                  setSelectedDate(null)
                  props.selectedDate(null)
                  e?.stopPropagation();
                }}
              />
            )}
          </Box>
        }
        onClick={handleButtonClick}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
      >
        {selectedDate
          ? formatIncidentDate(selectedDate, "MM-DD-YYYY")
          : "Today"}
      </Button>
      <DatePicker
        open={open}
        onClose={() => setOpen(false)}
        style={{
          display: "none"
        }}
        value={selectedDate}
        onChange={newValue => {
          setSelectedDate(newValue)
          props.selectedDate(newValue)
        }}
        renderInput={params => <TextField {...params} />}
      />
    </div>
  )
}

export default NCCalendarButton
