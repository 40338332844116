import Navigation from "./Navigation"
import { DOWNLOAD_APP } from "./const"
import logo from "../../assets/images/logo.svg"
import appStore from "../../assets/icons/app-store.png"
import googlePlay from "../../assets/icons/google-play.png"
import { Box, Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {
    background: "linear-gradient(180deg, #10658E 0%, #1EAAC4 100%)",
    display: "grid",
    gridTemplateColumns: "12fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "3fr 9fr"
    }
  },
  icon: {
    marginBottom: "4rem",
    marginTop: "5rem"
  },
  content: {
    width: "100%",
    textAlign: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.up("lg")]: {
      width: "80%"
    }
  },
  header: {
    fontSize: "50px",
    color: "white",
    marginBottom: "2rem"
  },
  imgSize: {
    width: "102px",
    height: "88px"
  },
  description: {
    fontSize: "16px",
    color: "white",
    marginBottom: "2rem",
    textAlign: "left"
  },
  link: {
    cursor: "pointer"
  }
}))

const LandingPageHeader = () => {
  const cls = useStyles()

  return (
    <Box className={cls.container}>
      <Navigation currentPage={DOWNLOAD_APP} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={cls.content}
      >
        <Grid item className={cls.icon}>
          <img src={logo} className={cls.imgSize} />
        </Grid>
        <Grid item className={cls.header}>
          Simplifying Movement Management One Day at a Time!
        </Grid>
        <Grid item className={cls.description} id="downloadApp">
          Noah Cares is an intuitive app designed to streamline day-to-day
          movement operations with ease. Whether you're tracking deliveries,
          managing transportation logistics, or coordinating field services,
          Noah Cares simplifies the process, allowing you to stay organised and
          efficient. With user-friendly features and real-time updates, Noah
          Cares empowers businesses to optimise their operations and deliver
          exceptional service every step of the way.
        </Grid>
        <Grid item>
          <Grid container alignItems="center" style={{ marginBottom: "2rem" }}>
            <Grid item>
              <img src={appStore} className={cls.link} />
            </Grid>
            <Grid>
              <img src={googlePlay} className={cls.link} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LandingPageHeader
