import { usePubNub } from "pubnub-react"
import { useGetAllUsers } from "../../services/queries"
import { useEffect, useRef, useState } from "react"
import { ChannelList, Chat } from "@pubnub/react-chat-components"
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCInput from "../../shared/NCInput"
import { SearchIcon } from "../../libs/icons"
import NCLoader from "../../shared/NCLoader"
import {
  createChannelId,
  fetchChannels,
  getMembership,
  getUnreadMessageCounts,
  timeSince
} from "../../libs/pubnub"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import ChatProvider from "./ChatProvider"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  messageSection: {
    background: "rgba(243, 244, 246, 1)",
    display: "block"
  },
  search: {
    width: "98%"
  },
  userListContainer: {
    marginTop: "10px",
    color: "rgba(16, 101, 142, 1)"
  },
  userItem: {
    borderBottom: "1px solid rgba(217, 217, 217, 1)",
    cursor: "pointer",
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  h100: {
    height: "calc(100% - 30px)"
  },
  listContainer: {
    height: "calc(100vh - 142px)",
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - (${theme.mixins.toolbar.minHeight}px + 152px))`
    }
  },
  userText: {
    fontSize: "18px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 700
  },
  selectedUserItem: {
    borderBottom: "1px solid rgba(217, 217, 217, 1)",
    cursor: "pointer",
    paddingTop: "16px",
    paddingBottom: "16px",
    backgroundColor: "rgba(209, 252, 245, 1)"
  },
  count: {
    width: "16px",
    height: "16px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "rgba(30, 170, 196, 1)",
    color: "white",
    fontSize: "16px",
    fontWeight: 700
  }
}))

const UserChannelList = () => {
  const cls = useStyles()

  const { t } = useTranslation()

  const pubnub = usePubNub()
  const uuid = pubnub.getUUID()
  const currentChannel = "Default"
  const theme = "light"

  const timeoutRef = useRef()
  const [search, setSearch] = useState(null)
  const [searchText, setSearchText] = useState(null)
  const { data: userList, isLoading } = useGetAllUsers(search)

  const [channels, setChannels] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const navigate = useNavigate()
  const location = useLocation()

  const mobileTheme = useTheme()
  // eslint-disable-next-line no-unused-vars
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const chatPath = "/direct-message/chat"
  const userListPath = "/direct-message"

  const handleSelectUser = user => {
    setSelectedUser(user)
    navigate(chatPath, {
      replace: location.pathname === chatPath
    })
  }

  const fetchDriverChannels = async userList => {
    const users = userList?.data?.data?.results
    const channelData = await fetchChannels(pubnub, uuid)
    const channelIds = Object.keys(channelData)
    const memberships = await getMembership(pubnub)
    const timeToken = channelIds?.map(id => {
      return (
        memberships?.data?.find(mem => mem?.channel?.id === id)?.custom
          ?.lastReadTimetoken || 0
      )
    })
    const messageCount = await getUnreadMessageCounts(
      pubnub,
      channelIds,
      timeToken
    )
    const channels =
      users?.map(user => {
        const id = createChannelId(uuid, user.pubnub_profile.uuid)
        const customMessage =
          channelData[id]?.custom?.[user.pubnub_profile.uuid]
        const customMessaageObj = customMessage && JSON.parse(customMessage)
        return {
          name: user?.name || "",
          id: id,
          uuid: user.pubnub_profile.uuid,
          count: messageCount[id],
          avatar: user?.avatar || "",
          updated: customMessaageObj?.updatedAt,
          lastMessage:
            typeof customMessaageObj?.lastMessage === "object"
              ? customMessaageObj?.lastMessage?.text
              : customMessaageObj?.lastMessage
        }
      }) || []
    setChannels([...channels])
  }

  const getSubscribedUserList = async () => {
    const channelData = await fetchChannels(pubnub, uuid)
    const channelIds = Object.keys(channelData)
    const memberships = await getMembership(pubnub)
    const timeToken = channelIds?.map(id => {
      return (
        memberships?.data?.find(mem => mem?.channel?.id === id)?.custom
          ?.lastReadTimetoken || 0
      )
    })
    const messageCount = await getUnreadMessageCounts(
      pubnub,
      channelIds,
      timeToken
    )
    const channelList = channelIds
      ?.map(id => {
        const data = channelData[id]
        const keysInCustom = Object.keys(data.custom)
        const otherUserKey = keysInCustom.filter(key => key !== uuid)?.[0]
        const otherUserData =
          data.custom?.[otherUserKey] && JSON.parse(data.custom[otherUserKey])
        if (!otherUserData) {
          return null
        }
        return {
          name: otherUserData.name,
          uuid: otherUserKey,
          id: id,
          count: messageCount[id],
          updated: data?.updated,
          avatar: otherUserData.avatar,
          lastMessage: otherUserData?.lastMessage
        }
      })
      .filter(data => !!data)
    setChannels(channelList || [])
  }

  const sortByDate = (a, b) => {
    return new Date(b.updated) - new Date(a.updated)
  }

  const fetchUserList = search => {
    !search && getSubscribedUserList()
  }

  useEffect(() => {
    fetchUserList(search)
  }, [search])

  useEffect(() => {
    if (userList && search) {
      fetchDriverChannels(userList)
    }
  }, [search, userList])

  const handleSearch = e => {
    const value = e.target.value
    setSearchText(value)
  }

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setSearch(searchText)
      setSelectedUser(null)
    }, 500)
  }, [searchText])

  useEffect(() => {
    if (channels.length !== 0) {
      pubnub?.unsubscribeAll()
      pubnub.addListener({
        message: function () {
          fetchUserList()
        }
      })

      pubnub.subscribe({
        channels: channels?.map(data => data.id)
      })
    }
  }, [channels.length])

  const renderChatItem = props => {
    const time = props.updated ? timeSince(new Date(props.updated)) : null
    const lastMessage =
      typeof props?.lastMessage?.text === "object"
        ? props.lastMessage?.text?.text?.slice(0, 24) + " " + (time || "")
        : typeof props?.lastMessage?.text === "string"
        ? props.lastMessage?.text?.slice(0, 24) + " " + (time || "") ?? ""
        : ""

    return (
      <List className={cls.userListContainer} disablePadding key={props.id}>
        <ListItem
          className={
            selectedUser?.id !== props.id ? cls.userItem : cls.selectedUserItem
          }
          onClick={() => {
            handleSelectUser(props)
          }}
        >
          <ListItemAvatar>
            <Avatar src={props.avatar}></Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={props.name}
            primaryTypographyProps={{
              component: "span", // Setting the component as "div"
              style: {
                fontSize: "18px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 700
              }
            }}
            secondary={lastMessage}
          />
          {props?.count > 0 && (
            <ListItemSecondaryAction>
              <span className={cls.count}>{props.count}</span>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
    )
  }

  return (
    <Grid container className={cls.h100}>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12}>
              <NCText variant={"h4"}>{t("chat.heading")}</NCText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {(isSmallScreen ? location.pathname === userListPath : true) && (
        <Grid item xs={12} md={4}>
          <Grid mb={2}>
            <Box mb={2}>
              <Grid container>
                <Grid item xs={12}>
                  <NCInput
                    value={searchText}
                    startIcon={SearchIcon}
                    onChange={handleSearch}
                    className={["rounded", cls.search].join(" ")}
                    placeholder={t("common.search_here")}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={2}>
              <Grid container>
                <Grid item xs={12} className={cls.listContainer}>
                  <Chat currentChannel={currentChannel} theme={theme}>
                    <ChannelList
                      sort={sortByDate}
                      channels={channels || []}
                      channelRenderer={renderChatItem}
                    />
                  </Chat>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {(isSmallScreen ? location.pathname === chatPath : true) && (
        <Grid
          item
          xs={12}
          md={8}
          className={[cls.messageSection, cls.h100].join(" ")}
        >
          {selectedUser && (
            <ChatProvider
              selectedUser={selectedUser}
              fetchUserList={fetchUserList}
            >
              <Outlet />
            </ChatProvider>
          )}
        </Grid>
      )}
      {isLoading && <NCLoader open={true} />}
    </Grid>
  )
}

export default UserChannelList
