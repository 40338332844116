import { Box } from "@material-ui/core"
import { formatDate } from "../../libs/utils"
import NCTable from "../../shared/table/NCTable"
import NCText from "../../shared/NCText"
import NCTableStatus from "../../shared/table/NCTableStatus"
import { DOCUMENT_VERIFICATION_STATUS } from "../../libs/config"
// import Filter from "../../shared/table/Filter"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const DriverDocuments = ({ data = [] }) => {
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] = useState([])
  const getDocName = file => {
    const fileParts = file.split("/")
    return fileParts[fileParts.length - 1]
  }
  const getDocFiles = documents => {
    return (
      <Box display={"flex"} flexDirection={"column"}>
        {documents.map(doc => {
          return (
            <NCText
              onClick={() => window.open(doc.file)}
              className="clickable-text"
            >
              {doc.file ? getDocName(doc.file) : doc.file}
            </NCText>
          )
        })}
      </Box>
    )
  }
  // function SelectColumnFilter({ column: { filterValue, setFilter } }) {
  //   return (
  //     <Filter
  //       options={Object.keys(DOCUMENT_VERIFICATION_STATUS).map(key => {
  //         return {
  //           key,
  //           name: DOCUMENT_VERIFICATION_STATUS[key]
  //         }
  //       })}
  //       filters={filterValue}
  //       setFilters={setFilter}
  //     />
  //   )
  // }
  const columns = [
    {
      Header: t("drivers.doc_type"),
      id: "document_type_descr",
      accessor: cell => cell.document_type_descr,
      disableSortBy: true
    },
    {
      Header: t("drivers.certificate"),
      accessor: cell => getDocFiles(cell.document_files),
      disableSortBy: true
    },
    {
      Header: t("drivers.date_uploaded"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value),
      disableSortBy: true,
    },
    {
      Header: t("drivers.exp_date"),
      id: "expiry",
      accessor: cell => cell.expiry_date,
      Cell: ({ value }) => formatDate(value),
      disableSortBy: true,
    },
    {
      Header:t("common.status"),
      id: "status",
      accessor: cell => (
        <NCTableStatus status={DOCUMENT_VERIFICATION_STATUS[cell.status]} />
      ),
      // defaultCanFilter: true,
      // Filter: SelectColumnFilter,
      disableSortBy: true
    }
  ]
  return (
    <NCTable
      columns={columns}
      data={data}
      hidePagination={true}
      filter={statusFilter}
      setFilter={setStatusFilter}
    />
  )
}

export default DriverDocuments
