import React from "react"
import { Tooltip, withStyles } from "@material-ui/core"

const TextOnlyTooltip = withStyles({
  tooltip: {
    fontSize: "12px",
    color: "white",
    backgroundColor: "black "
  }
})(Tooltip)

const NCTooltip = ({ title, ...props }) => {
  return (
    <TextOnlyTooltip
      arrow
      title={title}
      placement="top"
      PopperProps={{ style: { marginBottom: -10 } }}
    >
      {props.children}
    </TextOnlyTooltip>
  )
}

export default NCTooltip
