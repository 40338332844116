import { Box } from "@material-ui/core"
import { useEffect, useState } from "react"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete"
import { useTranslation } from "react-i18next"

const NCAddress = props => {
  const [value, setValue] = useState()

  const { t } = useTranslation();

  useEffect(() => {
    const initialState = props.value ? { label: props.value } : null
    setValue(initialState)
  }, [props.value])

  // eslint-disable-next-line no-unused-vars
  const getValue = data => {
    try {
      let parsedAddress = JSON.parse(data)
      return parsedAddress
    } catch (e) {
      return value
    }
  }

  return (
    <Box
      mt={"6px"}
      mb={"20px"}
      position={"relative"}
      className={props.error ? "error-address" : ""}
    >
      <GooglePlacesAutocomplete
        apiKey="AIzaSyAbXiODER5EIwsPZQINXIlh3aJPl5Ow_F4"
        placeholder={"155165"}
        selectProps={{
          value: value,
          placeholder: t("select_here"),
          onChange: async val => {
            // eslint-disable-next-line no-console
            console.log(val)

            const placeId = val.value.place_id
            const geoCode = await geocodeByPlaceId(placeId)
            const latitude = geoCode[0].geometry.location.lat()
            const longitude = geoCode[0].geometry.location.lng()

            const updatedvalue = {
              name: val.value.description,
              placeId: placeId,
              geometry: {
                latitude: latitude,
                longitude: longitude
              }
            }
            // eslint-disable-next-line no-console
            console.log(val)
            setValue(val)
            props.onChange(updatedvalue)
          },
          onFocus: () => {
            setValue(null)
            props.onChange(null)
          },
          styles: {
            placeholder: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            }),
            input: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            }),
            option: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            }),
            singleValue: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            })
          }
        }}
      />
      {props.error && (
        <span
          style={{
            position: "absolute",
            bottom: "-20px",
            color: "#f44336",
            margin: "0 14px",
            fontSize: "0.75rem",
            marginTop: "3px",
            textAlign: "left",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1.66"
          }}
        >
          {props.error}
        </span>
      )}
    </Box>
  )
}

export default NCAddress
