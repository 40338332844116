import { useState } from "react"
import { Box, InputAdornment, makeStyles, TextField } from "@material-ui/core"
import NCSvgIcon from "./NCSvgIcon"
import { ReactComponent as PasswordVisibleIcon } from "../assets/icons/passwordVisible.svg"
import { ReactComponent as PasswordHiddenIcon } from "../assets/icons/passwordHidden.svg"
import { ReactComponent as LockIcon } from "../assets/icons/lock.svg"
const useStyles = makeStyles(theme => ({
  input: {
    // padding: "0px 8px",
    fontSize: "14px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D5DB"
      },
      "&:hover fieldset": {
        borderColor: "#1EAAC4"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1EAAC4",
        borderWidth: "1px"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px"
    }
  },
  endIcon: {
    "&:hover": {
      "& path": {
        stroke: theme.palette.primary.main
      }
    }
  }
}))

const NCPasswordInput = props => {
  const cls = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(show => !show)
  return (
    <TextField
      {...props}
      error={props.error || false}
      inputProps={{
        min: props.min,
        max: props.max
      }}
      type={showPassword ? "text" : "password"}
      InputProps={{
        className: cls.input,
        readOnly: props.readOnly ? props.readOnly : false,
        startAdornment: (
          <InputAdornment position="start">
            <Box fontSize={"24px"}>
              <NCSvgIcon
                component={LockIcon}
                inheritViewBox
                className={cls.noFill}
              />
            </Box>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Box fontSize={"24px"}>
              <NCSvgIcon
                component={
                  showPassword ? PasswordHiddenIcon : PasswordVisibleIcon
                }
                inheritViewBox
                className={cls.endIcon}
                onClick={handleClickShowPassword}
                cursor="pointer"
              />
            </Box>
          </InputAdornment>
        )
      }}
      SelectProps={{
        native: true
      }}
      className={props.className}
      variant="outlined"
    />
  )
}

export default NCPasswordInput
