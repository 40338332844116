import { Box, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import LogoTextIcon from "../../assets/icons/menu_icons/logotext.svg"
import NCText from "../../shared/NCText"
import { useNavigate } from "react-router"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  box: {
    background: "linear-gradient(174.61deg, #1EAAC4 0%, #10658E 104.65%)"
  },
  nav: {
    color: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer"
  },
  subHeading: {
    fontSize: "20px"
  },
  content: {
    marginLeft: "80px",
    marginRight: "80px"
  }
}))
const PrivacyLayout = props => {
  const nav = useNavigate()
  const cls = useStyles()

  const {t} = useTranslation();

  return (
    <>
      <Box
        className={cls.box}
        px={2}
        py={2}
        mb={2}
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={"0px"}
        padding={"10px"}
      >
        <Grid container xs={12}>
          <Grid item>
            <img src={LogoTextIcon} style={{ width: "150px" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <NCText className={cls.nav} onClick={() => nav("/privacy-policy")}>
              {t("login.privacy_policy")}
            </NCText>
          </Grid>
          <Grid item>
            <NCText
              className={cls.nav}
              onClick={() => nav("/terms-conditions")}
            >
              {t("login.terms_conditions")}
            </NCText>
          </Grid>
        </Grid>
      </Box>
      {props.title && (
        <Box
          bgcolor={"#F3FAFC"}
          px={2}
          py={2}
          mb={2}
          display={"flex"}
          justifyContent={"center"}
          padding={"8px"}
        >
          <NCText className={cls.subHeading}>{props.title}</NCText>
        </Box>
      )}
      <div className={cls.content}>{props.children}</div>
    </>
  )
}

export default PrivacyLayout
