import React, { useState } from "react"
import NCTable from "../../shared/table/NCTable"
import NCText from "../../shared/NCText"
import { Grid, Box, IconButton, useTheme, useMediaQuery } from "@material-ui/core"
import { useGetClients, useGetClientsFile } from "../../services/queries"
import {
  downloadFileFromResponse,
  formatDate,
  loggedInUser
} from "../../libs/utils"
import {
  ACTIVITY_STATUS,
  GENDER,
  SEARCH_CONFIG,
  TABLE_CONFIG
} from "../../libs/config"
import NCInput from "../../shared/NCInput"
import NCButton from "../../shared/NCButton"
import ExportMenu from "../../components/ExportMenu"
import {
  AddIcon,
  SearchIcon,
  DetailIcon,
  ReviveIcon,
  DeleteIcon,
  OnHoldIcon,
  OnReleaseIcon
} from "../../libs/icons"
import Filter from "../../shared/table/Filter"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { useNavigate } from "react-router-dom"
import NCLoader from "../../shared/NCLoader"
import NCModal from "../../shared/NCModal"
import NCTableStatus from "../../shared/table/NCTableStatus"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import {
  useActivateClient,
  useDeleteClient,
  usePutClientOnHold,
  useReleaseClientOnHold
} from "../../services/mutations"
import useDebounce from "../../libs/hooks/Debounce"
import NotificationAlert from "../../components/notification/NotificationAlert"
import getQueryParams from "../../libs/hooks/useQuery"
import { useTranslation } from "react-i18next"
import { GoogleAnalyticsData, gaActions } from "../../libs/const"

const ClientList = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])
  const [downloadType, setDownloadType] = useState()
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState()
  let queryParams = getQueryParams();
  const nav = useNavigate()
  const user = loggedInUser()
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const deleteClientApi = useDeleteClient()
  const activateClientApi = useActivateClient()
  const putOnHoldApi = usePutClientOnHold()
  const releaseHoldApi = useReleaseClientOnHold()
  const queryClient = useQueryClient()

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const { isLoading, data } = useGetClients(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null,
      subadmin: queryParams.get("subadmin"),
      admin: queryParams.get("admin")
    },

  )
  const { refetch } = useGetClientsFile(
    downloadType,
    query,
    { ordering },
    {
      enabled: false
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: clientData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  function SelectColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <Filter
        options={[
          { key: "A", name: t("client.active") },
          { key: "I", name: t("client.inactive") },
          { key: "O", name: t("client.onhold") }
        ]}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }
  const deleteClient = clientId => {
    deleteClientApi.mutate(clientId, {
      onSuccess: () => {
        toast.success(t("client.delete"))
        queryClient.invalidateQueries(["getClientList"])
        setShowDeleteModel(false)
      }
    })
  }
  const handleDeleteClick = cell => {
    if (cell.status === "I") {
      //reactivate
      activateClientApi.mutate(cell.id, {
        onSuccess: () => {
          toast.success(t("client.activated"))
          queryClient.refetchQueries(["getClientList"])
          setShowDeleteModel(false)
        }
      })
    } else {
      setSelectedClientId(cell.id)
      setShowDeleteModel(true)
    }
  }
  const handleHold = cell => {
    putOnHoldApi.mutate(cell.id, {
      onSuccess: () => {
        toast.success(t("client.hold"))
        queryClient.refetchQueries(["getClientList"])
        queryClient.refetchQueries(["getNotificationData"])
        queryClient.refetchQueries(["getNotificationSummary"])
      }
    })
  }
  const releaseHold = cell => {
    releaseHoldApi.mutate(cell.id, {
      onSuccess: () => {
        toast.success(t("client.released"))
        queryClient.refetchQueries(["getClientList"])
      }
    })
  }

  const getDetailsByClientName = cell => {
    //   console.log(cell)
    return (
      <div onClick={() => nav(`/clients/${cell.id}`)}>
        <NCText className={"clickable-text"}>{cell?.name}</NCText>
      </div>
    )
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <Box display={"flex"} alignItems={"center"}>
          {(user.user_type === 1 || user.user_type === 2) ?
            cell.status === "A" ? (
              <IconButton onClick={() => {
                handleHold(cell)
                GoogleAnalyticsData(gaActions.client.category,gaActions.client.action.hold)
              }}>
                <OnHoldIcon />
              </IconButton>
            ) : cell.status === "O" ? (
              <IconButton onClick={() => {
                releaseHold(cell)
                GoogleAnalyticsData(gaActions.client.category,gaActions.client.action.release)
                }}>
                <OnReleaseIcon />
              </IconButton>
            ) : null : null}
          {(user.user_type === 1 || user.user_type === 2) ? <IconButton onClick={() => handleDeleteClick(cell)}>
            {cell.status === "I" ? <ReviveIcon /> : <DeleteIcon />}
          </IconButton> : null}
          <IconButton onClick={() => nav(`/clients/${cell.id}`)}>
            <DetailIcon />
          </IconButton>
        </Box>
      </div>
    )
  }
  const columns = [
    {
      Header: t("list.id"),
      id: "id",
      accessor: cell => cell.id,
      Cell: ({ value }) => (
        <NCText
          className={"clickable-text"}
          onClick={() => nav(`/clients/${value}`)}
        >
          {value}
        </NCText>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.client_name"),
      id: "name",
      accessor: cell => getDetailsByClientName(cell)
    },
    {
      Header: t("list.email"),
      accessor: "email"
    },
    {
      Header: t("list.phone_number"),
      accessor: "phone"
    },
    {
      Header: t("list.gender"),
      id: "gender",
      accessor: cell => GENDER[cell.gender]
    },
    {
      Header: t("list.date_created"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value)
    },
    {
      Header: t("list.status"),
      id: "status", // required for multiple filter
      accessor: cell => <NCTableStatus status={t("client."+ACTIVITY_STATUS[cell.status])} />,
      defaultCanFilter: true,
      Filter: SelectColumnFilter,
      // filter: "includes",
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]
  const downloadFile = async type => {
    await setDownloadType(type)
    const data = await refetch()
    const contentDispositionHeaders =
      data?.data?.headers["content-disposition"].split("=")
    downloadFileFromResponse(
      data?.data?.data,
      contentDispositionHeaders[contentDispositionHeaders.length - 1]
        .replaceAll(/[/"]/g, "")
        .trim()
    )
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={4}>
              <Grid item xs={12} md={4}>
              {!isSmallScreen ? (
                <NCText variant={"h4"}>{t("client.heading")}</NCText>
              ) : (
                <Grid container justifyContent="space-between">
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NCText variant={"h4"}>{t("client.heading")}</NCText>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                </Grid>
              )}
            </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} justifyContent={isSmallScreen ? "flex-start" : "flex-end"}>
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
                <Grid item>
                  <ExportMenu itemAction={item => downloadFile(item.key)} />
                </Grid>
                {user.user_type === 1 || user.user_type === 2 ? (
                  <Grid item style={{ paddingRight: "0px" }}>
                    <NCButton
                      variant="contained"
                      color="primary"
                      endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
                      onClick={() => nav("/clients/add-client")}
                    >
                      {t("client.add_client")}
                    </NCButton>
                  </Grid>
                ) : null}
                {!isSmallScreen && (
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <NCTable
          columns={columns}
          data={clientData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
        {isLoading ||
          deleteClientApi.isLoading ||
          activateClientApi.isLoading ||
          putOnHoldApi.isLoading ||
          (releaseHoldApi.isLoading && <NCLoader open={true} />)}
      </Grid>
      <NCModal
        open={showDeleteModel}
        title={t("common.delete")}
        text={t("client.delete_confirmation")}
        onClose={() => setShowDeleteModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => deleteClient(selectedClientId)}
            style={{ width: "auto" }}
          >
            {t("common.yes")}
          </NCButton>
        }
      />
    </Grid>
  )
}
export default ClientList
