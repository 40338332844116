import { Box, Grid, makeStyles } from "@material-ui/core"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import PhoneNumber from "../../components/PhoneNumber"
import NCButton from "../../shared/NCButton"
import { useAddDispatcher, useUpdateDispatcher } from "../../services/mutations"
import {
  useGetDispatcher,
  useGetDispatcherAuditLog
} from "../../services/queries"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import NCLoader from "../../shared/NCLoader"
import NCAuditButton from "../../shared/NCAuditButton"
import { loggedInUser } from "../../libs/utils"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  hideButton: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  showButton: {
    display:"none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent:"flex-end"
    }
  }
}))

const AddDispatcher = () => {
  const cls = useStyles();
  const { t } = useTranslation();
  const { dispatcherId } = useParams()
  const edit = !window.location.href.includes("add")
  const user = loggedInUser()
  const addDispatcher = edit ? useUpdateDispatcher() : useAddDispatcher()
  const { isLoading, data: dispatcherResult } = edit
    ? useGetDispatcher(dispatcherId)
    : {}
  const dispatcherData = dispatcherResult?.data?.data || {}
  const queryClient = useQueryClient()
  const nav = useNavigate()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: dispatcherData?.first_name || "",
      last_name: dispatcherData?.last_name || "",
      email: dispatcherData?.email || "",
      phone: dispatcherData?.phone || ""
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("admin.first_name_required")),
      last_name: Yup.string().required(t("admin.last_name_required")),
      phone: Yup.string().required("admin.phone_required"),
      email: Yup.string()
        .email(t("admin.invalid_email"))
        .required(t("admin.email_required"))
    }),
    onSubmit: (values, { resetForm }) => {
      let updatedValues = { ...values }
      if (dispatcherId) {
        updatedValues.id = dispatcherId
      }
      addDispatcher.mutate(updatedValues, {
        onSuccess: async () => {
          toast.success(
            dispatcherId ? t("dispatcher.updated") :
            t("dispatcher.updated")
          )
          resetForm({ values: "" })
          queryClient.invalidateQueries(["getDispatcherList"])
          queryClient.invalidateQueries(["getDispatcherAuditLog"])
          nav("/dispatchers")
        }
      })
    }
  })

  const { data } = useGetDispatcherAuditLog(
    {
      dispatcher_id: dispatcherId
    },
    { enabled: dispatcherId ? true : false }
  )

  const auditData = data?.data?.data?.results || []

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2} display={"flex"}>
          <NCText variant={"h4"} style={{ marginRight: "10px" }}>
            {edit ? t("dispatcher.update_dispatcher") : t("dispatcher.add_dispatcher")}
          </NCText>
        </Box>
        <Box display={"flex"}>
          {user.user_type === 1 && dispatcherId ? (
            <Box className={cls.hideButton}>
              <NCAuditButton data={auditData} isLoading={isLoading} />
            </Box>
          ) : null}
          <Box marginLeft={"5px"}>
            <NotificationAlert />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        className={cls.showButton}
        xs={12}>
        {user.user_type === 1 && dispatcherId ? (
          <NCAuditButton data={auditData} isLoading={isLoading} />
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={12}>
                <FormSection title= {edit ? t("dispatcher.update_dispatcher") : t("dispatcher.add_dispatcher")}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FormField
                        label={t("common.first_name")}
                        name={"first_name"}
                        required={true}
                        error={formik?.errors?.first_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormField
                        label={t("common.last_name")}
                        name={"last_name"}
                        required={true}
                        error={formik?.errors?.last_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormField
                        label={t("common.email_address")}
                        name={"email"}
                        required={true}
                        disabled={edit}
                        error={formik?.errors?.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormField
                        as={PhoneNumber}
                        label={t("common.phone_number")}
                        name={"phone"}
                        onChange={e => formik.setFieldValue("phone", `+${e}`)}
                        required={true}
                        error={formik?.errors?.phone}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
              </Grid>
            </Grid>
            <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <NCButton
                      onClick={() => nav("/dispatchers")}
                      variant="outlined"
                      color="primary"
                    >
                      {t("common.cancel")}
                    </NCButton>
                  </Grid>
                  <Grid item>
                    <NCButton variant="contained" color="primary" type="submit">
                      {edit ? t("common.update") : t("common.save")}
                    </NCButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {addDispatcher.isLoading && <NCLoader open={true} />}
            {isLoading && <NCLoader open={true} />}
          </Form>
        </FormikProvider>
      </Grid>
    </Grid>
  )
}
export default AddDispatcher
