import { Grid, Box } from "@material-ui/core"
import NCText from "../shared/NCText"
import TopNavBar from "./TopNavBar"
import NotificationAlert from "./notification/NotificationAlert"

const FormTemplate = props => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          bgcolor={"white"}
          top={0}
          width={"-webkit-fill-available"}
          zIndex={0}
        >
          <Grid container>
            <Grid item xs={6}>
              <Box mb={2}>
                <NCText variant={"h4"}>{props.title}</NCText>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <NotificationAlert />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <TopNavBar />
          </Box>
        </Box>
        <Box zIndex={0} mb={"60px"}>
          {props.children}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          bgcolor={"#F3FAFC"}
          //   mx={-3}
          //   ml={-4}
          p={"10px 30px 10px 10px"}
          position={"fixed"}
          bottom={0}
          width={"-webkit-fill-available"}
          m={"0 0 0 -30px"}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
            >
              {props.footerButtons}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default FormTemplate
