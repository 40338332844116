import { Box, Grid, makeStyles } from "@material-ui/core"
import React, { useCallback, useEffect, useRef, useState } from "react"
import useWebSocket from "react-use-websocket"
import Map from "../../components/map/Map"
import {
  DropLocationIcon,
  EndMarkerIcon,
  PickupLocationIcon,
  StartMarkerIcon
} from "../../libs/icons"
import FormField from "../../components/FormField"
import { FormikProvider, useFormik } from "formik"
import { useParams } from "react-router-dom"
import { rideStatus, socketUrl } from "../../libs/const"
import { formatAMPM } from "../../libs/utils"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  container: {
    border: "1px solid #F3F4F6"
  },
  headingContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "10px"
    }
  },
  heading: {
    fontSize: "26px",
    padding: "10px 0px"
  },
  requestId: {
    fontWeight: 700
  },
  status: {
    fontSize: "18px",
    fontWeight: 600
  },
  statusText: {
    color: "#7D7D7D",
    fontSize: "14px"
  },
  boxContainer: {
    border: "1px solid #D1D5DB"
  },
  circle: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 10px"
  },
  yColor: {
    backgroundColor: "#F9B50B"
  },
  gColor: {
    backgroundColor: "#4CAF50"
  },
  rightContainerTitle: {
    color: "#2E3B36",
    fontSize: "24px",
    padding: "15px 20px",
    borderBottom: "1px solid #E3E3E3"
  }
}))

const MapViewListingDetails = () => {
  const cls = useStyles()
  const {t} = useTranslation();
  const { id } = useParams()
  const intervalRef = useRef()
  const seconds = 1000 * 10
  const [center, setCenter] = useState(null)
  const [dropAddress, setDropAddress] = useState(null)
  const [pickupAddress, setPickUpAddress] = useState(null)
  const { sendJsonMessage, lastJsonMessage, getWebSocket } = useWebSocket(
    socketUrl,
    {
      onClose: () => {
        // eslint-disable-next-line no-console
        console.log("Connection is closed")
      }
    }
  )

  const sendMessage = () => {
    sendJsonMessage({
      type: "get.trip_details",
      data: {
        id: id
      }
    })
  }

  useEffect(() => {
    sendMessage()
    intervalRef.current = setInterval(() => {
      sendMessage()
    }, seconds)
    return () => {
      handleCloseConnection()
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      pickupAddress: "",
      estimatedPickUpTime: ""
    }
  })

  const handleCloseConnection = useCallback(() => {
    const ws = getWebSocket()
    if (ws) {
      ws.close()
    }
  }, [getWebSocket])

  const status = lastJsonMessage?.data?.status

  useEffect(() => {
    if (lastJsonMessage?.data?.pickup_address) {
      if(status === 5 || status === 7){
        setCenter({
          lat: lastJsonMessage?.data?.location_data?.driver?.latitude,
          lng: lastJsonMessage?.data?.location_data?.driver?.longitude
        })
        setPickUpAddress({
          lat: lastJsonMessage?.data?.location_data?.driver?.latitude,
          lng: lastJsonMessage?.data?.location_data?.driver?.longitude
        })
        setDropAddress({
          lat:
            status === 5
              ? lastJsonMessage?.data?.pickup_address?.latitude
              : lastJsonMessage?.data?.drop_off_address?.latitude,
          lng:
            status === 5
              ? lastJsonMessage?.data?.pickup_address?.longitude
              : lastJsonMessage?.data?.drop_off_address?.longitude
        })  
      }else{
        setCenter({
          lat: lastJsonMessage?.data?.pickup_address?.latitude,
          lng: lastJsonMessage?.data?.pickup_address?.longitude
        })
        setPickUpAddress({
          lat: lastJsonMessage?.data?.pickup_address?.latitude,
          lng: lastJsonMessage?.data?.pickup_address?.longitude
        })
        setDropAddress({
          lat: lastJsonMessage?.data?.drop_off_address?.latitude,
          lng: lastJsonMessage?.data?.drop_off_address?.longitude
        })
      }
      formik.setFieldValue(
        "pickupAddress",
        lastJsonMessage?.data?.pickup_address?.address
      )
      formik.setFieldValue(
        "estimatedPickUpTime",
        formatAMPM(lastJsonMessage?.data?.timing_details?.pickup_time || lastJsonMessage?.data?.pickup_time)
      )
      formik.setFieldValue(
        "dropoffAddress",
        lastJsonMessage?.data?.drop_off_address?.address
      )
      formik.setFieldValue(
        "estimatedDropOffTime",
        formatAMPM(lastJsonMessage?.data?.timing_details?.dropped_time)
      )
    }
  }, [lastJsonMessage])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>{t("map.map_view")}</NCText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12} className={cls.container}>
        <Box mb={2} p={1}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className={cls.headingContainer}
          >
            <Box
              className={cls.heading}
              display={"flex"}
              gridGap={"10px"}
              alignItems={"center"}
            >
              <span><NCText>{t("map.request")}</NCText></span>
              <span className={cls.requestId}>{lastJsonMessage?.data?.id}</span>
              <span
                className={[
                  cls.circle,
                  status === 5 ? cls.yColor : cls.gColor
                ].join(" ")}
              ></span>
              <span className={cls.status}>{t("status_o."+rideStatus[status])}</span>
            </Box>
            <Box display={"flex"} alignItems={"center"} gridGap={"10px"}>
              {status === 5 ? (
                <>
                  <PickupLocationIcon />
                  <NCText><span>{t("map.client_location")}</span></NCText>
                  <DropLocationIcon />
                  <NCText><span>{t("map.driver_location")}</span></NCText>
                </>
              ) : status === 7 ? (
                <>
                  <StartMarkerIcon />
                  <NCText><span>{t("map.driver_location")}</span></NCText>
                  <EndMarkerIcon />
                  <NCText><span>{t("map.drop_location")}</span></NCText>
                </>
              ): (
                <>
                  <StartMarkerIcon />
                  <NCText><span>{t("map.pickup_location")}</span></NCText>
                  <EndMarkerIcon />
                  <NCText><span>{t("map.drop_location")}</span></NCText>
                </>
              )}
            </Box>
          </Box>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={6}>
              <Box height={"300px"} p={1} className={cls.boxContainer}>
                {pickupAddress && (
                  <Map
                    startAddress={pickupAddress}
                    dropAddress={dropAddress}
                    showClientToDriver={status === 5}
                    showStartToEnd={status !== 5}
                    center={center}
                    zoomControl={true}
                    zoom={15}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box height={"100%"} className={cls.boxContainer} ml={1} p={0}>
                <FormikProvider value={formik}>
                 
                    <NCText> <div className={cls.rightContainerTitle}>{t("map.ride_details")}</div></NCText>
                  <Box p={1}>
                    <FormField
                      disabled={true}
                      label={t("map.pickup_address")}
                      name={"pickupAddress"}
                    />
                  </Box>
                  <Box p={1}>
                    <FormField
                      disabled={true}
                      label={
                        status === 5 ? t("map.pickup_est_time") : t("map.pickup_time")
                      }
                      name={"estimatedPickUpTime"}
                    />
                  </Box>
                  {status !== 5 && (
                    <>
                      <Box p={1}>
                        <FormField
                          disabled={true}
                          label={t("map.drop_address")}
                          name={"dropoffAddress"}
                        />
                      </Box>
                      <Box p={1}>
                        <FormField
                          disabled={true}
                          label={t("map.drop_est_time")}
                          name={"estimatedDropOffTime"}
                        />
                      </Box>
                    </>
                  )}
                </FormikProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MapViewListingDetails
