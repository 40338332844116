import i18next from "i18next"

export const validationMessages = {
	required: i18next.t("validate_message.required"),
	maxLength: (x) => `${i18next.t("validate_message.should_not_more")} ${x} characters`,
	minLength: (x) => `${i18next.t("validate_message.should_not_less")} ${x} characters`,
	invalid: i18next.t("validate_message.invalid"),
	noSpecialChars: i18next.t("validate_message.noSpecialChars"),
	oneSpecialChar: i18next.t("validate_message.oneSpecialChar"),
	oneUpperChar: i18next.t("validate_message.oneUpperChar"),
	oneLowerChar: i18next.t("validate_message.oneLowerChar")
};
