import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { Grid, makeStyles } from "@material-ui/core"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import FormField from "../../components/FormField"
import NCAutocomplete from "../../shared/NCAutocomplete"
import { INSPECTION_CATEGORY, INSPECTION_TYPE } from "../../libs/const"
import { useCreateInspectionQuestion, useUpdateInspectionQuestion } from "../../services/mutations"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  modal: {
    width: "200px",
    minWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      maxWidth: "80%",
      minWidth: "auto"
    }
  },
}))

const AddQuestionModal = ({ modalState = false, handleClose, data = {} }) => {
  const cls = useStyles()

  const {t} = useTranslation();

  const createQuestionMutation = useCreateInspectionQuestion();

  const updateQuestionMutation = useUpdateInspectionQuestion();

  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      type: data?.type || "",
      category: data?.category || "",
      sequence: data?.sequence || ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("inspection.error.question_required")),
      type: Yup.string().required(t("inspection.error.type_required")),
      category: Yup.string().required(t("inspection.error.category_required")),
      sequence: Yup.number().min(1,t("inspection.error.seq_min")).required(t("inspection.error.seq_required"))
    }),
    enableReinitialize: true,
    onSubmit(values) {
      if(!data?.id){
        createQuestionMutation.mutate(values, {
          onSuccess: () => {
            toast.success(t("inspection.success"))
            handleClose(true);
          }
        })  
      }else{
        updateQuestionMutation.mutate({id:data.id,...values}, {
          onSuccess: () => {
            toast.success(t("inspection.update"))
            handleClose(true);
          }
        })  
      }
    }
  })

  const handleSequence = e => {
    let inputValue = e.target.value

    inputValue = inputValue.replace(/[^0-9]/g, "")

    // eslint-disable-next-line no-console
    console.log(inputValue)

    formik.setFieldValue("sequence", inputValue)
  }

  const requestModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              label={t("inspection.question")}
              name={"name"}
              required={true}
              error={formik?.errors?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              as={NCAutocomplete}
              options={INSPECTION_TYPE}
              label={t("inspection.type")}
              name={"type"}
              onChange={v => {
                formik.setFieldValue("type", v)
              }}
              required={true}
              error={formik?.errors?.type}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              as={NCAutocomplete}
              options={INSPECTION_CATEGORY}
              label={t("inspection.category")}
              name={"category"}
              onChange={v => {
                formik.setFieldValue("category", v)
              }}
              required={true}
              error={formik?.errors?.category}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={t("inspection.sequence")}
              name={"sequence"}
              type="number"
              min={1}
              onChange={handleSequence}
              required={true}
              error={formik?.errors?.sequence}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )

  return (
    <NCModal
      open={modalState}
      title={data?.id ? t("inspection.edit_question_head") : t("inspection.add_question_head")}
      text={requestModal}
      className={cls.modal}
      onClose={() => handleClose()}
      cancelButton={<></>}
      submitButton={
        <>
          <Grid item xs={12}>
            <NCButton
              variant="contained"
              color="primary"
              disableElevation
              onClick={formik.handleSubmit}
              style={{ width: "100%" }}
            >
              {data?.id ? t("common.update") : t("inspection.add_question")}
            </NCButton>
          </Grid>
          <Grid item xs={12} style={{marginTop:"5px"}}>
            <NCButton
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={handleClose}
              style={{ width: "100%" }}
            >
              {t("common.cancel")}
            </NCButton>
          </Grid>
        </>
      }
    />
  )
}
export default AddQuestionModal
