import { Grid } from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useUpdateEmergency } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import FormField from "../FormField"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import PhoneNumber from "../PhoneNumber"
import { useTranslation } from "react-i18next"

const EditEmergencyModal = ({
  clientId,
  modalState = false,
  handleClose,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation();
  const updateEmergencyApi = useUpdateEmergency()
  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      phone: data.phone || "",
      email: data.email || ""
    },
    validationSchema: Yup.object().shape({}),
    enableReinitialize: true,
    onSubmit(values) {
      updateEmergencyApi.mutate(
        { ...values, id: clientId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getClientDetail"])
            queryClient.invalidateQueries(["getClientAuditLog"])
            toast.success(t("client.emg_edit"))
            handleClose()
          }
        }
      )
    }
  })
  const emergencyContactModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              label={t("common.name")}
              name={"name"}
              required={true}
              error={formik?.errors?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={t("client.email")}
              name={"email"}
              required={true}
              error={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: "30px" }}>
            <FormField
              as={PhoneNumber}
              label={t("common.phone_number")}
              name={"phone"}
              onChange={e => formik.setFieldValue("phone", `+${e}`)}
              required={true}
              error={formik?.errors?.phone}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
  return (
    <>
      <NCModal
        open={modalState}
        title={t("client.edit_emg_contact")}
        text={emergencyContactModal}
        onClose={() => handleClose()}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            {t("common.update")}
          </NCButton>
        }
      />
      {updateEmergencyApi.isLoading && <NCLoader open={true} />}
    </>
  )
}

export default EditEmergencyModal
