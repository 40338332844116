import { Box, InputAdornment, makeStyles, TextField } from "@material-ui/core"
import NCSvgIcon from "./NCSvgIcon"
import { ReactComponent as SendIcon } from "../assets/icons/sendIcon.svg"
const useStyles = makeStyles(theme => ({
  input: {
    // padding: "0px 8px",
    fontSize: "14px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D5DB"
      },
      "&:hover fieldset": {
        borderColor: "#1EAAC4"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1EAAC4",
        borderWidth: "1px"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 14px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px"
      }
    }
  },
  endIcon: {
    "&:hover": {
      "& path": {
        stroke: theme.palette.primary.main
      }
    }
  }
}))

const NCSendMessage = props => {
  const cls = useStyles()
  return (
    <TextField
      {...props}
      type={"textarea"}
      InputProps={{
        className: cls.input,
        endAdornment: (
          <InputAdornment position="end">
            <Box fontSize={"24px"}>
              <NCSvgIcon
                component={SendIcon}
                onClick={props.onSend}
                inheritViewBox
                className={cls.endIcon}
                cursor="pointer"
              />
            </Box>
          </InputAdornment>
        )
      }}
      SelectProps={{
        native: true
      }}
      className={props.className}
      variant="outlined"
    />
  )
}

export default NCSendMessage
