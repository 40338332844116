import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { IntroIcon, LogoutIcon, MoreVertIcon, SettingIcon } from "../libs/icons"
import NCSvgIcon from "../shared/NCSvgIcon"
import { useNavigate } from "react-router-dom"
import NCModal from "../shared/NCModal"
import NCButton from "../shared/NCButton"
import Introduction from "../containers/introduction"
import { useGetIntro } from "../services/queries"
import { useTranslation } from "react-i18next"

const ITEM_HEIGHT = 48

const UserMenu = ({ icon }) => {
  const nav = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [showLogoutModel, setShowLogoutModel] = React.useState(false)
  const [showIntroModel, setShowIntroModel] = React.useState(false)
  const open = Boolean(anchorEl)

  const { data } = useGetIntro()

  const {t} = useTranslation();

  const options = [
    {
      icon: SettingIcon,
      name: t("user_menu.settings"),
      to: () => nav("/setting")
    },
    {
      icon: IntroIcon,
      name: t("user_menu.introduction"),
      to: () => setShowIntroModel(true)
    },
    {
      icon: LogoutIcon,
      name: t("user_menu.log_out"),
      to: () => setShowLogoutModel(true) //nav("/logout")
    }
  ]

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemClick = option => {
    handleClose()
    option.to()
  }

  return (
    <div>
      {icon ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {icon}
        </IconButton>
      ) : (
        <NCSvgIcon
          component={MoreVertIcon}
          inheritViewBox={false}
          onClick={handleClick}
          className="cursor-pointer"
        />
      )}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch"
          }
        }}
      >
        {options.map(option => (
          <MenuItem key={option.name} onClick={() => handleItemClick(option)}>
            <NCSvgIcon
              onClick={() => {}}
              component={option.icon}
              inheritViewBox={false}
            />
            {option.name}
          </MenuItem>
        ))}
      </Menu>
      <Introduction
        open={showIntroModel}
        onClose={() => setShowIntroModel(false)}
        data={data}
      />

      <NCModal
        open={showLogoutModel}
        title={t("user_menu.log_out_text")}
        text={t("user_menu.log_out_confirmation")}
        onClose={() => setShowLogoutModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => nav("/logout")}
            style={{ width: "auto" }}
          >
            {t("common.yes")}
          </NCButton>
        }
      />
    </div>
  )
}

export default UserMenu
