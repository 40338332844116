import React, { useState } from "react"
import NCTable from "../../shared/table/NCTable"
import NCText from "../../shared/NCText"
import { Grid, Box, IconButton, useTheme, useMediaQuery } from "@material-ui/core"
import { useGetAdmin, useGetAdminFile } from "../../services/queries"
import { downloadFileFromResponse, formatDate } from "../../libs/utils"
import NCInput from "../../shared/NCInput"
import ExportMenu from "../../components/ExportMenu"
import { AddIcon, DetailIcon, SearchIcon } from "../../libs/icons"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import NCSvgIcon from "../../shared/NCSvgIcon"
import NCButton from "../../shared/NCButton"
import { useNavigate } from "react-router-dom"
import NCLoader from "../../shared/NCLoader"
import useDebounce from "../../libs/hooks/Debounce"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useTranslation } from "react-i18next"

const AdminList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])
  const [downloadType, setDownloadType] = useState()
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const nav = useNavigate()

  const { isLoading, data } = useGetAdmin(page, debouncedSearchTerm, pageSize, {
    ordering,
    status:
      statusFilter && statusFilter.length
        ? statusFilter[0].value.toString()
        : null
  })
  const { refetch } = useGetAdminFile(
    downloadType,
    query,
    { ordering },
    {
      enabled: false
    }
  )
  const {
    next,
    previous,
    total_pages,
    results: requestData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const getAdminByName = cell => {
    return (
      <div onClick={() => nav(`/admins/${cell.id}`)}>
        <NCText className={"clickable-text"}>{cell?.company_name}</NCText>
      </div>
    )
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton onClick={() => nav(`/admins/${cell.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }

  const getAdminId = value => {
    const selectedAdmin = value.data.find(item => item.id === value.value)
    nav(`/admins/${selectedAdmin.id}`)
  }
  const columns = [
    {
      Header: t("dist_id"),
      id: "id",
      accessor: cell => cell.id,
      Cell: value => (
        <NCText className={"clickable-text"} onClick={() => getAdminId(value)}>
          {value?.value}
        </NCText>
      ),
      disableSortBy: true
    },
    {
      Header: t("dist_name"),
      id: "company_name",
      accessor: cell => getAdminByName(cell)
    },
    {
      Header: t("dist_email"),
      accessor: "company_email_address"
    },

    {
      Header: t("date_created"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value)
    },
    {
      Header: t("tol_clients"),
      accessor: "total_clients",
      Cell: ({ value, row }) =>
        value ? (
          <NCText
            className={"clickable-text"}
            onClick={() => nav(`/clients/?admin=${row?.original?.id}`)}
          >
            {value}
          </NCText>
        ) : (
          value
        )
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]
  const downloadFile = async type => {
    await setDownloadType(type)
    const data = await refetch()
    const contentDispositionHeaders =
      data?.data?.headers["content-disposition"].split("=")
    downloadFileFromResponse(
      data?.data?.data,
      contentDispositionHeaders[contentDispositionHeaders.length - 1]
        .replaceAll(/[/"]/g, "")
        .trim()
    )
  }

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={4}>
              {!isSmallScreen ? (
                <NCText variant={"h4"}>{t("admins")}</NCText>
              ) : (
                <Grid container justifyContent="space-between">
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NCText variant={"h4"}>{t("admins")}</NCText>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} justifyContent={isSmallScreen ? "flex-start" : "flex-end"}>
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
                <Grid item>
                  <ExportMenu itemAction={item => downloadFile(item.key)} />
                </Grid>
                <Grid item style={{ paddingRight: "0px" }}>
                  <NCButton
                    variant="contained"
                    color="primary"
                    onClick={() => nav("/admins/add-admin")}
                    endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
                  >
                    {t("container.admin.add_admin")}
                  </NCButton>
                </Grid>
                {!isSmallScreen && (
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <NCTable
          columns={columns}
          data={requestData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
    </Grid>
  )
}
export default AdminList
