import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector/cjs"
import { initReactI18next } from "react-i18next"

i18next.use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  returnObject: true,
  resources: {
    en: {
      translation: require("./locales/en/translation.json")
    },
    es: {
      translation: require("./locales/es/translation.json")
    },
    fr: {
      translation: require("./locales/fr/translation.json")
    },
    ar: {
      translation: require("./locales/ar/translation.json")
    },
    am: {
      translation: require("./locales/am/translation.json")
    },
    ti: {
      translation: require("./locales/ti/translation.json")
    },
    om: {
      translation: require("./locales/om/translation.json")
    }
  },
})
