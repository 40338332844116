import { Grid, Box, makeStyles, Button } from "@material-ui/core"
import NCText from "../../shared/NCText"
import FormSection from "../../components/FormSection"
import { useGetClientDetail } from "../../services/queries"
import { GENDER } from "../../libs/config"
import {
  formatDate,
  getAddressValue,
  getAge,
  loggedInUser,
  saveQR
} from "../../libs/utils"
import GuardianList from "./GuardianList"
import NCLoader from "../../shared/NCLoader"
import { useRef, useState } from "react"
import EditClientModal from "./EditClientModal"
import EditEmergencyModal from "./EditEmergencyModal"
import EditSubAdminModal from "./EditSubAdminModal"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { DriverEditIcon } from "../../libs/icons"
import NCTooltip from "../../shared/NCTooltip"
import { useReactToPrint } from "react-to-print"
import { QRCodeCanvas } from "qrcode.react"
import NCButton from "../../shared/NCButton"
import { useTranslation } from "react-i18next"
import { STORAGE } from "../../libs/storage"

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "20px",
    fontSize: "12px",
    width: "180px"
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between"
  },
  subSectionTitle: {
    color: "#9CA3AF",
    fontSize: "12px"
  },
  "& .MuiBox-root": {
    display: "flex"
  }
}))
const SelectedClient = ({ clientId }) => {
  const cls = useStyles()
  const { t } = useTranslation();
  const user = loggedInUser()
  const [clientModal, setClientModal] = useState(false)
  const [emergencyModal, setEmergencyModal] = useState(false)
  const [subAdminModal, setSubAdminModal] = useState(false)
  const { isLoading, data } = useGetClientDetail(clientId)
  const result = data?.data?.data || {}

  const qrRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => qrRef.current
  })

  // const { isLoading, data: subAdminData } = useGetSubAdminDetail(
  //   result.subadmin,
  //   {
  //     enabled: user.user_type === 3 ? false : result.subadmin ? true : false
  //   }
  // )
  // let subAdmin
  // let adminName
  // if (user.user_type === 3) {
  //   subAdmin = user.profile_attributes || {}
  //   adminName = subAdmin.admin?.company_name || ""
  // } else {
  let subAdmin = result?.client_subadmin_info || {} //|| subAdminData?.data?.data || {}
  let adminName = result?.client_subadmin_info?.admin?.company_name || "" //|| subAdmin?.admin?.company_name || ""
  //}

  const renderRowItem = (key, value) => {
    return (
      <Grid item md={4}>
        <NCText>
          <Box fontSize={"12px"} color={"#9CA3AF"}>
            {key}
          </Box>
        </NCText>
        <NCText
          variant="body2"
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {value}
        </NCText>
      </Grid>
    )
  }

  const clientTitle = (
    <Grid item className={cls.titleSection}>
      {t("client.user_details")}
      {user.user_type === 1 || user.user_type === 2 ? (
        <Button
          onClick={() => setClientModal(true)}
          className={cls.button}
          variant="contained"
          color="primary"
        >
          {t("client.edit_client")}
        </Button>
      ) : null}
    </Grid>
  )
  const subAdminTitle = (
    <Grid item className={cls.titleSection}>
      {t("client.subadmin_details")}
      {user.user_type === 1 || user.user_type === 2 ? (
        <Button
          onClick={() => setSubAdminModal(true)}
          className={cls.button}
          variant="contained"
          color="primary"
          startIcon={<NCSvgIcon component={DriverEditIcon} inheritViewBox />}
        >
          {t("client.subadmin")}
        </Button>
      ) : null}
    </Grid>
  )
  const emergencyTitle = (
    <Grid item className={cls.titleSection}>
      {t("client.emg_contact_details")}
      {user.user_type === 1 || user.user_type === 2 ? (
        <Button
          onClick={() => setEmergencyModal(true)}
          className={cls.button}
          variant="contained"
          color="primary"
        >
          {t("client.edit_emg_contact")}
        </Button>
      ) : null}
    </Grid>
  )

  const renderPrintButton = (
    <NCButton
      variant="contained"
      color="primary"
      disableElevation
      onClick={handlePrint}
      style={{ width: "auto", marginRight: "12px" }}
    >
      {t("common.print_qr")}
    </NCButton>
  )

  const saveQRButton = (
    <NCButton
      variant="contained"
      color="primary"
      disableElevation
      onClick={saveQR.bind(this, qrRef)}
      style={{ width: "auto", marginRight: "12px" }}
    >
      {t("common.save_qr")}
    </NCButton>
  )

  const isDispatcher = STORAGE.isDispatcher();

  return (
    <Grid container spacing={2}>
      {(result?.uuid && !isDispatcher) && (
        <Grid item xs={12}>
          <Box display={"flex"} marginBottom={"20px"}>
            <Box
              ref={qrRef}
              display={"flex"}
              marginTop={"20px"}
              justifyContent={"center"}
            >
              <QRCodeCanvas
                id="qrcode-canvas"
                style={{ height: "auto", width: "120px" }}
                value={JSON.stringify({ id: result?.uuid })}
                level="H"
              />
            </Box>
            <Box
              marginTop={"20px"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
              ml={4}
            >
              {renderPrintButton}
              {saveQRButton}
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12}>
        <FormSection title={clientTitle}>
          <Box mb={2}>
            <Grid container spacing={2}>
              {renderRowItem(t("common.first_name"), result.first_name)}
              {renderRowItem(t("common.middle_name"), result.middle_name)}
              {renderRowItem(t("common.last_name"), result.last_name)}
              {/* <NCTooltip title={result.email || ""}> */}
              {result.email ? (
                <NCTooltip title={result.email || ""}>
                  {renderRowItem(t("common.email_address"), result.email)}
                </NCTooltip>
              ) : null}
              {/* </NCTooltip> */}
              {renderRowItem(t("common.phone_number"), result.phone)}
              {renderRowItem(t("common.gender"), GENDER[result.gender])}

              {renderRowItem(t("common.dob"), formatDate(result.dob))}
              {renderRowItem(t("common.age"), getAge(result.dob))}
              {renderRowItem(t("common.address"), getAddressValue(result.address))}

              {renderRowItem(t("common.student_id"), getAddressValue(result.identifier))}
            </Grid>
          </Box>
        </FormSection>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormSection title={subAdminTitle}>
          <Grid container>
            {renderRowItem(t("common.name"), subAdmin.name)}
            {renderRowItem(t("client.admin"), adminName)}
          </Grid>
        </FormSection>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormSection title={emergencyTitle}>
          <Grid container>
            {renderRowItem(
              t("client.emg_contact_name"),
              result?.emergency_contact?.name
            )}
            <NCTooltip title={result?.emergency_contact?.email || ""}>
              {renderRowItem(
                t("client.emg_contact_email"),
                result?.emergency_contact?.email
              )}
            </NCTooltip>
            {renderRowItem(t("common.phone_number"), result?.emergency_contact?.phone)}
          </Grid>
        </FormSection>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <GuardianList clientId={clientId} />
      </Grid>
      {isLoading && <NCLoader open={true} />}
      <EditClientModal
        clientId={clientId}
        modalState={clientModal}
        handleClose={() => setClientModal(false)}
        data={result}
      />
      <EditEmergencyModal
        clientId={clientId}
        modalState={emergencyModal}
        handleClose={() => setEmergencyModal(false)}
        data={result?.emergency_contact}
      />
      {subAdmin.id && (user.user_type === 1 || user.user_type === 2) ? (
        <EditSubAdminModal
          clientId={clientId}
          modalState={subAdminModal}
          handleClose={() => setSubAdminModal(false)}
          data={{
            subAdmin: subAdmin.id,
            name: subAdmin.name,
            adminName,
            clientId
          }}
        />
      ) : null}
    </Grid>
  )
}
export default SelectedClient
