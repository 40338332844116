import ReactGA from "react-ga4"
import { STORAGE } from "./storage"

const sockConnectionURL =
  window.location.hostname === "localhost"
    ? "ws://noah-cares-38566-staging.botics.co"
    : window.location.hostname === "noah-cares-38566-staging.botics.co"
    ? "wss://noah-cares-38566-staging.botics.co"
    : "wss://" + window.location.host

export const socketUrl = `${sockConnectionURL}/ws/trips/?token=${STORAGE.getToken()}`

export const pubnubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
export const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

export const googleAPiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const rideStatus = {
  1: "Created",
  2: "Waiting For Driver Assign",
  3: "Waiting for Approval",
  4: "Accepted By Driver",
  5: "Waiting for the driver", //# Waiting for the driver
  6: "Arrived",
  7: "On route", //  # On route (In Progress)
  8: "Completed",
  9: "Cancelled By Driver",
  10: "Cancelled By Client",
  11: "Expired",
  12: "Cancelled Before Driver Assign"
}

export const gaActions = {
  client: {
    category: "CLIENT",
    action: {
      add: "ADD",
      delete: "DELETE",
      hold: "HOLD",
      release: "RELEASE",
      view: "VIEW",
      approve: "Approve"
    }
  }
}

export const GoogleAnalyticsData = (category, action) => {
  ReactGA.event({
    category: category,
    action: action
  })
}

export const INSPECTION_TYPE = [
  {
    id:1,
    name:"PRE"
  },
  {
    id:2,
    name:"POST"
  },
]

export const INSPECTION_CATEGORY = [
  {
    id:1,
    name:"Front of Vehicle"
  },
  {
    id:2,
    name:"Engine Compartment"
  },
  {
    id:3,
    name:"Tire"
  },
  {
    id:4,
    name:"Exterior Lights"
  },
  {
    id:5,
    name:"Driver Fuel Area"
  },
  {
    id:6,
    name:"Rear of Vehicle"
  },
  {
    id:7,
    name:"In Cab"
  },
  {
    id:8,
    name:"Emergency Equipment"
  },
  {
    id:9,
    name:"Others"
  },
]

export const STOPS_CALL_INTERVAL = 1000 * 30;

export const STOP_STATUS = {
  1: "PENDING",
  2: "STARTED",
  3: "ARRIVED",
  4: "COMPLETED",
  5: "NO SHOW",
  6: "CANCELLED"
}

export const STOP_TYPE = {
  1: "PICKUP",
  2: "DROP OFF"
}