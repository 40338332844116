import * as React from "react"
import {
  Button,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  makeStyles
} from "@material-ui/core"
import { FilterIcon } from "../../libs/icons"
import NCSvgIcon from "../NCSvgIcon"
import NCCheckbox from "../NCCheckbox"

const useStyles = makeStyles({
  filterButton: {
    padding: "0",
    minWidth: "auto",
    marginTop: "-14px",
    backgroundColor: "transparent !important"
  }
})
const Filter = ({ options, filters = [], setFilters }) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const cls = useStyles()

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const updateFilter = key => {
    if (filters && filters.length) {
      if (filters.includes(key)) {
        let modifiedValues = [...filters].filter(item => item != key)
        setFilters(modifiedValues)
      } else {
        setFilters([...filters, key])
      }
    } else {
      setFilters([key])
    }
  }
  return (
    <div>
      <Button
        variant="link"
        color="primary"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={cls.filterButton}
      >
        <NCSvgIcon
          component={FilterIcon}
          className="sorting-icon"
          inheritViewBox
        />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper style={{ zIndex: 1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {options.map(option => (
                    <MenuItem onClick={() => updateFilter(option.key)}>
                      <NCCheckbox
                        color={"primary"}
                        style={{ padding: "0" }}
                        checked={filters.includes(option.key)}
                        onChange={() => updateFilter(option.key)}
                      />
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
export default Filter
