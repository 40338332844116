import {
  Box,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCTable from "../../shared/table/NCTable"
import { useState } from "react"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import {
  getTwillioRecording,
  useGetAllPhoneCallRecording
} from "../../services/queries"
import { PlayDisabledIcon, PlayIcon, SearchIcon } from "../../libs/icons"
import NCLoader from "../../shared/NCLoader"
import NCInput from "../../shared/NCInput"
import useDebounce from "../../libs/hooks/Debounce"
import { formatAMPM, formatDate, formatDuration } from "../../libs/utils"
import AudioPlayer from "./audio-player"

const PhoneCalls = () => {
  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [query, setQuery] = useState("")
  const [ordering, setOrdering] = useState(null)
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const [recordingUrl, setRecordingUrl] = useState(null)

  const [loadingRecording, setLoadingRecording] = useState(false)

  const { isLoading, data } = useGetAllPhoneCallRecording(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: callRecording
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <Box display={"flex"} alignItems={"center"}>
          <IconButton
            style={{ cursor: "pointer" }}
            disabled={!cell.recording_url}
            onClick={async () => {
                try{
                    setLoadingRecording(true)
                    const response = await getTwillioRecording(cell.recording_url)
                    setLoadingRecording(false)
                    const url = URL.createObjectURL(response.data)
                    setRecordingUrl(url)      
                }catch(err){
                    // eslint-disable-next-line no-console
                    console.log(err);
                    setLoadingRecording(false);
                }
            }}
          >
            {cell.recording_url ? <PlayIcon /> : <PlayDisabledIcon />}
          </IconButton>
        </Box>
      </div>
    )
  }

  const columns = [
    {
      Header: "From",
      id: "from",
      accessor: cell => cell.caller?.name || cell?.caller?.id
    },
    {
      Header: "to",
      id: "to",
      accessor: cell => cell.receiver.name
    },
    {
      Header: "Status",
      id: "status",
      accessor: cell => cell.status,
      disableSortBy: true
    },
    {
      Header: "Started At",
      style: { width: "200px", maxWidth: "200px" },
      accessor: cell =>
        cell?.start_time
          ? formatDate(cell?.start_time) +
            " - " +
            formatAMPM(cell?.start_time).toUpperCase()
          : "-",
      disableSortBy: true
    },
    {
      Header: "Ended At",
      style: { width: "200px", maxWidth: "200px" },
      accessor: cell =>
        cell?.end_time
          ? formatDate(cell?.end_time) +
            " - " +
            formatAMPM(cell?.end_time).toUpperCase()
          : "-",
      disableSortBy: true
    },
    {
      Header: "Duration",
      accessor: cell => cell.duration ? formatDuration(cell.duration)  : "00:00:00",
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>Phone Calls</NCText>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              style={{
                marginTop: isSmallScreen ? "10px" : "0px"
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={"Search here"}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
                {/* <Grid item style={{ paddingLeft: "0" }}>
                      <NotificationAlert />
                    </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container 
        // style={{ position: "relative" }}
        >
          <Grid item xs={12}>
            <Box 
            // style={{ position: "sticky", top: "40px", zIndex: "999" }}
            >
              <div style={{ padding: "10px 10px", marginBottom:"10px" }}>
                {recordingUrl && <AudioPlayer audioUrl={recordingUrl} />}
              </div>
            </Box>
            <NCTable
              columns={columns}
              data={callRecording || []}
              page={page}
              onNext={onNext}
              onPrev={onPrev}
              pageCount={total_pages}
              gotoPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              sorting={ordering}
              setSorting={setOrdering}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
        {(isLoading || loadingRecording) && <NCLoader open={true} />}
      </Grid>
    </Grid>
  )
}

export default PhoneCalls
