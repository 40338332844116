import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { Box } from "@material-ui/core"
const PhoneNumber = props => {
  return (
    <Box position={"relative"}>
      <PhoneInput
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        country={"us"}
        inputClass={props.error ? "error" : ""}
        buttonClass={props.error ? "error" : ""}
        inputProps={{
          helperText: props.error
        }}
      />
      {props.error && (
        <span
          style={{
            // position: "absolute",
            // bottom: "-20px",
            color: "#f44336",
            margin: "0 14px",
            fontSize: "0.75rem",
            marginTop: "3px",
            textAlign: "left",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1.66"
          }}
        >
          {props.error}
        </span>
      )}
    </Box>
  )
}

export default PhoneNumber
