import { Switch, withStyles } from "@material-ui/core"

const AntSwitch = withStyles(theme => ({
  root: {
    width: 34,
    height: 18,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 1,
    color: "#fff",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: "none"
  },
  track: {
    border: "1px solid #D1D5DB",
    borderRadius: 16 / 2,
    height: 16,
    opacity: 1,
    backgroundColor: "#D1D5DB"
  },
  checked: {}
}))(Switch)

const NCSwitch = props => {
  return <AntSwitch {...props} />
}

export default NCSwitch
