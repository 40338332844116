/* eslint-disable no-console */
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"

const usePageTracking = () => {
  const location = useLocation()
  const [startTime, setStartTime] = useState(new Date().getTime())

  const setTitle = (path) => {
    const initialText = "Noah Cares - "
    if(path.includes("/overview")){
        document.title = initialText + "Requests"
    }else if(path.includes("/clients")){
        document.title = initialText + "Clients"
    }else if(path.includes("/admins")){
        document.title = initialText + "Admin"
    }else if(path.includes("/subadmins")){
        document.title = initialText + "Subadmin"
    }else if(path.includes("/dispatchers")){
        document.title = initialText + "Dispatchers"
    }else if(path.includes("/drivers")){
        document.title = initialText + "Drivers"
    }else if(path.includes("/aidmonitor")){
        document.title = initialText + "Aid Monitor"
    }else if(path.includes("/map-view-listing")){
        document.title = initialText + "Map"
    }else if(path.includes("/vehicles")){
        document.title = initialText + "Vehicles"
    }else if(path.includes("/training")){
        document.title = initialText + "Training"
    }else if(path.includes("/direct-message")){
        document.title = initialText + "Chat"
    }else if(path.includes("/phonecalls")){
        document.title = initialText + "Phone Calls"
    }else if(path.includes("/analytics")){
        document.title = initialText + "Analytics"
    }else if(path.includes("/users")){
        document.title = initialText + "Users"
    }else if(path.includes("/incidents")){
        document.title = initialText + "Incidents"
    }else if(path.includes("/feedback")){
        document.title = initialText + "Feedback"
    }else if(path.includes("/setting")){
        document.title = initialText + "Setting"
    }else if(path.includes("/login")){
        document.title = initialText + "Login"
    }else if(path.includes("/resetpassword")){
        document.title = initialText + "Reset Password"
    }else if(path.includes("/privacy-policy")){
        document.title = initialText + "Privacy"
    }else if(path.includes("/terms-conditions")){
        document.title = initialText + "Terms Conditions"
    }else{
        document.title = "Noah Cares"
    }
  }

  useEffect(() => {
    setTitle(location.pathname)
    const pagePath = location.pathname + location.search
    ReactGA.send({
      hitType: "pageview",
      page: pagePath
    })

    console.log(`${pagePath} Loaded`)

    const handlePageChange = () => {
      const endTime = new Date().getTime()
      const timeSpent = (endTime - startTime) / 1000 // in seconds

      ReactGA.send({
        hitType: "timing",
        timingCategory: "Time Spent",
        timingVar: pagePath,
        timingValue: timeSpent
      })

      // Update start time for the new page
      setStartTime(new Date().getTime())
      console.log(`${pagePath} Ended`)
    }
    return () => {
      handlePageChange()
    }
  }, [location])
}

export default usePageTracking
