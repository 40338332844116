import AboutUs from "./AboutUs"
import ContactUs from "./ContactUs"
import FAQ from "./FAQ"
import Footer from "./Footer"
import LandingPageHeader from "./LandingPageHeader"
import { useGetAboutUs, useGetFAQ } from "../../services/queries"
import NCLoader from "../../shared/NCLoader"

const LandingPage = () => {
  const { isLoading: faqLoading, data: faq } = useGetFAQ()
  const { isLoading: aboutUsLoading, data: aboutUs } = useGetAboutUs()

  const aboutUsData = aboutUs?.data?.data

  return (
    <div className="landing-page-container" style={{fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}>
      <LandingPageHeader />
      <AboutUs
        description={aboutUsData?.description}
        mission={aboutUsData?.mission}
        values={aboutUsData?.values}
        vision={aboutUsData?.vision}
      />
      <ContactUs />
      <FAQ faq={faq?.data} />
      <Footer />
      <NCLoader open={faqLoading || aboutUsLoading} />
    </div>
  )
}

export default LandingPage
