import { Box, Button, Grid, Modal, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import NCInput from "../../shared/NCInput"
import NCButton from "../../shared/NCButton"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 450,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      width: "100%",
      minWidth: "auto"
    },
    // width: 500,
    backgroundColor: "white",
    boxShadow: 24,
    padding: "20px 30px 20px 30px",
    borderRadius: "10px",
    outline: "none",
    maxHeight: "80%",
    overflow: "auto"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px",
    minHeight: "70px"
  },
  submit: {
    marginTop: "20px"
  },
  cancel: {
    color: "#E22134",
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#2E3B36",
    fontSize: "32px",
    fontWeight: "600",
    fontFamily: "Open Sans, sans-serif"
  }
}))

const EditInvoice = props => {
  const { invoice } = props
  const { t } = useTranslation();
  const cls = useStyles()
  const [invoiceData, setInvoiceData] = useState([])

  useEffect(() => {
    if (invoice) setInvoiceData(invoice)
  }, [invoice])

  const handleChange = (index, value) => {
    const invoiceDataCopy = [...invoiceData]
    if (isNaN(value) || value === "") {
      invoiceDataCopy[index].price = 0
    } else {
      invoiceDataCopy[index].price = parseInt(value)
    }
    // eslint-disable-next-line no-unused-vars
    setInvoiceData(prevData => invoiceDataCopy)
  }

  const handleSubmit = e => {
    e.preventDefault()
    // Handle form submission here
    props.onSubmit(invoiceData)
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box className={`${cls.root} ${props.className}`}>
        <div className="modal">
          <div className="modal-content">
            <Box className={cls.title}>{t("reports.edit_invoice")}</Box>
            <form onSubmit={handleSubmit}>
              {invoice?.map((item, index) => (
                <Grid container spacing={2} key={index} className={cls.row}>
                  <Box>{item.name}</Box>
                  <NCInput
                    label={t("common.value_in_dollar")}
                    value={item?.price}
                    onChange={e => {
                      handleChange(index, e.target.value)
                    }}
                    placeholder={t("common.enter_value")}
                  />
                </Grid>
              ))}
              <Box style={{ justifyContent: "center", alignItems: "center" }}>
                <NCButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={cls.submit}
                >
                  {t("common.save")}
                </NCButton>
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    className={`${cls.submit} ${cls.cancel}`}
                    style={{ alignSelf: "center" }}
                    onClick={props.onClose}
                  >
                    {t("common.cancel")}
                  </Button>
                </Box>
              </Box>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default EditInvoice
