import { Box, Grid } from "@material-ui/core"
import NCText from "../../shared/NCText"
import NotificationItem from "./NotificationItem"
import { useState } from "react"
import { useGetNotification } from "../../services/queries"
import { TABLE_CONFIG } from "../../libs/config"
import NCTable from "../../shared/table/NCTable"
import CancelledPopup from "./CancelledPopup"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useTranslation } from "react-i18next"

const DispatcherNotificationList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [modal, showModal] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState()
  const { isLoading, data } = useGetNotification(page, pageSize, {
    category: "CANCEL_BY_DRIVER"
  })
  const { next, previous, total_pages, results = [] } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const columns = [
    {
      Header: "",
      id: "notification",
      disableSortBy: true,
      accessor: cell => (
        <div
          onClick={() => {
            if (!cell?.is_seen) {
              setSelectedNotification(cell)
              showModal(true)
            }
          }}
        >
          <NotificationItem detail={cell} />
        </div>
      )
    }
  ]

  return (
    <Grid container style={{ flexFlow: "column", height: "100%" }}>
      <Grid item xs={12} style={{ flex: "0 1 auto" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <NCText variant={"h4"}>{t("Cancelled Rides")}</NCText>
          <NotificationAlert />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ flex: "1 1 auto" }}
        className="dispatcher-notification-container"
      >
        <Box height={"100%"} bgcolor={"#fafafa"} pt={"10px"}>
          <NCTable
            columns={columns}
            data={results || []}
            page={page}
            onNext={onNext}
            onPrev={onPrev}
            pageCount={total_pages}
            gotoPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isLoading={isLoading}
          />
        </Box>
      </Grid>
      {selectedNotification ? (
        <CancelledPopup
          modal={modal}
          showModal={showModal}
          detail={selectedNotification}
        />
      ) : null}
    </Grid>
  )
}

export default DispatcherNotificationList
