import React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  makeStyles
} from "@material-ui/core"
import { DescendingIcon, DownloadIcon } from "../libs/icons"
import NCSvgIcon from "../shared/NCSvgIcon"
import NCRadio from "../shared/NCRadio"
import NCButton from "../shared/NCButton"
import NCText from "../shared/NCText"

const useStyles = makeStyles({
  input: {
    borderRadius: "20px",
    width: "100%",
    textTransform: "capitalize"
  },
  menuItem: {
    padding: "0",
    fontSize: "14px"
  },
  dropdownMenuItem:{
    padding:"5px",
    fontSize:"14px",
    borderBottom:"1px solid #eee"
  },
  menuList:{
    maxHeight:"250px",
    overflow:"scroll",
  }
})
let defaultItems = [
  {
    name: "CSV",
    key: "csv"
  },
  {
    name: "Excel",
    key: "xlsx"
  },
  {
    name: "PDF",
    key: "pdf"
  }
]
export default function SimpleMenu({ items = defaultItems,selItem, itemAction, asDropdown=false, heading }) {
  const [open, setOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(selItem)
  const anchorRef = React.useRef(null)
  const cls = useStyles()

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        className={cls.input}
        endIcon={
          <NCSvgIcon
            component={DescendingIcon}
            className="svg-primary"
            inheritViewBox
          />
        }
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {asDropdown ? selectedItem?.name || heading : "Export"}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{
          zIndex:"9999",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              transform: "translate3d(0px, 10px, 0px)"
            }}
          >
            <Paper elevation={5} style={{ width: "200px", padding: "10px" }}>
              <Box p={1}>
                <div>
                  <NCText>{asDropdown ? heading :"File Type"}</NCText>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      className={cls.menuList}
                      onKeyDown={handleListKeyDown}
                    >
                      {items.map(item => (
                        <MenuItem
                          onClick={() =>{
                            if(asDropdown){
                              itemAction(item);
                              setSelectedItem(item)
                              setOpen(false);
                            }else{
                              setSelectedItem(item)
                            }
                          }}
                          className={asDropdown ? cls.dropdownMenuItem : cls.menuItem}
                        >
                          {!asDropdown && <NCRadio
                            color={"primary"}
                            style={{ padding: "4px" }}
                            checked={
                              (selectedItem && selectedItem.key) === item.key
                            }
                          />}
                          {item.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                  {!asDropdown && <NCButton
                    onClick={() => itemAction(selectedItem)}
                    variant="contained"
                    color="primary"
                    endIcon={
                      <NCSvgIcon component={DownloadIcon} inheritViewBox />
                    }
                  >
                    Download
                  </NCButton>}
                </div>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
