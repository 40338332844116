import { createTheme } from "@material-ui/core"

const NCTheme = createTheme({
  palette: {
    primary: {
      BG: "#F5F4F7",
      dark: "#232323",
      contrastText: "#fff",
      main: "#1EAAC4"
    },
    secondary: {
      main: "#E22134"
    }
  },
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  }
})

export default NCTheme
