import { makeStyles, Box, Switch, Grid, styled } from "@material-ui/core"
import NCText from "../shared/NCText"
const useStyles = makeStyles({
  container: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "18px",
    color: "#111827",
    fontWeight: 500
  },
  switch: {
    color: "#22C55E"
  }
})

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "grey" : "grey",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}))

const FormSwitch = ({ label, ...props }) => {
  const cls = useStyles()
  return (
    <Box
      width={"100%"}
      className={cls.container}
      borderRadius={"5px"}
      border={"1px solid #D1D5DB"}
      paddingX={1}
      paddingY={2}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <div className={cls.label}><NCText>{label}</NCText></div>
        <IOSSwitch {...props} />
      </Grid>
    </Box>
  )
}

export default FormSwitch
