import { useState } from "react"
import { IconButton } from "@material-ui/core"
import { useNavigate } from "react-router"
import NCTable from "../../shared/table/NCTable"
import { DetailIcon } from "../../libs/icons"
import { useGetClientRequest } from "../../services/queries"
import NCLoader from "../../shared/NCLoader"
import { REQUEST_STATUS, SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import Filter from "../../shared/table/Filter"
import useDebounce from "../../libs/hooks/Debounce"
import NCTableStatus from "../../shared/table/NCTableStatus"
import { formatDate } from "../../libs/utils"
import NCText from "../../shared/NCText"
import { useTranslation } from "react-i18next"

const ClientRequestList = props => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [query] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const nav = useNavigate()
  const { isLoading, data } = useGetClientRequest(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null,
      admin: props.adminId,
      client: props.clientId
    }
  )
  const { next, previous, total_pages, results } =
    props.adminId || props.clientId
      ? data?.data?.data || {}
      : data?.data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const SelectColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <Filter
        options={[
          { key: "1", name: t("requests.pending") },
          { key: "2", name: t("requests.approved") },
          { key: "3", name: t("requests.declined") },
          { key: "4", name: t("requests.on_hold") }
        ]}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }
  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton onClick={() => nav(`/overview/${cell.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }
  const getIdFromRequestId = value => {
    const selectedRequest = value.data.find(
      item => item.identifier === value.value
    )
    nav(`/overview/${selectedRequest.id}`)
  }
  const columns = [
    {
      Header: t("list.request_id"),
      id: "identifier",
      accessor: cell => cell.identifier,
      Cell: value => (
        <NCText
          className={"clickable-text"}
          onClick={() => getIdFromRequestId(value)}
        >
          {value?.value}
        </NCText>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.submitter"),
      accessor: "submitter_info.name"
    },
    {
      Header: t("list.date_created"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value)
    },
    {
      Header: t("list.status"),
      id: "status",
      accessor: cell => <NCTableStatus status={REQUEST_STATUS[cell.status]} />,
      defaultCanFilter: true,
      Filter: SelectColumnFilter,
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      disableSortBy: true,
      accessor: cell => getActionColumn(cell)
    }
  ]
  return (
    <>
      <NCTable
        columns={columns}
        data={results || []}
        pageCount={total_pages}
        pageSize={pageSize}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        gotoPage={setPage}
        setPageSize={setPageSize}
        sorting={ordering}
        setSorting={setOrdering}
        filter={statusFilter}
        setFilter={setStatusFilter}
        isLoading={isLoading}
      />
      {isLoading && <NCLoader open={true} />}
    </>
  )
}

export default ClientRequestList
